import { useMashup } from '@trinity-incyte/hooks';
import React, { useMemo } from 'react';

export const DualBarComparison = ({appId, mashupId, colors=['#65Bb00', '#A8Ed6c'], title="", formatter=undefined}) => {

    const qviz = useMashup(appId, null, mashupId);

    const extractTileDataCells = () => {
        
        const chartLines = qviz?.table?.rows[0].cells.map((cell)=>{
            return { 
                value: cell.qText,
                raw_value: cell.qGrandTotal.qNum,
                label:  cell.qMeasureInfo.qFallbackTitle,
            }
        });
        return chartLines;
    }

    const tileDataCells = useMemo(extractTileDataCells, [qviz?.table?.rows]);

    const barStyle:React.CSSProperties = {
        height: '4.1rem', 
        padding:'0.7rem 0rem',
        marginBottom:'1.1em', 
    };

    const barLabelStyle:React.CSSProperties = {
        height: '1.7rem',
        background:'rgba(255,255,255,0.6)', 
        color: '#000000', 
        fontWeight: 'bold',
        padding:'0.1rem 0.5rem',
        overflow:'visible',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        margin: '0.7rem 0rem'
    }

	return (
        <div key={`container_${mashupId}`} style={{width:'90%'}}>
            <div style={{width: '100%', fontWeight:'bolder', fontSize:'1.1rem', textAlign:'center', color:'#000000'}}>{title}</div>
            <div key={`${mashupId}_chart_container`} style={{width:'100%', paddingRight:'4px'}}>
            {tileDataCells?.map((chart, cIndex)=> {
                let largerNumber = Math.max(...tileDataCells.map(o => o.raw_value));
                let barWidth = chart.raw_value/largerNumber*100;
                
                barWidth=(largerNumber==0)?0:barWidth;

                let label = `${chart.label}: `;
                if (formatter) {
                    label+=formatter(chart.raw_value);
                } else {
                    label+=chart.value;                    
                }

                return (
                    <div key={`${label}_${cIndex}`}  style={{...barStyle, backgroundColor: colors[cIndex], width:barWidth+'%'}}>
                        <div style={{...barLabelStyle}}>
                            {label}
                        </div>
                    </ div>
                );
            })}

            </div>
        </div>
	);
}

export default DualBarComparison;