import React, { useContext, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { use360ProfSelection } from '@trinity-incyte/hooks'
import { ConfigContext, HCO360Context } from '@trinity-incyte/context'
import { QSAppMetadataFamily, QlikUser as QlikUserAtom } from '@trinity-incyte/recoil'
import { MosaicGlobal, QlikAppInstance } from '@trinity-incyte/api-interfaces'
import { Input, Spin, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

declare const Mosaic: MosaicGlobal

const HCO360SearchResult = ({groupData, noClick = false}) => {
	const GROUP_NAME_ID_COLUMN_INDEX = 0
	const GROUP_ID_COLUMN_INDEX = 1
	const GROUP_CITY_COLUMN_INDEX = 4
	const GROUP_STATE_COLUMN_INDEX = 5
	const ORG_COLUMN_INDEX = 7
	const ORG_CITY_INDEX = 8
	const ORG_STATE_INDEX = 9
	const history = useHistory()

	return (
		<div className="hco360-search-result" onClick={() => {
			if (noClick) return;
			history.replace(`/HCO360/${groupData[0][GROUP_ID_COLUMN_INDEX].qText}`)
		}}>
			<div style={{flex: 2}}>
				<div className="hco360-search-result-header">{groupData[0][GROUP_NAME_ID_COLUMN_INDEX].qText}</div>
				{groupData.slice(0,5).map((entry) => (
					<div className="hco360-search-result-details">{entry[ORG_COLUMN_INDEX].qText}</div>
				))}
			</div>
			<div style={{flex: 1}}>
				<div className="hco360-search-result-header">{`${groupData[0][GROUP_CITY_COLUMN_INDEX].qText}, ${groupData[0][GROUP_STATE_COLUMN_INDEX].qText}`}</div>
				{groupData.slice(0,5).map((entry) => (
					<div className="hco360-search-result-details">{`${entry[ORG_CITY_INDEX].qText}, ${entry[ORG_STATE_INDEX].qText}`}</div>
				))}
			</div>
		</div>
	)
}

const HCO360Search = () => {
    const hco360Context = useContext(HCO360Context)
	const Config = useContext(ConfigContext)
	const [ searchResults, setSearchResults ] = useState<Map<string, any>>(null)
	const [ searchString, setSearchString ] = useState<string>(null)
	const searchTaskTimeout = useRef<NodeJS.Timeout>()
	const config = Config.Qlik.Prof360
	const { appId } = config.ids
	const app = Mosaic.Qlik.app[appId]
	const metadata = useRecoilValue(QSAppMetadataFamily(appId))
	const { IDsTable: ids, FieldsTable: fields } = metadata

	use360ProfSelection('')

	useEffect(() => {
        hco360Context.setActiveView('Search')
		hco360Context.applyGroupId(null)
		hco360Context.setShowGroup(false)
	}, [])

	const combineSearchResults = (searchResultsArray: Array<any>) => {
		const resultRowsByGPID = new Map<string, any>()
		searchResultsArray.forEach((searchResult) => {
			if (searchResult == null) return
			const resultRows = searchResult.qMatrix
			resultRows.map(row => {
				if (!resultRowsByGPID.has(row[1].qText)) resultRowsByGPID.set(row[1].qText, [])
				const gpResults = resultRowsByGPID.get(row[1].qText)
				gpResults.push(row)
			})
		})

		return resultRowsByGPID
	}

	const searchTask = async (text, callback, app) => {
		if (text == null || text == "") return null
		if (!callback) return null
		if (app) {
			const object = await app.visualization.get(ids.get('Mosaic_HCO 360_GP Search')?.id)
			const nameIdResults = await object.table.getFilteredByFieldSearch(app.field(fields.get('Mosaic_HCO 360_GP Name With ID')?.id), text)
			const accountResults = await object.table.getFilteredByFieldSearch(app.field("DO.ORG_NAME_WITH_ID"), text) //TODO: Use friendly name

			callback(combineSearchResults([nameIdResults, accountResults]))
		} else {
			callback(false, text)
		}
	}

	const searchDelay = 500
	const searchQlik = (text: string, callback: (results) => void, app: QlikAppInstance) => {
		if (searchTaskTimeout.current) clearTimeout(searchTaskTimeout.current)
		if (text == "") {
			searchTaskTimeout.current = null
			return
		}
		searchTaskTimeout.current = setTimeout(searchTask, searchDelay, text, callback, app)
	}

	const onSearchStringUpdate = (newSearchString: string) => {
		setSearchResults(null)
		setSearchString(newSearchString)
		searchQlik(newSearchString, (results) => {
			setSearchResults(results)
			searchTaskTimeout.current = null
		}, app)
	}

	const resultsContent = Array<JSX.Element>()
	if (searchTaskTimeout.current != null) {
		resultsContent.push(
			<div className='hco360-spinner-container'>
				<Spin size="large"/>
			</div>
		)
	} else if (searchResults != null) {
		if (searchResults.size > 0) {
			searchResults.forEach((group) => {
				resultsContent.push(<HCO360SearchResult groupData={group}/>)
			})
		} else 
		{
			resultsContent.push(<Typography.Title level={5}>No Results</Typography.Title>)
		}
	}

	const dummyData1 = [[
		{ qText: "TEXAS ONCOLOGY (5395658934)" },
		{ qText: "5395658934" },
		{ qText: "" },
		{ qText: "" },
		{ qText: "DALLAS" },
		{ qText: "TX" },
		{ qText: "" },
		{ qText: "AUSTIN CANCER CENTER (51323706)" },
		{ qText: "AUSTIN" },
		{ qText: "TX" }
	],[
		{ qText: "TEXAS ONCOLOGY (5395658934)" },
		{ qText: "5395658934" },
		{ qText: "" },
		{ qText: "" },
		{ qText: "DALLAS" },
		{ qText: "TX" },
		{ qText: "" },
		{ qText: "CANCER CARE CENTERS OF SOUTH TEXAS-COMPREHENSIVE CANCER CENTER (50121207)" },
		{ qText: "NEW BRAUNFELS" },
		{ qText: "TX" }
	],[
		{ qText: "TEXAS ONCOLOGY (5395658934)" },
		{ qText: "5395658934" },
		{ qText: "" },
		{ qText: "" },
		{ qText: "DALLAS" },
		{ qText: "TX" },
		{ qText: "" },
		{ qText: "COLUMBIA HOSP AT MEDICAL CITY (50017132)" },
		{ qText: "DALLAS" },
		{ qText: "TX" }
	],[
		{ qText: "TEXAS ONCOLOGY (5395658934)" },
		{ qText: "5395658934" },
		{ qText: "" },
		{ qText: "" },
		{ qText: "DALLAS" },
		{ qText: "TX" },
		{ qText: "" },
		{ qText: "HOAST PHARMACY (50036010)" },
		{ qText: "SAN ANTONIO" },
		{ qText: "TX" }
	],[
		{ qText: "TEXAS ONCOLOGY (5395658934)" },
		{ qText: "5395658934" },
		{ qText: "" },
		{ qText: "" },
		{ qText: "DALLAS" },
		{ qText: "TX" },
		{ qText: "" },
		{ qText: "NORTH TEXAS REGIONAL CANCER CENTER (50118598)" },
		{ qText: "PLANO" },
		{ qText: "TX" }
	]]

	const dummyData2 = [[
		{ qText: "TEXAS ONCOLOGY (5395658934)" },
		{ qText: "5395658934" },
		{ qText: "" },
		{ qText: "" },
		{ qText: "DALLAS" },
		{ qText: "TX" },
		{ qText: "" },
		{ qText: "COLUMBIA HOSP AT MEDICAL CITY (50017132)" },
		{ qText: "DALLAS" },
		{ qText: "TX" }
	]]

	const divSpaceStyle = {margin:'0.5em 0em 0.4em 0em', lineHeight:'1.3em', textIndent:'-0.6em', padding:'0em 0em 0em 1.2em'};
	const content = (
		<div style={{display:"flex"}}>
			<div style={{backgroundColor: 'white', flex:"4", padding: "5px"}}>
				<div style={{fontWeight:"bold"}}>Search for:</div>
				<div>- Parent Name</div>
				<div>- Parent ID</div>
				<div>- Child Name</div>
				<div>- Child ID</div>
				<Input
					onChange={({ target: { value } }) => onSearchStringUpdate(value)}
					placeholder='Search for HCO/Group'
					suffix={<SearchOutlined />}
					value={searchString}
					style={{margin:'10px 0px 10px 0px', border: '1px solid #666'}}
				/>
				<div style={{fontWeight:"bold", marginTop: "16px"}}>Instructions and Sample Results:</div>
				<div style={divSpaceStyle}>- All search results will display the Parent HCO and may include several Child sites underneath it.</div>
				<div style={divSpaceStyle}>- When clicking on a result, you will be taken to the page for the Parent.</div>
				<div style={divSpaceStyle}>- If the name or ID you enter matches a Child, the results will list the Child and its Parent. For example, if you searched for “Columbia Hosp” or “50017132”, you’d see a result like this:</div>
				<div style={{width:'100%', textAlign:'right', margin:'0em 0em 1em 2em'}}><div style={{width:'90%', textAlign:'left'}}><HCO360SearchResult groupData={dummyData2} noClick/></div></div>
				<div style={divSpaceStyle}>- If the name or ID you enter matches a Parent, the results will list the Parent and several children (if applicable). For example, if you searched for “Texas Oncology” or “5395658934”, you’d see a result like this:</div>
				<div style={{width:'100%', textAlign:'right', margin:'0em 0em 1em 2em'}}><div style={{width:'90%', textAlign:'left'}}><HCO360SearchResult groupData={dummyData1} noClick/></div></div>
			</div>
			<div style={{flex:"6", backgroundColor:'white'}}>
				{resultsContent}
			</div>
		</div>
	)

	return ( content )
}

export default HCO360Search
