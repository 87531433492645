import React from "react";
import { KPIBox, QSMashupObject } from "@trinity-incyte/ui";
import Config from "../../Config";
import { useRecoilValue } from "recoil";
import { activeTeam as activeTeamAtom, QSAppMetadataFamily } from "@trinity-incyte/recoil";
import { FieldSalesPathGenerator } from "libs/utils/src/lib/path-generators";

const config = Config.Qlik.Prof360;
const { appId } = config.ids;

export const HCP360JakafiTrends = {
    'JAKAFI Trends SP Sales': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const activeTeam = useRecoilValue(activeTeamAtom);
            return (
                <div style={{width: '100%'}}>
                    <KPIBox canClick to={FieldSalesPathGenerator(activeTeam,'/Jakafi/Details/SP%20Sales%20History')} style={{height: '400px', display: 'flex', width: '100%'}}>
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Jakafi SP Physician Sales`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Jakafi SP Physician Sales')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Jakafi SP and Direct Sales in Zip`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Jakafi SP and Direct Sales in Zip')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                    </KPIBox>
                </div>
            );
        }
    },
    'JAKAFI Trends Alerts': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const activeTeam = useRecoilValue(activeTeamAtom);
            return (
                <div style={{width: '100%'}}>
                    <KPIBox canClick to={FieldSalesPathGenerator(activeTeam,'/Jakafi/Details/Alerts')} style={{height: '400px', display: 'flex', width: '100%'}}>
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Jakafi Alert Received vs Followed Up`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Jakafi Alert Received vs Followed Up')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Jakafi Alerts Followed Up by Channel`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Jakafi Alerts Followed Up by Channel')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                    </KPIBox>
                </div>
            );
        }
    },
    'JAKAFI Trends Interactions': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
                <div style={{width: '100%'}}>
                    <KPIBox canClick to={`/HCP360/IncyteActivity/Interactions/${props.profId}`} style={{height: '400px', display: 'flex', width: '100%'}}>
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Jakafi Emails HQ vs RTE`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Jakafi Emails HQ vs RTE')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Jakafi Call Direct vs Influenced`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Jakafi Call Direct vs Influenced')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                    </KPIBox>
                </div>
            );
        }
    },
}

export default HCP360JakafiTrends;