import React from "react"
import { useRecoilValue } from "recoil";
import { activeTeam as activeTeamAtom, QSAppMetadataFamily } from "@trinity-incyte/recoil";
import Config from '../../Config';

const ChatBotContent = () => {

    // get the team
    const activeTeam = useRecoilValue(activeTeamAtom);

    const getMobileOS = () => {
        const ua = navigator.userAgent;
        if (/android/i.test(ua)) {
          return "Android"
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
          return "iOS"
        }
        return "Other"
      }
    
    const isIOS = getMobileOS()=='iOS';

    // find the link based on the team
    const embedLnk = Config.App.embedLinks.chatBot.find((obj) => {
        return obj.teams.includes(activeTeam)}
    )
    if (embedLnk?.embedLink) {

        // get the window height
        const windowHeight = window.innerHeight;

        // parentOrigin added so HCP360 and HCO360 links can link to the _parent from the chatBot 
        const encodedHost = encodeURIComponent(window.location.origin); // NOTE: the config value e.g.: Config.Qlik.config.host will not work in local environments;
    
        const embedBotLink = `${embedLnk?.embedLink}?screenHeight=${windowHeight}&parentOrigin=${encodedHost}`;
        
        if (isIOS) {
            window.open(embedBotLink);
            return <div style={{width:'100%', fontSize:'1.6em',  color:'rgb(0, 92, 173)', padding: '1em', textAlign:'center'}}>
                    <div>If a new window does not open automatically:</div>
                    <a href={embedBotLink} target="_blank" style={{textDecoration:'underline'}}>Click here to open Mosaic AI</a>
                    </div>
        } else {
            return <iframe allow="autoplay" height="100%" src={embedBotLink} title="chat bot"></iframe>

        }

    } else {
        return <div style={{width:'100%', fontSize:'2em',  color:'rgb(0, 92, 173)', padding: '1em'}}>Mosaic AI is not configured for this team.</div>
    }
}

export const ChatBot = (props) => {
    return (
        <ChatBotContent />
    )
}

export default ChatBot