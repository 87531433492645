import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import SalesPerformanceGauges from './SalesPerformanceGauges';
import { PathGenerators } from '@trinity-incyte/utils';
import { activeTeam as activeTeamAtom } from '@trinity-incyte/recoil'
import { useRecoilValue } from 'recoil';

const { Title } = Typography; 

const JakafiGauges = (props) => {
	const { config } = props;
	const activeTeam = useRecoilValue(activeTeamAtom);
	return (
		<>
			<div style={{ fontSize:'1.2rem', fontWeight:'bold', position:'fixed', zIndex:2000, left:10, top:6 }}>
				<NavLink to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Jakafi/Trends/0")}>
					<span style={{ textDecoration: 'underline' }}>Jakafi</span>
					<LinkOutlined />
				</NavLink>
			</div>
			<SalesPerformanceGauges config={config} apex />
		</>
	);
};

export default JakafiGauges;
