import React, { useEffect, useState, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { 
	QSAppMetadataFamily,
	activeTeam as activeTeamAtom,
	ListBuilderFields as ListBuilderFieldsAtom,
	ListBuilderFilters as ListBuilderFiltersAtom,
	ListBuilderSegments as ListBuilderSegmentsAtom,
 } from '@trinity-incyte/recoil';
import { MosaicListBuilder } from '@trinity-incyte/ui';
import { generateGetMoreData, convertTeam } from '@trinity-incyte/utils';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare var Mosaic:MosaicGlobal;

enum DefaultMasterIDs {
	'/20408',
	'/20321',
	'/20324',
}

enum HiddenFields {
	'HCP',
	'Prof Full Name (ID)',
	'Territory (HO1)',
	'Territory (HO2)',
	'Territory (HO3)',
	'Territory (OCNE)',
}

const ComplexListBuilder = ({
	config, reset, myGeo,
}) => {
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const activeTeam = useRecoilValue(activeTeamAtom);
	const [mappings, set_mappings] = useRecoilState(ListBuilderFieldsAtom);
	const [segmentList, set_segmentList] = useRecoilState(ListBuilderSegmentsAtom);
	const [filterButtonItems, set_filterButtonItems] = useRecoilState(ListBuilderFiltersAtom);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;

	const getSelector = (val: string) => {
		const retVal = {};
		val = val.replace(/ /g, '');
		const pairsArray = val.split('],');
		for (let ii = 0; ii < pairsArray.length -1; ii += 1) {
			pairsArray[ii] = pairsArray[ii] + ']'; // add back the missing bracket
		}

		pairsArray.map(pair => {
			const subPairs = pair.split(':[');
			const valueToSelect = subPairs[1].split(']')[0].replace(/'/g, '').split(',');
			retVal[subPairs[0]] = valueToSelect;
		})

		return retVal;
	};

	let listBuilderFields = ids.get('Mosaic HCP 360_List Builder_table_List Builder Fields')?.id;
	let listBuilderFilters = ids.get('Mosaic HCP 360_List Builder_table_List Builder Filters')?.id;
	let listBuilderSegments = ids.get('Mosaic HCP 360_List Builder_table_List Builder Segments')?.id;

	useEffect(() => {
		if (!ids || !fields) return;
		if (mappings.length == 0) {
			app.visualization
				.get(listBuilderFields)
				.then((qViz) => {
					qViz.table._instance.getMoreData = generateGetMoreData();
					qViz.table.addOnDataListener(() => {
						set_mappings(qViz.table.rows.map((row) => ({
							Team: row.cells[0].qText,
							Default: (row.cells[1].qText !== '-') ? row.cells[1].qText : null,
							'Field Group': row.cells[2].qNum,
							'Display Order': row.cells[3].qNum,
							'Master Item ID': row.cells[6].qNum,
							'Master Item Name': row.cells[7].qText,
						})));
					});
					qViz.table.getMoreData();
				});
		}
		if (filterButtonItems.length == 0) {
			app.visualization
				.get(listBuilderFilters)
				.then((qViz) => {
					qViz.table.addOnDataListener(() =>
						set_filterButtonItems(qViz.table.rows.map((row) => ({
							Team: row.cells[0].qText,
							displayOrder: row.cells[1].qText,
							text: row.cells[3].qText,
							id: row.cells[4].qNum,
							selection: {
								y: getSelector(row.cells[5].qText),
								n: getSelector(row.cells[6].qText),
								clear: JSON.parse(`[${row.cells[7].qText}]`),
							},
							requiredFields: [row.cells[4].qNum],
						})))
					);
					qViz.table.getMoreData();
				});
		}
		
		if (segmentList.length == 0) {
			app.visualization
				.get(listBuilderSegments)
				.then((qViz) => {
					qViz.table.addOnDataListener(() => {
						set_segmentList(qViz.table.rows.map((row) => ({
							Team: row.cells[0].qText,
							Default: row.cells[1].qText,
							text: row.cells[2].qText,
							displayOrder: row.cells[3].qNum,
							selection: {
								default: getSelector(row.cells[5].qText),
							},
							requiredFields: row.cells[4].qText
								.split(',')
								.map((item) => Number(item)),
						})));
					});
					qViz.table.getMoreData();
				});
		}
	}, [ids.size, fields.size]);

	return (
		<MosaicListBuilder
			config={config}
			myGeo={myGeo}
			userIdField={fields.get('Mosaic_HCP 360_Masquerade User Id')?.id}
			reset={reset}
			activeTeam={activeTeam}
			segmentList={segmentList}
			mappings={mappings}
			filterButtonItems={filterButtonItems}
			dimensionListFilter={({ qData: { labelExpression } }) => !(labelExpression in HiddenFields)}
			filtersReducer={(curr, val) => {
				if (activeTeam === convertTeam(val.Team)) {
					curr.push(val);
				}
				return curr;
			}}
			segmentButtonReducer={(curr, val) => {
				if ((activeTeam === convertTeam(val.Team)) || val.Team === 'Both') {
					curr.push(val);
				}
				return curr;
			}}
			initialItemsArrayReducer={(curr, val) => {
				if ((activeTeam === convertTeam(val.Team)) && val.Default !== null) {
					curr.push(val['Master Item ID']);
				}
				return curr;
			}}
			allowlistedItemsReducer={(curr, val) => {
				if ((activeTeam === convertTeam(val.Team)) || val.Team === 'Both') {
					const id = val['Master Item ID'];
					curr[id] = val['Master Item Name'].trim(); 
				} else {
					// supposed to do nothing
				}
				return curr;
			}}
			dimensionListReducer={(allowlistedIDs, dimensions) => {
				let tempGroup = [];
				dimensions.qDimensionList.qItems.forEach((val) => {
					if (allowlistedIDs.indexOf(val.qMeta.masterScriptId) >= 0) {
						const fieldId = parseInt(val.qMeta.masterScriptId, 10);
						const meta = mappings.reduce(
							(acc: any, curr) => {
								// Find field group
								if (`TEAM${convertTeam(activeTeam)}` === `TEAM${curr.Team}` && fieldId === curr['Master Item ID']) {
									acc.group = curr['Field Group'] || -1;
									acc.order = curr['Display Order'] || -1;
								}
								return acc;
							},
							{ group: null, order: -1 }
						);

						if (meta.order >= 0) {
							tempGroup[meta.order - 1] = val; // Subtracting to 0-index everything
						}
					}
				});
				return tempGroup;
			}}
			tableOptions={{
				tableProps: {
					rowLimit: 20000, // Setting a limit primarily because of home users (this table can be HUGE)
				},
			}}
		/>
	);
};

export default ComplexListBuilder;
