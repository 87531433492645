import React from 'react';

const DraggablePanes = ( props ) => {
	const content = (
		<div id="draggable_pane" style={props.style || { height: '100%', width: '100%', display: 'block', maxWidth: '100%!important' }}>
			{props.menu}
			{props.children}
			{props.panes[props.activeItem].pane.content}
		</div>
	);

	return ( content );
};

export default DraggablePanes;

