import React, { useEffect, useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { 
	RGLGrid, 
	LeftSlider, 
	PeriodOverPeriod, 
	KPINavHighlight 
} from '@trinity-incyte/ui';
import { useNon360ClearProfSelection } from '@trinity-incyte/hooks';
import { activeTeam as activeTeamAtom, QSAppMetadataFamily } from '@trinity-incyte/recoil';

import Utils from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';

const LandingPage = ( props ) => {
	const activeTeam = useRecoilValue(activeTeamAtom);
	const Config = useContext(ConfigContext);
	const screen = Utils.screen(Config);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;

	useNon360ClearProfSelection({
		selection: [
			{
				fieldName: fields.get('Mosaic_Field Sales_Time Selection')?.id,
				qSelected: activeTeam === 'HO3' ? 'Month' : 'Quarter',
			},
		],
	});

	// I'm not sure if this is important? When trying to switch to Ant Layout in AppRouter this broke things
	// useEffect(() => {
	// 	(document.querySelector('.layout > .segment') as HTMLElement).style.padding = '0px';
	// 	window.dispatchEvent(new Event('resize'));
	// 	return () => {
	// 		// document.querySelector('.container > .segment').style.padding = '14px';
	// 	}
	// }, []);

	let defaultLayout;

	if (activeTeam === 'HO3') {
		defaultLayout = {
			"xs": [{
				"w": 24,
				"h": 4,
				"x": 0,
				"y": 0,
				"i": "Landing Page Circle Gauges",
				"moved": false,
				"static": true
			}, {
				"w": 16,
				"h": 12,
				"x": 0,
				"y": 4,
				"i": "Landing Page Alerts",
				"moved": false,
				"static": true
			}, {
				"w": 8,
				"h": 5,
				"x": 16,
				"y": 4,
				"i": "Landing Page Current Week Events",
				"moved": false,
				"static": true,
			}, {
				"w": 8,
				"h": 7,
				"x": 16,
				"y": 9,
				"i": "Landing Page Key Segments",
				props: { activeTeam },
				"moved": false,
				"static": true,
			}]
		};
	} else if (activeTeam === 'OCNE') {
		defaultLayout = {
		 "xs": [{
				 "w": 24,
				 "h": 4,
				 "x": 0,
				 "y": 0,
				 "i": "/Index - Circle Gauges OCNE",
				 "responsive": true,
				 "moved": false,
				 "static": true
		 }, {
				 "w": 8,
				 "h": 3,
				 "x": 16,
				 "y": 4,
				 "i": "/Index - Pemi Box",
				 "moved": false,
				 "static": true
		 }, {
				 "w": 8,
				 "h": 2,
				 "x": 16,
				 "y": 7,
				 "i": "/Index - Event Box",
				 "moved": false,
				 "static": true
		 }, {
				 "w": 8,
				 "h": 7,
				 "x": 16,
				 "y": 9,
				 "i": "Ordered Key Segments",
				 props: { activeTeam },
				 "moved": false,
				 "static": true
		 }, {
				 "w": 16,
				 "h": 12,
				 "x": 0,
				 "y": 4,
				 "i": "/Index - Alerts",
				 "moved": false,
				 "static": true
		 }]
	 };
	} else {
		defaultLayout = {
		 "xs": [{
				 "w": 16,
				 "h": 4,
				 "x": 0,
				 "y": 0,
				 "i": "/Index - Circle Gauges",
				 "moved": false,
				 "static": true
		 }, {
				 "w": 8,
				 "h": 9,
				 "x": 18,
				 "y": 0,
				 "i": "/Index - Barrier Grid",
				 "moved": false,
				 "static": true
		 }, {
		// 		 "w": 8,
		// 		 "h": 2,
		// 		 "x": 16,
		// 		 "y": 8,
		// 		 "i": "/Index - Event Box",
		// 		 "moved": false,
		// 		 "static": true
		//  }, {
				 "w": 8,
				 "h": 7,
				 "x": 16,
				 "y": 9,
				 "i": "/Index - Key Segments",
				 props: { activeTeam },
				 "moved": false,
				 "static": true
		 }, {
				 "w": 16,
				 "h": 12,
				 "x": 0,
				 "y": 4,
				 "i": "/Index - Alerts",
				 "moved": false,
				 "static": true
		 }]
	 };
	}

	return (
		<>

			{activeTeam === 'HO3' ? (
				<KPINavHighlight />
			) : (
				<PeriodOverPeriod />
			)}
			<RGLGrid
				title="Landing Page"
				layout={defaultLayout}
			/>
			<LeftSlider />
		</>
	);
};

export default LandingPage;
