import React, { useContext, useState } from 'react';
import {
	Space,
	Button,
	Row,
} from 'antd';
import { useRecoilValue } from 'recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { useSelections } from '@trinity-incyte/hooks';
import { QSAppMetadataFamily } from '@trinity-incyte/recoil'
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const DateSelectionButtons = (props) => {
	const [activeButton, setActiveButton] = useState(0);
	const { layout, size, defaultSelection } = props;
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;

	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	let timeSelectionField = fields.get('Mosaic_Field Sales_Time Selection')?.id;
	let yearField = fields.get('Mosaic_Field Sales_Year')?.id;
	let latest13Weeks = fields.get('Mosaic_Field Sales_Latest 13 Weeks')?.id;
	let latest26Weeks = fields.get('Mosaic_Field Sales_Latest 26 Weeks')?.id;
	let latest52Weeks = fields.get('Mosaic_Field Sales_Latest 52 Weeks')?.id;
	let actualCurrentQuarter = fields.get('Mosaic_Field Sales_CQT')?.id;
	let actualCurrentMonthToDate = fields.get('Mosaic_Field Sales_CMTD')?.id;
	let actualCurrentYearToDate = fields.get('Mosaic_Field Sales_YTD')?.id;
	let actualCurrentQuarterToDate = fields.get('Mosaic_Field Sales_QTD')?.id;
	let retailIntLast12Mths = fields.get('Mosaic_Field Sales_RetailInt Last 12 Months')?.id;
	let retailIntLast12Qtr = fields.get('Mosaic_Field Sales_RetailInt Last 12 Qtr')?.id;
	let retailInt13WTD = fields.get('Mosaic_Field Sales_RetailInt 13 WTD')?.id;
	let retailInt26WTD = fields.get('Mosaic_Field Sales_RetailInt 26 WTD')?.id;
	let retailInt52WTD = fields.get('Mosaic_Field Sales_RetailInt 52 WTD')?.id;
	let retailIntIsQTD = fields.get('Mosaic_Field Sales_RetailInt QTD')?.id;
	let retailIntIsYTD = fields.get('Mosaic_Field Sales_RetailInt YTD')?.id;
	let retailProIsQTD = fields.get('Mosaic_Field Sales_RetailPro QTD')?.id;
	let retailProIsYTD = fields.get('Mosaic_Field Sales_RetailPro YTD')?.id;
	let retailPro13WTD = fields.get('Mosaic_Field Sales_RetailPro 13 WTD')?.id;
	let retailPro26WTD = fields.get('Mosaic_Field Sales_RetailPro 26 WTD')?.id;
	let retailPro52WTD = fields.get('Mosaic_Field Sales_RetailPro 52 WTD')?.id;
	let retailProLast12Mths = fields.get('Mosaic_Field Sales_RetailPro Last 12 Months')?.id;
	let retailProLast12Qtr = fields.get('Mosaic_Field Sales_RetailPro Last 12 Qtr')?.id;
	let retailCurrentWeek = fields.get('Mosaic_Field Sales_Retail C1WTY')?.id;
	let retailPreviousWeek = fields.get('Mosaic_Field Sales_Retail P1WTY')?.id;
	
	const knownFieldsToBeCleared = [
		fields.get('Mosaic_Field Sales_RetailPro Latest 52 Weeks')?.id,
		fields.get('Mosaic_Field Sales_RetailPro Last 3 Years')?.id,
		fields.get('Mosaic_Field Sales_RetailInt Latest 52 Weeks')?.id,
		fields.get('Mosaic_Field Sales_RetailInt Last 3 Years')?.id,
		latest13Weeks,
		latest26Weeks,
		latest52Weeks,
		retailProIsQTD,
		retailProIsYTD,
		retailPro13WTD,
		retailPro26WTD,
		retailPro52WTD,
		retailProLast12Mths,
		retailProLast12Qtr,
		retailIntLast12Mths,
		retailIntLast12Qtr,
		retailInt13WTD,
		retailInt26WTD,
		retailInt52WTD,
		retailIntIsQTD,
		retailIntIsYTD,
		actualCurrentQuarter,
		actualCurrentMonthToDate,
		actualCurrentYearToDate,
		actualCurrentQuarterToDate,
		retailCurrentWeek,
		retailPreviousWeek
	];

	const {
		appSelections,
		simpleSelections
  } = useSelections({config});

	const buttonSelection = (event, appId, fieldsToBeCleared, clearSelection?) => {
		let button;
		if (event.target.nodeName === 'BUTTON') {
			button = event.target;
		} else if (event.target.nodeName === 'SPAN') {
			button = event.target.parentElement;
		} else {
			button = event.target.firstElementChild;
		}
	
		const selectionData = JSON.parse(button.dataset.selection);
		const selectionDataKeys = Object.keys(selectionData);
	
		// Clear all possible selected fields before choosing another selection
		for (let ij = 0; ij < fieldsToBeCleared.length; ij += 1) {
			const fieldName = fieldsToBeCleared[ij];
			Mosaic.Qlik.app[appId].field(fieldName).clear();
		}
	
		if (clearSelection) return;
		for (let ii = 0; ii < selectionDataKeys.length; ii += 1) {
			const fieldName = selectionDataKeys[ii];
			const selection = selectionData[fieldName];
			const app = Mosaic.Qlik.app[appId];
			const field = app.field(fieldName);
			if (selection === '*') {
				field.selectAll();
			} else {
				// Selection is case-sensitive
				let selectionArray;
				if (Array.isArray(selectionData[fieldName])) {
					selectionArray = selectionData[fieldName];
				} else {
					selectionArray = new Array(selectionData[fieldName]);
				}
	
				field.clear().then(() => {
					field.selectValues(selectionArray);
				});
			}
		}
	};
  
	const isSelected = (option) => {
		if (!appSelections) return false;

		let optionFields = Object.keys(option);
		const optionMatches = new Array(optionFields.length).fill(0); // 1 = match

		if (optionFields.length > 0) {
			for (let ii = 0; ii < optionFields.length; ii += 1) {
				let optionValues = option[optionFields[ii]];
				if (!Array.isArray(optionValues)) optionValues = [optionValues];
				const singleFieldMatches = new Array(optionValues.length).fill(0);

				for (let ij = 0; ij < appSelections.length; ij += 1) {
					if (optionFields[ii] === appSelections[ij]?.fieldName) { // The field matches
						for (let ik = 0; ik < optionValues.length; ik += 1) {
							const optionVal = optionValues[ik];
							for (let il = 0; il < appSelections[ij]?.selectedValues.length; il += 1) {
								const selectionValue = appSelections[ij]?.selectedValues[il]?.qName;
								if (typeof optionVal === 'string' && optionVal === selectionValue) { // The field & values match
									singleFieldMatches[ik] = 1;
								} else if (typeof optionVal === 'number' && optionVal === parseInt(selectionValue, 10)) { // The field & values match
									singleFieldMatches[ik] = 1;
								}
							}
						}
					}
				}

				const countSingleFieldMatches = singleFieldMatches.reduce((acc, curr) => (acc + curr), 0);
				if (countSingleFieldMatches === optionValues.length) optionMatches[ii] = 1;
			}
		}

		const countAllFieldMatches = optionMatches.reduce((acc, curr) => (acc + curr), 0);
		const retVal = (countAllFieldMatches === optionFields.length);
		return retVal;
	};

	let buttonsArray;
	if (layout === "ALERTSFOLLOWUPLIMITED") {
		buttonsArray = [
			{
				ind: 6,
				disabled: false,
				text: 'MTD',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentMonthToDate}": [-1]}`,
			}, {
				ind: 7,
				disabled: false,
				text: 'QTD',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentQuarterToDate}": [-1]}`,
			}, {
				ind: 8,
				disabled: false,
				text: 'YTD',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentYearToDate}": [-1]}`,
			},
		];
	} else if (layout === "ALERTSFOLLOWUP") {
		buttonsArray = [
			{
				ind: 1,
				disabled: false,
				text: 'Latest 13 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${latest13Weeks}": [-1]}`,
			}, {
				ind: 2,
				disabled: false,
				text: 'Latest 26 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${latest26Weeks}": [-1]}`,
			}, {
				ind: 3,
				disabled: false,
				text: 'Latest 52 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${latest52Weeks}": [-1]}`,
			}, {
				ind: 4,
				disabled: false,
				text: 'QTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentQuarterToDate}": [-1]}`,
			}, {
				ind: 5,
				disabled: false,
				text: 'YTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentYearToDate}": [-1]}`,
			},
		];
	} else if (layout === "BARRIERANALYSIS") {
		buttonsArray = [
			{
				ind: 1,
				disabled: false,
				text: 'Latest 13 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${latest13Weeks}": [-1]}`,
			}, {
				ind: 4,
				disabled: false,
				text: 'QTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentQuarterToDate}": [-1]}`,
			}, {
				ind: 5,
				disabled: false,
				text: 'YTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${actualCurrentYearToDate}": [-1]}`,
			},
		];

	} else if (layout === 'MONJUVI') {
		buttonsArray = [
			{
				ind: 2,
				disabled: false,
				text: 'All - Y',
				selection: `{"${timeSelectionField}": "Year"}`,
			}, {
				ind: 3,
				disabled: false,
				text: `${new Date().getFullYear() - 2}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 2}]}`,
			}, {
				ind: 4,
				disabled: false,
				text: `${new Date().getFullYear() - 1}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 1}]}`,
			},
			{
				ind: 6,
				disabled: false,
				text: `${new Date().getFullYear()}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear()}]}`,
			}, {
				ind: 7,
				disabled: false,
				text: 'Latest 12 Months',
				selection: `{"${timeSelectionField}": "Month", "${retailIntLast12Mths}": [1]}`,
			}, {
				ind: 8,
				disabled: false,
				text: 'Latest 12 Quarters',
				selection: `{"${timeSelectionField}": "Quarter", "${retailIntLast12Qtr}": [1]}`,
			}, {
				ind: 9,
				disabled: false,
				text: 'Latest 13 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailInt13WTD}": [1]}`,
			}, {
				ind: 10,
				disabled: false,
				text: 'Latest 26 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailInt26WTD}": [1]}`,
			}, {
				ind: 13,
				disabled: false,
				text: 'Latest 52 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailInt52WTD}": [1]}`,
			}, {
				ind: 11,
				disabled: false,
				text: 'QTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailIntIsQTD}": [1]}`,
			}, {
				ind: 12,
				disabled: false,
				text: 'YTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailIntIsYTD}": [1]}`,
			}
		];
	} else if (layout === "ZYNYZ") {
		buttonsArray = [
			{
				ind: 2,
				disabled: false,
				text: 'All - Y',
				selection: `{"${timeSelectionField}": "Year"}`,
			},
			{
				ind: 3,
				disabled: false,
				text: `${new Date().getFullYear()}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear()}]}`,
			}, {
				ind: 4,
				disabled: false,
				text: 'Latest 12 Months',
				selection: `{"${timeSelectionField}": "Month", "${retailIntLast12Mths}": [1]}`,
			}, {
				ind: 5,
				disabled: false,
				text: 'Latest 12 Quarters',
				selection: `{"${timeSelectionField}": "Quarter", "${retailIntLast12Qtr}": [1]}`,
			}, {
				ind: 6,
				disabled: false,
				text: 'Latest 13 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailInt13WTD}": [1]}`,
			}, {
				ind: 7,
				disabled: false,
				text: 'Latest 26 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailInt26WTD}": [1]}`,
			}, {
				ind: 8,
				disabled: false,
				text: 'Latest 52 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailInt52WTD}": [1]}`,
			}, {
				ind: 9,
				disabled: false,
				text: 'QTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailIntIsQTD}": [1]}`,
			}, {
				ind: 10,
				disabled: false,
				text: 'YTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailIntIsYTD}": [1]}`,
			}
		];
	} else if (layout === 'JAKAFI' || layout === 'PEMAZYRE') { // is Jak/Pem
		buttonsArray = [
			{
				ind: 1,
				disabled: false,
				text: 'All - Y',
				selection: `{"${timeSelectionField}": "Year"}`,
			}, {
				ind: 2,
				disabled: false,
				text: `${new Date().getFullYear() - 3}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 3}]}`,
			}, {
				ind: 3,
				disabled: false,
				text: `${new Date().getFullYear() - 2}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 2}]}`,
			}, {
				ind: 4,
				disabled: false,
				text: `${new Date().getFullYear() - 1}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 1}]}`,
			}, {
				ind: 5,
				disabled: false,
				text: `${new Date().getFullYear()}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear()}]}`,
			}, {
				ind: 6,
				disabled: false,
				text: 'Latest 12 Months',
				selection: `{"${timeSelectionField}": "Month", "${retailProLast12Mths}": [1]}`,
			}, {
				ind: 7,
				disabled: false,
				text: 'Latest 12 Quarters',
				selection: `{"${timeSelectionField}": "Quarter", "${retailProLast12Qtr}": [1]}`,
			}, {
				ind: 9,
				disabled: false,
				text: 'Latest 13 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailPro13WTD}": [1]}`,
			}, {
				ind: 10,
				disabled: false,
				text: 'Latest 26 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailPro26WTD}": [1]}`,
			}, {
				ind: 13,
				disabled: false,
				text: 'Latest 52 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailPro52WTD}": [1]}`,
			}, {
				ind: 11,
				disabled: false,
				text: 'QTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailProIsQTD}": [1]}`,
			}, {
				ind: 12,
				disabled: false,
				text: 'YTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailProIsYTD}": [1]}`,
			}
		];
	} else { // is Region Analysis
		buttonsArray = [
			{
				ind: 1,
				disabled: false,
				text: 'All - Y',
				selection: `{"${timeSelectionField}": "Year"}`,
			}, {
				ind: 2,
				disabled: false,
				text: `${new Date().getFullYear() - 3}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 3}]}`,
			}, {
				ind: 3,
				disabled: false,
				text: `${new Date().getFullYear() - 2}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 2}]}`,
			}, {
				ind: 4,
				disabled: false,
				text: `${new Date().getFullYear() - 1}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear() - 1}]}`,
			}, {
				ind: 5,
				disabled: false,
				text: `${new Date().getFullYear()}`,
				selection: `{"${timeSelectionField}": "Quarter", "${yearField}": [${new Date().getFullYear()}]}`,
			}, {
				ind: 6,
				disabled: false,
				text: 'Latest 12 Months',
				selection: `{"${timeSelectionField}": "Month", "${retailProLast12Mths}": [1]}`,
			}, {
				ind: 7,
				disabled: false,
				text: 'Latest 12 Quarters',
				selection: `{"${timeSelectionField}": "Quarter", "${retailProLast12Qtr}": [1]}`,
			}, {
				ind: 9,
				disabled: false,
				text: 'Latest 13 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailPro13WTD}": [1]}`,
			}, {
				ind: 10,
				disabled: false,
				text: 'Latest 26 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailPro26WTD}": [1]}`,
			}, {
				ind: 13,
				disabled: false,
				text: 'Latest 52 Weeks',
				selection: `{"${timeSelectionField}": "Week", "${retailPro52WTD}": [1]}`,
			}, {
				ind: 11,
				disabled: false,
				text: 'QTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailProIsQTD}": [1]}`,
			}, {
				ind: 12,
				disabled: false,
				text: 'YTD - Week',
				selection: `{"${timeSelectionField}": "Week", "${retailProIsYTD}": [1]}`,
			}, {
				ind: 14,
				disabled: false,
				text: 'Current Week',
				selection: `{"${timeSelectionField}": "Week", "${retailCurrentWeek}": [-1]}`,
			}, {
				ind: 15,
				disabled: false,
				text: 'Previous Week',
				selection: `{"${timeSelectionField}": "Week", "${retailPreviousWeek}": [-1]}`,
			}
		];
	}

	const fieldsToBeCleared = [...knownFieldsToBeCleared,
		`${timeSelectionField}`,
		`${yearField}`,
	];

	const content = (
		<Row style={{ width: '100%' }} justify="space-around">
			<Space align="baseline" size={[8, 16]} wrap>
				{buttonsArray.map((val) => (
						<Button
						style={{ fontSize: '1rem' }}
						key={`date-button-${val.ind}`}
						disabled={val.disabled}
						data-appid={appId}
						data-selection={val.selection}
						onClick={(event) => {
							if (val.disabled) return;
							if (activeButton !== val.ind) {
								setActiveButton(val.ind);
								buttonSelection(event, appId, fieldsToBeCleared);
							} else {
								setActiveButton(0);
								buttonSelection(event, appId, fieldsToBeCleared, true);
							}
						}}
						onKeyPress={(event) => {
							if (val.disabled) return;
							if (activeButton !== val.ind) {
								setActiveButton(val.ind);
								buttonSelection(event, appId, fieldsToBeCleared);
							} else {
								setActiveButton(0);
								buttonSelection(event, appId, fieldsToBeCleared, true);
							}
						}}
						size={size}
						type={(isSelected(JSON.parse(val.selection))) ? 'default' : 'primary'}
					>
						{val.text}
					</Button>
				))}
			</Space>
		</Row>
	);

	return (content);
};

export default DateSelectionButtons;
