import React from 'react';

import { RGLGrid as Grid, LeftSlider, NavGroupMosaic, SubPage } from '@trinity-incyte/ui';
import { use360ProfSelection } from '@trinity-incyte/hooks';

const Barriers = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection( profId );
	const urlSuffix = ( profId ) ? `/${profId}` : '';

	let defaultLayout;

	if ( profId === false ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'green',
					text: 'Connections',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId === '-' ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'orange',
					text: 'Please select one HCP to view the overview.',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId !== false && !isProfSelected ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Loader',
				props: {
					color: 'blue',
					text: 'Loading...',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId !== false && isProfSelected ) {
		defaultLayout = {
			xs: [
				{
					"w": 5,
					"h": 16,
					"x": 0,
					"y": 0,
					"i": "Mosaic Overview Prof Box",
					"moved": false,
					"props": {
						"profId": profId,
					},
					"static": true,
				},
				{
					w: 19,
					h: 16,
					x: 5,
					y: 0,
					i: 'Incyte Activity - Barriers',
					props: {
						profId
					},
					static: true,
				},
			],
		};
	}

	const content = (
		<>
			<NavGroupMosaic showClear urlSuffix={urlSuffix} />
			<Grid
				title="Barriers"
				layout={defaultLayout}
			/>
			<LeftSlider />
		</>
	);

	return ( content );
};

export default Barriers;
