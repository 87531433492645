import React, { useContext, useEffect, useState } from "react";
import Config from '../../../Config';
import { StarFilled } from "@ant-design/icons";
import { BasicTableColumnRenderer } from "libs/ui/src/lib/Qlik/qscustom-table/basic-table/basic-table";
import HCPDetails from "./HCPDetails";
import { Spin } from 'antd';

import { useRecoilValue } from "recoil";
import PreCallPlanningStyles from './PreCallPlanning.module.scss';
import { QSAppMetadataFamily, activeTeam as activeTeamAtom } from "@trinity-incyte/recoil";

import { NavGroupMosaic, QSMashupObject } from '@trinity-incyte/ui';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { HCP360Context } from "@trinity-incyte/context";
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";

declare const Mosaic: MosaicGlobal;
let dateValue = "";

const PreCallPlanning = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection( profId );
	const urlSuffix = ( profId ) ? `/${profId}` : '';
    const config = Config.Qlik.Prof360;
    const { appId } = config.ids;
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const activeTeam = useRecoilValue(activeTeamAtom);
    const { IDsTable: ids, FieldsTable: fields } = metadata;
    const hcp360Context = useContext(HCP360Context);
    const profData = hcp360Context.profData;
    const [ionDate, set_ionDate] = useState("");

    let content = (<div style={{margin:'2em', textAlign:'center', width:'100%'}}><Spin size="large" /><br />Loading...</div>);

    const app = Mosaic.Qlik.app[appId];

    useEffect(() => {
        let definition = {
            dateValue: {qStringExpression: `=Only(${fields.get('Mosaic_HCP 360_ION Data Month')?.id})`}
        };
        app.createGenericObject(definition, () => {}).then((obj) => {
            dateValue = obj.layout?.dateValue || "";
            set_ionDate(dateValue);
        });
    }, [])

    if (profId !== false && profId !== '-' && isProfSelected && profData && activeTeam) {
        content = (
            <>
            <NavGroupMosaic showClear urlSuffix={urlSuffix} />
            <div style={{width:'100%', height:'100%', backgroundColor:"white", overflow:'auto', padding:'12px'}}>

            <HCPDetails profId={profId} />
                <>
                {/* Sales Volume */}
                <div style={{display:'flex'}}>
                    <div className={PreCallPlanningStyles.dataTable_full} style={{paddingTop:'18px'}}>
                        <div style={{display:'flex', width:'100%'}}>
                        <div className={PreCallPlanningStyles.dataTable_header} style={{width:'40%',}}>{"Sales"}</div>
                            {(ionDate) && (<div style={{width:'60%', color:"#666", fontWeight:'normal', textAlign:'right'}}>Monthly data as of {ionDate}</div>)}
                        </div>
                        <QSMashupObject
                        appId={appId}
                        customClass="basicTable"
                        mashupId={ids.get('Mosaic_HCP 360 - Overview_Bottles')?.id}
                        isBasicTable
                        compactMargin
                        compact
                        {...props}
                        />
                    </div>

                    <div className={PreCallPlanningStyles.dataTable_full} style={{height: '200px', minHeight: '200px', display: 'flex', width: '100%', minWidth: '400px'}}>

                    <QSMashupObject
                        exportAsImg
                        exportAsPPT
                        exportAsXLS
                        key={`Mosaic_HCP 360_Jakafi SP and Direct Sales in Zip R13W`}
                        appId={appId}
                        mashupId={ids.get('Mosaic_HCP 360_Jakafi SP and Direct Sales in Zip R13W')?.id}
                        contentClass="no-margin"
                        contentStyle={{ height: null, width: '100%', padding: '15px' }}
                        style={{flex:1}}
                    />
                    </div>
                </div>

                <div className={PreCallPlanningStyles.dataTable_full}>
                <div className={PreCallPlanningStyles.dataTable_header}>{"Calls by Team"}</div>
                <QSMashupObject
                        appId={appId}
                        customClass="basicTable"
                        mashupId={ids.get('Mosaic_HCP 360_Jakafi Latest Call by Team')?.id}
                        isBasicTable
                        compactMargin
                        compact
                        {...props}
                        />
                </div>

                <div className={PreCallPlanningStyles.dataTable_full}>
                <div className={PreCallPlanningStyles.dataTable_header}>{"Alerts"}</div>
                <QSMashupObject
                        appId={appId}
                        customClass="basicTable"
                        mashupId={ids.get('Mosaic_HCP 360_Jakafi Latest Alert by Type')?.id}
                        isBasicTable
                        compactMargin
                        compact
                        {...props}
                        />
                </div>

                <div className={PreCallPlanningStyles.dataTable_full}>
                <div className={PreCallPlanningStyles.dataTable_header}>{"Speaker Programs"}</div>
                <QSMashupObject
                        appId={appId}
                        customClass="basicTable"
                        mashupId={ids.get('Mosaic_HCP 360_Jakafi Speaker Programs')?.id}
                        isBasicTable
                        compactMargin
                        compact
                        {...props}
                        />
                </div>

                <div className={PreCallPlanningStyles.dataTable_full}>
                <div className={PreCallPlanningStyles.dataTable_header}>{"RTE's Sent"}</div>
                <QSMashupObject
                        appId={appId}
                        customClass="basicTable"
                        mashupId={ids.get('Mosaic_HCP 360_Jakafi RTEs Sent')?.id}
                        isBasicTable
                        compactMargin
                        compact
                        {...props}
                        />
                </div>

                <div className={PreCallPlanningStyles.dataTable_full}>
                <div className={PreCallPlanningStyles.dataTable_header}>{"Barriers"}</div>

                    <QSMashupObject 
                        compact
                        compactMargin
                        customClass="basicTable"
                        isBasicTable
                        appId={appId}
                        mashupId={ids.get('Mosaic_HCP 360 - Overview_Jakafi Barrier Tiles')?.id}
                        columnRenderers={new Map<string, BasicTableColumnRenderer>([
                            ["Indication", { cellRenderer: (cellInfo, columns, data) => {
                                return (
                                    <td key={`dataCell_Indication`} style={{ }}>
                                        {data.RecentFlag === "Y" ? <StarFilled style={{ color: 'gold', marginTop: '1px', marginRight: '0.5em', stroke: 'red', strokeWidth: '40' }} /> : ""}
                                        {cellInfo}
                                    </td>
                                )
                            } }],
                            ["RecentFlag", { headerRenderer: (cellInfo, columns, index) => { }, cellRenderer: (cellInfo, columns, data) => {} }]
                        ])}
                    />
                </div>

                <div className={PreCallPlanningStyles.dataTable_full}>
                <div className={PreCallPlanningStyles.dataTable_header}>{"Marketing Journey"}</div>
                <QSMashupObject
                        appId={appId}
                        customClass="basicTable"
                        mashupId={ids.get('Mosaic_HCP 360_Jakafi Marketing Campaign Journeys')?.id}
                        isBasicTable
                        compactMargin
                        compact
                        {...props}
                        />
                </div>

                </>
            </div>
            </>
        );
    }

	return ( content );
};

export default PreCallPlanning;
