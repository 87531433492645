import { QlikAppInstance } from "@trinity-incyte/api-interfaces"
import { useEffect, useState } from "react"

export interface useSelectionProps {
    app: QlikAppInstance
    fieldIds: Array<string>
}

export const useSelection = (props: useSelectionProps) => {
    const [selectionData, setSelectionData] = useState<Map<string, any>>(new Map<string, any>())

    const onData = () => {
        const newSelectionData = new Map<string, any>()
        props.fieldIds.forEach((fieldId) => {
            newSelectionData.set(fieldId, props.app.selectionState().selections?.find(selection => selection.fieldName == fieldId))
        })

        setSelectionData(newSelectionData)
    }

    useEffect(()=> {
        props.app.selectionState().addOnDataListener(onData)

        return () => { props.app.selectionState().removeOnDataListener(onData) }
    }, [])

    return selectionData
}

export default useSelection