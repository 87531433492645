import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Segment } from '@trinity-incyte/ui';
import { 
	QlikLastModified as QlikLastModifiedAtom, 
	QSAppMetadataFamily, 
	rowFiltersState 
} from '@trinity-incyte/recoil';
import Utils from '@trinity-incyte/utils';
import { generateGetMoreData } from '@trinity-incyte/utils';
import EventsPane from './EventsPane';
import { filterVisibleFilters } from './filterVisibleFilters';
import './index.scss';
import { Empty } from 'antd';
import { AudioFilled, MailOutlined, TeamOutlined } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import RecentStyles from './Recent.module.scss';

declare const Mosaic: MosaicGlobal;

const Recent = ( props ) => {
  const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const [events, set_events] = useState({
		weekMinus0: [],
		weekMinus1: [],
		weekMinus2: [],
		weekMinus3: [],
	});
	const [accordionTitles, set_accordionTitles] = useState({});
	const [tableObject, set_tableObject] = useState<any>();
	const [activePane, set_activePane] = useState('weekMinus0');
	const { selectedCompany, selectedTeam, selectedType } = props.selections;
	const setRowFilters = useSetRecoilState(rowFiltersState);

  const config = props.config;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const { IDsTable: ids, FieldsTable: fields } = metadata;
  const app = Mosaic.Qlik.app[appId];

	useEffect(() => { // OnMount & OnUnmount
		// Set up the accordion titles
		const now = Utils.moment();
		const titles = {};
		if ( now.isoWeekday() <= 5 ) {
			now.isoWeekday( 'Friday' );
		} else {
			now.isoWeekday( 'Friday' ).add( 1, 'week' );
		}
		titles['weekMinus0'] = (<span style={{ fontSize: '1.4rem', textDecoration: 'underline' }}>This Week</span>);
		titles['weekMinus1'] = (<span style={{ fontSize: '1.4rem', textDecoration: 'underline' }}>{`Week Ending ${now.subtract( 1, 'week' ).format('MM-DD-YYYY')}`}</span>);
		titles['weekMinus2'] = (<span style={{ fontSize: '1.4rem', textDecoration: 'underline' }}>{`Week Ending ${now.subtract( 1, 'week' ).format('MM-DD-YYYY')}`}</span>);
		titles['weekMinus3'] = (<span style={{ fontSize: '1.4rem', textDecoration: 'underline' }}>{`Week Ending ${now.subtract( 1, 'week' ).format('MM-DD-YYYY')}`}</span>);
		set_accordionTitles(titles);

		const app = Mosaic.Qlik.app[appId];
		if ( app ) {
			const onQVizData = ( qViz ) => {
				const weekMinus0 = Utils.moment();
				if ( weekMinus0.isoWeekday() <= 5 ) {
					weekMinus0.isoWeekday( 'Friday' );
				} else {
					weekMinus0.isoWeekday( 'Friday' ).add( 1, 'week' );
				}
				const weekMinus1 = weekMinus0.clone().subtract( 1, 'week' );
				const weekMinus2 = weekMinus1.clone().subtract( 1, 'week' );
				const weekMinus3 = weekMinus2.clone().subtract( 1, 'week' );

				const tempEvents = {
					weekMinus0: [],
					weekMinus1: [],
					weekMinus2: [],
					weekMinus3: [],
				};
				const fromTable = {
					companies: {
						All: 0,
					},
					teams: {
						All: 0,
					},
					types: {
						All: 0,
					},
					totalCount: {
						All: 0,
						teams: {},
						companies: {},
						types: {},
					},
				};
				for ( let ii = 0; ii < qViz.table.rows.length; ii += 1 ) {
					if ( props.sample && ( ii % ( parseInt( props.sample, 10 ) || 50 ) !== 0 )) continue;

					const row = qViz.table.rows[ii];
					const eventDate = Utils.moment( row.cells[2].qText );

					const activity = row.cells[1].qText;
					let icon;
					if ( activity.indexOf( 'Call' ) >= 0 || activity.indexOf( 'MSL Engagement' ) >= 0 ) {
						icon = <TeamOutlined/>;
					} else if ( activity.indexOf( 'Speaker Program' ) >= 0 ) {
						icon = <AudioFilled/>;
					} else {
						icon = <MailOutlined/>;
					}

					const filterType = row.cells[4].qText;
					const companyName = row.cells[5].qText;
					const teamName = row.cells[6].qText;
					fromTable.types.All += 1;
					fromTable.companies.All += 1;
					fromTable.teams.All += 1;
					fromTable.types[filterType] = fromTable.types[filterType] +1 || 1;
					fromTable.companies[companyName] = fromTable.companies[companyName] +1 || 1;
					fromTable.teams[teamName] = fromTable.teams[teamName] +1 || 1;
					fromTable.totalCount.All += 1;
					fromTable.totalCount.companies[companyName] = (fromTable.totalCount.companies[companyName] || 0) + 1;
					fromTable.totalCount.teams[teamName] = (fromTable.totalCount.teams[teamName] || 0) + 1;
					fromTable.totalCount.types[filterType] = (fromTable.totalCount.types[filterType] || 0) + 1;
					const event = {
						prof: row.cells[0].qText,
						profId: row.cells[0].qText.split('(')[1].split(')')[0],
						activity,
						labels: [{ content: teamName, color: 'orange' }],
						// labels: [],
						date: eventDate,
						employeeName: row.cells[3].qText,
						filterType,
						companyName,
						teamName,
						count: row.cells[7].qNum,
						icon,
						iconStyle: ( row.cells[1].qText === 'Call' )
							? { background: 'var(--MagentaHEX)', color: '#fff' }
							: { background: 'var(--DarkGreyHEX)', color: '#fff' },
						contentStyle: ( row.cells[1].qText === 'Call' )
							? { background: 'var(--WhiteFogHEX)', color: '#0D0D0D' }
							: { background: 'var(--WhiteFogHEX)', color: '#0D0D0D' },
					};

					if ( eventDate.isBefore( weekMinus3 )) {
						tempEvents.weekMinus3.push( event );
					} else if ( eventDate.isBefore( weekMinus2 )) {
						tempEvents.weekMinus2.push( event );
					} else if ( eventDate.isBefore( weekMinus1 )) {
						tempEvents.weekMinus1.push( event );
					} else {
						tempEvents.weekMinus0.push( event );
					}
				}

				setRowFilters(filterVisibleFilters(fromTable));

				set_events( tempEvents );
			};

			app.visualization
				.get(ids.get('Mosaic_Events_table_Events - Recent Activity')?.id)
				.then(( qViz ) => {
					qViz.table.addOnDataListener(() => {
						onQVizData( qViz );
						if ( qViz.table.rows.length !== qViz.table.rowCount && ( !props.rowLimit || ( props.rowLimit && qViz.table.rows.length < props.rowLimit ))) {
							qViz.table.getMoreData();
						}
					});
					qViz.table._instance.getMoreData = generateGetMoreData();
					qViz.table.getMoreData();
					set_tableObject( qViz );
				});
		}
    }, []);

    useEffect(() => {
			return () => {
				if (tableObject && tableObject.close) tableObject.close();
			};
    }, [tableObject]);

	// This updates the table when the selections change
	useEffect(() => {
		app.visualization
			.get(ids.get('Mosaic_Events_table_Events - Recent Activity')?.id)
			.then(( qViz ) => {
				qViz.table.getMoreData();
			});
	}, [QlikLastModified])

	useEffect(() => {
		if(tableObject?.table) {
			setRowFilters(filterVisibleFilters(tableObject.table.rows.reduce((acc, row) => {
				const filterType = row.cells[4].qText;
				const companyName = row.cells[5].qText;
				const teamName = row.cells[6].qText;
				acc.types[filterType] = acc.types[filterType] || 0;
				acc.companies[companyName] = acc.companies[companyName] || 0;
				acc.teams[teamName] =  acc.teams[teamName] || 0;
				acc.totalCount = acc.totalCount || {
						All: 0,
						teams: {},
						companies: {},
						types: {},
				};
				if (selectedType === filterType){
					acc.types.All += 1;
					acc.types[filterType] = acc.types[filterType] +1 || 1;
				} else if (!selectedType) {
					acc.types.All += 1;
					acc.types[filterType] = acc.types[filterType] +1 || 1;
				}
				if (selectedCompany === companyName){
					acc.teams.All += 1;
					acc.teams[teamName] = acc.teams[teamName] +1 || 1;
				} else if (!selectedCompany) {
					acc.teams.All += 1;
					acc.teams[teamName] = acc.teams[teamName] +1 || 1;
				}
				if (selectedTeam === teamName){
					acc.companies.All += 1;
					acc.companies[companyName] = acc.companies[companyName] +1 || 1;
				} else if (!selectedTeam) {
					acc.companies.All += 1;
					acc.companies[companyName] = acc.companies[companyName] +1 || 1;
				}
				if (
					(!selectedType || filterType === selectedType) &&
					(!selectedCompany || companyName === selectedCompany) &&
					(!selectedTeam || teamName === selectedTeam)
				) {
					acc.totalCount.All += 1;
					if (!selectedCompany || companyName === selectedCompany) {
						acc.totalCount.companies[companyName] =
							(acc.totalCount.companies[companyName] || 0) + 1;
					}
					if (!selectedTeam || teamName === selectedTeam) {
						acc.totalCount.teams[teamName] =
							(acc.totalCount.teams[teamName] || 0) + 1;
					}
					if (!selectedType || filterType === selectedType) {
						acc.totalCount.types[filterType] =
							(acc.totalCount.types[filterType] || 0) + 1;
					}
				}
			return acc;
		}, {companies: { All: 0 }, teams: { All: 0 }, types: { All: 0 } })));
	}
}, [selectedCompany, selectedTeam, selectedType, tableObject]);

const PaneHeader = ( { paneKey, isActive } ) => {
	return (
		<div
			style={{
				fontWeight: isActive ? 'bold' : 'normal',
				cursor: 'pointer',
			}}
			onClick={() => {
				set_activePane(paneKey === activePane ? '' : paneKey);
			}}
		>
			{accordionTitles[paneKey]}
			{' ('}
			{events[paneKey].reduce((accumulator, val) => {
				if ( selectedType && val.filterType !== selectedType ) return accumulator;
				if ( selectedCompany && val.companyName !== selectedCompany ) return accumulator;
				if ( selectedTeam && val.teamName !== selectedTeam ) return accumulator;
				return accumulator + 1;
			}, 0)}
			{')'}
		</div>
	)	;
};

	const panels = [
		{ key: 'weekMinus0' },
		{ key: 'weekMinus1' },
		{ key: 'weekMinus2' },
		{ key: 'weekMinus3' },
	];

	const segments = panels.map(( val, ind ) => {
		return (
			<Segment
				key={`recent_${val.key}`}
				basic
				color="#005cab"
				active={activePane === val.key}
				className={`${RecentStyles.segmentContainer} ${activePane === val.key ? RecentStyles.activeSegmentContainer : ''}`}
			>
				<div className={RecentStyles.segmentItem}>
				<PaneHeader
					paneKey={val.key}
					isActive={activePane === val.key}
				/>
					{activePane === val.key && (
						!!events[val.key].length ? [
							<br />,
							<EventsPane
								items={events[val.key]}
								selectedType={selectedType}
								selectedCompany={selectedCompany}
								selectedTeam={selectedTeam}
							/>,
						] :
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description="No Recent Events"
								className={RecentStyles.noEvents}
							/>)}
				</div>
			</Segment>
		);
	});

	return <div className={RecentStyles.recentContainer}>{segments}</div>;
};

export default Recent;
