import { QlikAPI, QlikAppInstance, QlikBookmark, QlikDataPage, QlikFieldInstance, QlikFieldValue, QlikGenericObject, QlikHeader, QlikModel, QlikSelection, QlikSelectionState, QlikTable, QlikTableRow, QlikVariable, QlikVariableInstance, QlikVariableProperties, QlikVisualization, QlikVisualizationInstance } from "./MosaicGlobal"

export class QlikAPIFacade implements QlikAPI {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    getGlobal(config: object) { return this._instance.getGlobal(...arguments) }
    openApp(appId: string, config: object) { return new QlikAppInstanceFacade(this._instance.openApp(...arguments)) }
}

export class QlikAppInstanceFacade implements QlikAppInstance {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get bookmark() { return new QlikBookmarkFacade(this._instance.bookmark) }
    get id() { return this._instance.id }
    _lastModified: number
    get lastModified() { return this._lastModified }
    set lastModified(value) { this._lastModified = value }
    _selections: QlikSelection[]
    get selections() { return this._selections }
    set selections(value) { this._selections = value }
    _selectionsCount: number
    get selectionsCount() { return this._selectionsCount }
    set selectionsCount(value) { this._selectionsCount = value }
    get variable() { return new QlikVariableFacade(this._instance.variable) }
    get visualization() { return new QlikVisualizationFacade(this._instance.visualization) }
    addAlternateState(qStateName: string) { return this._instance.addAlternateState(...arguments) }
    clearAll(lockedAlso?: boolean, state?: string) { return this._instance.clearAll(...arguments) }
    createGenericObject(definition: object, callback?: any) { return this._instance.createGenericObject(...arguments) }
    createList(definition: object, callback?: any) { return this._instance.createList(...arguments) }
    field(fieldName: string) { return new QlikFieldInstanceFacade(this._instance.field(...arguments)) }
    forward() { return this._instance.forward(...arguments) }
    getList(listType: string, callback: any) { return this._instance.getList(...arguments) }
    getObject(id:string, elem?: Element|string, options?:any) { return this._instance.getObject(...arguments) }
    lockAll() { return this._instance.lockAll(...arguments) }
    removeAlternateState(qStateName: string) { return this._instance.removeAlternateState(...arguments) }
    searchAssociations(qTerms: Array<any>, qPage: any, qOptions?: any, callback?: (reply: any) => void) { return this._instance.searchAssociations(...arguments) }
    searchResults(qTerms: Array<any>, qPage: any, qOptions?: any, callback?: (reply: any) => void) { return this._instance.searchResults(...arguments) }
    selectionState(state?: string) { return new QlikSelectionStateFacade(this._instance.selectionState(...arguments)) }
    unlockAll() { return this._instance.unlockAll(...arguments) }
}

export class QlikBookmarkFacade implements QlikBookmark {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    apply(id: string) { return this._instance.apply(...arguments) }
    create(title: string, desciption: string, sheedId?: string) { return this._instance.create(...arguments) }
    remove(id: string) { return this._instance.remove(...arguments) }
}

export class QlikSelectionStateFacade implements QlikSelectionState {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get selections() { return this._instance.selections }
    addOnDataListener(callback: any) { this._instance.OnData.bind(callback) }
    removeOnDataListener(callback: any) { this._instance.OnData.unbind(callback) }
}

export class QlikVariableFacade implements QlikVariable {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    createSessionVariable(props: QlikVariableProperties) { return this._instance.createSessionVariable(...arguments).then(instance => new QlikVariableInstanceFacade(instance)) }
    getByName(variableName: string) { return this._instance.getByName(...arguments).then(instance => new QlikVariableInstanceFacade(instance)) }
    getContent(variableName: string, callback?: any) { return this._instance.getContent(...arguments) }
    setStringValue(variableName: string, newValue: string) { return this._instance.setStringValue(...arguments) }
    setNumValue(variableName: string, newValue: number) { return this._instance.setNumValue(...arguments) }
}

export class QlikVisualizationFacade implements QlikVisualization {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get(mashupId: string) { return this._instance.get(...arguments).then(instance => new QlikVisualizationInstanceFacade(instance)) }
    create(type: string, description: object, title: object) { return this._instance.create(...arguments).then(instance => new QlikVisualizationInstanceFacade(instance)) }
}

export class QlikFieldInstanceFacade implements QlikFieldInstance {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get fldname() { return this._instance.fldname }
    get rows() { return this._instance.rows.map(row => new QlikFieldValueFacade(row)) }
    get stateCounts() { return this._instance.stateCounts }
    addOnDataListener(callback: any) { this._instance.OnData.bind(callback) }
    clear() { return this._instance.clear(...arguments) }
    clearOther(softlock?: boolean) { return this._instance.clearOther(...arguments) }
    getData(options?: any) { return this._instance.getData(...arguments) }
    lock() { return this._instance.lock(...arguments) }
    onceOnDataListener(callback: any) { this._instance.OnData.once(callback) }
    selectAll() { return this._instance.selectAll(...arguments) }
    selectMatch() { return this._instance.selectMatch(...arguments) }
    selectPossible() { return this._instance.selectPossible(...arguments) }
    selectValues(fieldValues: (number|string|QlikFieldValueFacade)[], toggle?: boolean, softlock?: boolean) { return this._instance.selectValues(...arguments) }
    unlock() { return this._instance.unlock(...arguments) }

}

export class QlikVisualizationInstanceFacade implements QlikVisualizationInstance {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get id() { return this._instance.id }
    get model() { return this._instance.model && new QlikModelFacade(this._instance.model) }
    get table() { return this._instance.table && new QlikTableFacade(this._instance.table) }
    get qapp() { return new QlikAppInstanceFacade(this._instance.qapp) }
    close() { return this._instance.close(...arguments) }
    exportData(options: any) { return this._instance.exportData(...arguments) }
    exportImg(options: any) { return this._instance.exportImg(...arguments) }
    exportPdf(options: any) { return this._instance.exportPdf(...arguments) }
    show(elementId: string) { return this._instance.show(...arguments) }
}

export class QlikFieldValueFacade implements QlikFieldValue {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get qState() { return this._instance.qState }
    get qText() { return this._instance.qText }
    select(toggle?: boolean, softlock?: boolean) { return this._instance.select(...arguments) }
}

export class QlikModelFacade implements QlikModel {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get enigmaModel() { return this._instance.enigmaModel }
    get layout() { return this._instance.layout }
    addInvalidatedListener(callback: any) { this._instance.Invalidated.bind(callback) }
    addValidatedListener(callback: any) { this._instance.Validated.bind(callback) }
    applyPatches(qPatches: any, qSoftLock?: boolean) { return this._instance.applyPatches(...arguments) }
    clearSelections(path: string) { return this._instance.clearSelections(...arguments) }
    exportData(options: any) { return this._instance.exportData(...arguments) }
    getHyperCubeData(path: string, pagingInfo: object[]) { return this._instance.getHyperCubeData(...arguments) }
    getLayout(options?: any) { return this._instance.getLayout(...arguments) }
    getListObjectData(path: string, pagingInfo: object[]) { return this._instance.getListObjectData(...arguments) }
    removeInvalidatedListener(callback: any) { this._instance.Invalidated.unbind(callback) }
    removeValidatedListener(callback: any) { this._instance.Validated.unbind(callback) }
    searchListObjectFor(qPath: string, qMatch: string) { return this._instance.searchListObjectFor(...arguments) }
    selectHyperCubeValues(qPath: string, qDimNo: number, qValues: number[], qToggleMode: boolean) { return this._instance.selectHyperCubeValues(...arguments) }
    selectListObjectValues(qPath: string, qValues: number[], qToggleMode?: boolean, qSoftLock?: boolean) { return this._instance.selectListObjectValues(...arguments) }
}

export class QlikVariableInstanceFacade extends QlikModelFacade implements QlikVariableInstance {
}

export class QlikTableFacade implements QlikTable {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get app() { return new QlikAppInstanceFacade(this._instance.app) }
    get colCount() { return this._instance.colCount }
    get headers() { return this._instance.headers.map(header => new QlikHeaderFacade(header)) }
    get model() { return new QlikModelFacade(this._instance.model) }
    get rowCount() { return this._instance.rowCount }
    get rows() { return this._instance.rows }
    get totals() { return this._instance.totals }
    addOnDataListener(callback: any) { this._instance.OnData.bind(callback) }
    async getFilteredByFieldSearch(field: QlikFieldInstance, searchString: string) {
        await field.selectMatch(`*${searchString}*`, true)
        await field.getData({rows: 0})
        const filteredDataPromise = new Promise<any>((resolve, reject) => {
            field.onceOnDataListener(async () => {
                if (field.stateCounts.qSelected == 0) resolve(null)
                let dataPage = await this?.model.getHyperCubeData(
                '/qHyperCubeDef',	
                [
                    {
                        qLeft: 0,
                        qWidth: this.headers.length,
                        qTop: 0,
                        qHeight: 500,
                    },
                ])
                await field.clear()
                resolve(dataPage[0])
            })
        })

        return filteredDataPromise
    }
    getMoreData() { return this._instance.getMoreData(...arguments) }
    removeOnDataListener(callback: any) { this._instance.OnData.unbind(callback) }
}

export class QlikGenericObjectFacade implements QlikGenericObject {
    constructor(instance) {
        this._instance = instance
    }
    _instance: any
    get qDimensionInfo() { return this._instance.qDimensionInfo }
    get qMeasureInfo() { return this._instance.qMeasureInfo }
    close() { return this._instance.close() }
}

export class QlikHeaderFacade extends QlikGenericObjectFacade implements QlikHeader {
    get cId() { return this.qMeasureInfo ? this.qMeasureInfo.cId : this.qDimensionInfo.cId; } //Mosaic Custom Property
    get errorCode() { return this._instance.errorCode }
    get field() { return this._instance.field ? new QlikFieldInstanceFacade(this._instance.field) : null }
    get isDimension() { return this._instance.isDimension }
    get isOrderedBy() { return this._instance.isOrderedBy }
    get parent() { return new QlikHeaderFacade(this._instance.header) }
    get qFallbackTitle() { return this._instance.qFallbackTitle }
    get qReverseSort() { return this._instance.qReverseSort }
    get qSortIndicator() { return this._instance.qSortIndicator }
    get qText() { return this._instance.qText }
    orderBy() { this._instance.orderBy() }
    reverseOrder() { this._instance.reverseOrder() }
    selectRange(min: Number, max: Number, inclMin: boolean, inclMax: boolean) { return this._instance.selectRange(...arguments) }
}

export default QlikAPIFacade