import React from 'react';

import { RGLGrid as Grid, NavGroupMosaic, LeftSlider } from '@trinity-incyte/ui';
import { use360ProfSelection } from '@trinity-incyte/hooks';

const AdBoards = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection( profId );
	const urlSuffix = ( profId ) ? `/${profId}` : '';

	let defaultLayout;

	if ( profId === false ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'green',
					text: 'Connections',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId === '-' ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'orange',
					text: 'Please select one HCP to view the overview.',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId !== false && !isProfSelected ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Loader',
				props: {
					color: 'blue',
					text: 'Loading...',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId !== false && isProfSelected ) {
		defaultLayout = {
			xs: [{
				"w": 5,
				"h": 16,
				"x": 0,
				"y": 0,
				"i": "Mosaic Overview Prof Box",
				"moved": false,
				"props": {
					"profId": profId,
				},
				"static": true,
			}, {
				w: 19,
				h: 16,
				x: 5,
				y: 0,
				i: 'Adboards Incyte Activity',
				props: {
					color: 'green',
					text: 'AdBoards',
					showExports: true,
					tableProps: {
						colOverrides: [
							{
								columns: [0],
								hStyles: { width: '6rem' },
							}, {
								columns: [1],
								hStyles: { width: '34rem' },
							}, {
								columns: [2],
								hStyles: { width: '7rem' },
							}, {
								columns: [3],
								hStyles: { width: '9rem' },
							},
						],
					},
				},
				moved: false,
				static: true,
			}],
		};
	}

	const content = (
		<>
			<NavGroupMosaic urlSuffix={urlSuffix} />
			<Grid
				title="AdBoards"
				layout={defaultLayout}
			/>
			<LeftSlider />
		</>
	);

	return ( content );
};

export default AdBoards;
