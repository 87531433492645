import React, { useContext } from 'react';
import { RGLGrid as Grid, InnerPageNavGroup } from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';

const Calendar = () => {
	const Config = useContext(ConfigContext);
	const defaultLayout = {
		xs: [{
			w: 24,
			h: 16,
			x: 0,
			y: 0,
			i: 'Message Box',
			props: {
				color: 'green',
				text: 'Calendar',
			},
			moved: false,
			static: true,
		}],
	};

	const content = (
		<>
			<InnerPageNavGroup
				dropdownText="Action Center"
				dropdownItems={[
					{ text: 'Action Center', to: '/ActionCenter' },
					{ text: 'Calendar', to: '/ActionCenter/Calendar' },
				]}
				config={Config.Qlik.FieldSales}
			/>
			<Grid
				title="Calendar"
				layout={defaultLayout}
			/>
		</>
	);

	return ( content );
};

// I have a feeling this file and the other two Action Center files are not being used in Hemonc
// if so, we should delete them

export default Calendar;
