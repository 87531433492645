import { MosaicGlobal, QlikCell, QlikVisualizationInstance } from '@trinity-incyte/api-interfaces';
import { useEffect, useRef, useState } from 'react';

declare const Mosaic: MosaicGlobal;

const DYNAMIC_ROW_LIMIT = 50;
const EMPTY_DATA = {
    cube: {},
    dimensions: [],
    measures: [],
    headers: [],
    rows: [],
};

/* eslint-disable-next-line */
export interface UseQSTableProps {
    config?: any;
    definition?: any;
    mashupId?: any;
    qViz: QlikVisualizationInstance;
    title?: any;
    options?: any;
    dynamic?: boolean;
}

export function useQSTable({
	config = null,
	definition = null,
	mashupId = null,
	qViz = null,
	title = null,
	options = {
		rowLimit: null,
    },
    dynamic = false,
}: UseQSTableProps) {
    const [qsObject, set_qsObject] = useState<QlikVisualizationInstance>(qViz);
    const [getMore, set_getMore] = useState<any>(false);
	const [data, set_data] = useState({
		cube: {},
		dimensions: [],
		measures: [],
		headers: [],
		rows: [],
	});
    const [invalid, set_invalid] = useState(true);
    const fetchIndex = useRef<number>(0);

	const getData = async (obj: QlikVisualizationInstance) => {
        const {id, table, model} = obj;
        const qWidth = table.headers.length;
        const qTop = data.rows.length;
        const qHeight = 100;
        const currentIndex = ++fetchIndex.current;

        const cube = await model.getHyperCubeData(
            '/qHyperCubeDef',	
            [
                {
                    qLeft: 0,
                    qWidth,
                    qTop,
                    qHeight,
                },
            ]
        );
        const qMatrix = cube?.[0]?.qMatrix ?? [];

        const layout = await model.getLayout();
        if (currentIndex === fetchIndex.current) {
            const cube = layout.qHyperCube;
            const dimensions = cube.qDimensionInfo;
            const measures = cube.qMeasureInfo;
            const headers = [];
            // const isPivotTable = cube.qMode === "P";

            // if (isPivotTable) {
            //     headers.push(dimensions[0]);
            //     layout.qHyperCube.qPivotDataPages[0].qTop.forEach((val) => {
            //         if (val.qText !== undefined) headers.push(val);
            //     });
            // } else {
            //     dimensions.forEach((val) => {
            //         if (val.qFallbackTitle !== undefined) headers.push(val);
            //     });
            //     measures.forEach((val) => {
            //         // Yes, it seems measures can only ever go after all dimensions
            //         if (val.qFallbackTitle !== undefined) headers.push(val);
            //     });
            // }

            // Enhance the rows with the columnId
            const columnOrder = cube.qColumnOrder;
            const effectiveOrder = cube.qEffectiveInterColumnSortOrder;

            // Should only be true if all columns are shown. Effective will be shorter if column(s) are hidden
            const hasHiddenColumns = (columnOrder.length !== effectiveOrder.length);

            let difference = [];
            if (hasHiddenColumns) {
                difference = columnOrder.filter(val => !effectiveOrder.includes(val));
            }

            // This syntheticColumnId supports selection via hypercube for cases where columns are hidden
            // This should work with multiple/single hidden columns & in cases where columns start hidden
            const newRows = qMatrix.map((val, rowIndex) => {
                let increment = 0;
                let cells = new Array<QlikCell>()
                if (cube.qMode === "P") {
                    const rowHeader = layout.qHyperCube.qPivotDataPages[0].qLeft[rowIndex]
                    const headerData = {
                        syntheticColumnId: 0,
                        qAttrExps: rowHeader.qAttrExps,
                        qElemNumber: rowHeader.qElemNo,
                        qNum: rowHeader.qValue,
                        qState: 'L',
                        qText: rowHeader.qText
                    }
                    cells.push(headerData)
                    increment += 1;
                }
                val.forEach((cell, cellInd) => {
                    if (difference.includes(cellInd)) {
                        increment += 1;
                    }
                    cell.syntheticColumnId = cellInd + increment;
                    cells.push(cell);
                });

                return cells;
            });

            set_data({
                cube,
                dimensions,
                measures,
                headers,
                rows: [...data.rows, ...newRows],
            });
            set_invalid(false);
        } else {
            // A selection was made, and this data page isn't relevant anymore
        }
    };

	const onObject = (obj: QlikVisualizationInstance) => {
		set_qsObject(obj);
		getData(obj);
    };

    const getMoreData = async () => {
        if (qsObject.model.layout.qHyperCube.qSize.qcy > data.rows.length
            && (!options.rowLimit || (data.rows.length < options.rowLimit))) {
            getData(qViz);
        }
    };

	useEffect(() => {
		onObject(qViz);
		return () => {
			qViz.close();
		}
	}, [qViz.id]);

	useEffect(() => {
		if (!definition && !mashupId) return;
		const { appId } = config.ids;
		const app = Mosaic.Qlik.app[appId];
		if (!app) return;

		if (definition) {
			app.visualization.create(
				'table',
				definition,
				title,
			)
			.then(onObject)
			.catch(err => console.error(err));
		} else if (mashupId) {
			app.visualization.get(mashupId)
				.then(onObject)
				.catch(err => console.error(err));
		}
	}, [definition, mashupId]);

	useEffect(() => {
        if (!dynamic || (dynamic && !data.rows.length)) {
            getMoreData();
        } else if (dynamic) {
            if (data.rows.length < DYNAMIC_ROW_LIMIT) {
                set_getMore(false);
            } else {
                set_getMore((_) => getMoreData);
            }
        }
	}, [data]);

    useEffect(() => {
        const onInvalidateHandler = () => { 
            set_invalid(true)
            qsObject?.model.getLayout()
        };
        const onValidatedHandler = () => { set_data({...EMPTY_DATA}) };
        qsObject?.model.addValidatedListener(onValidatedHandler);
        qsObject?.model.addInvalidatedListener(onInvalidateHandler);
        return () => {
            qsObject?.model.removeValidatedListener(onValidatedHandler);
            qsObject?.model.removeInvalidatedListener(onInvalidateHandler);
        }
    }, [qsObject?.model?._instance]);

    const dataToReturn = invalid ? EMPTY_DATA : data;

	return {
        object: qsObject,
        ...dataToReturn,
        getMore,
        triggerSort: (cId: string) => {

            const header = qViz.table.headers.find((curr) => {
                if ((curr?.qDimensionInfo?.cId === cId) || (curr?.qMeasureInfo?.cId === cId)) return curr;  
            });

            if (header.isOrderedBy) {
                header.reverseOrder();
            } else {
                header.orderBy();
            }
            // Reset the table to apply the new order
            set_data(EMPTY_DATA);
            set_invalid(true);
        }
	};
};

export default useQSTable;
