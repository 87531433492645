import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {Col, Divider, List, Row} from 'antd';
import {
    activeBrand as activeBrandAtom,
    activeTeam as activeTeamAtom,
    QlikLastModified as QlikLastModifiedAtom,
    IncyteTeam,
		QSAppMetadataFamily,
} from '@trinity-incyte/recoil';
import { LinkOutlined } from '@ant-design/icons';
import { convertTeam, PathGenerators } from '@trinity-incyte/utils';
import { QSStyleOverrides } from '@trinity-incyte/ui';
import { MosaicGlobal, QlikVisualizationInstance } from '@trinity-incyte/api-interfaces';
import KeySegmentsCountStyles from './KeySegmentsCount.module.scss';
import { useMashup } from '@trinity-incyte/hooks';

declare const Mosaic: MosaicGlobal;
declare var window: any;

const KeySegmentsCount = ( {config} ) => {
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const { IDsTable: ids } = metadata;
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const activeBrand = useRecoilValue(activeBrandAtom);
	const activeTeam = useRecoilValue<IncyteTeam>(activeTeamAtom);
	const [counts, set_counts] = useState({});
	const [tableObject, set_tableObject] = useState<any>();
  const app = Mosaic.Qlik.app[appId];
  const newTab = 'Details';

	let objectId, activeLink;
  if (activeTeam === 'HO3') {
      objectId = ids.get('Mosaic_Main - Monjuvi_table_Monjuvi Key Segments')?.id;
      activeLink = 'MONJUVI';
  } else {
      objectId = ids.get('Mosaic_Main - Jakafi_table_Jakafi Key Segments')?.id;
      activeLink = 'JAKAFI';
  }

	const onQVizData = ( qViz: QlikVisualizationInstance ) => {
		const tempCounts = {};
		for ( let ii = 0; ii < qViz.table.rows[0]?.cells?.length; ii += 1 ) {
			let header = qViz.table.headers[ii].qFallbackTitle;
			let cell = qViz.table.rows[0].cells[ii].qText;
				tempCounts[header] = {
					count: cell,
					link: PathGenerators.FieldSalesPathGenerator(activeTeam, `/${activeLink}/${newTab}/${header}`),
				};
		}
		set_counts( tempCounts );
	};

	useEffect(() => {
		app?.visualization
			.get(objectId)
			.then(( qViz ) => {
				const listener = () => {
					if (!qViz.table) return;
					onQVizData( qViz );
					qViz.table.removeOnDataListener( listener );
				};
				qViz.table.addOnDataListener( listener );
				qViz.table.getMoreData();
				set_tableObject( qViz );
			});
    }, [activeTeam]);

    useEffect(() => {
        return () => {
            if (tableObject && tableObject.close) tableObject.close();
        };
    }, [tableObject]);

	// This updates the table when the selections change
	useEffect(() => {
		app?.visualization
			.get(objectId)
			.then(( qViz ) => {
				const listener = () => {
					if (!qViz.table) return;
					onQVizData( qViz );
					qViz.table.removeOnDataListener( listener );
				};
				qViz.table.addOnDataListener( listener );
				qViz.table.getMoreData();
			});
	}, [QlikLastModified])

  const columns = Object.keys( counts );
	const content = columns.map((val, ind) => {
		return (
			<List.Item
        className="key-segments-item"
				style={{padding: '0px'}}
				key={val}
				onClick={() => window.analytics?.track('Link Clicked', { activeTeam: activeTeam, activeBrand: activeBrand, text: val, context: 'Key Segments Link Clicked' })}
			>
				<Row className={KeySegmentsCountStyles.keySegmentsRow}>
						<Col span={17} className={KeySegmentsCountStyles.keySegmentsLabel}>
							<NavLink to={`${counts[val].link}`} style={{color:"#595959"}}>{val} <LinkOutlined /></NavLink>
						</Col>
					<Col span={7} className={KeySegmentsCountStyles.keySegmentsCountValue}>
							<NavLink to={`${counts[val].link}`} style={{fontWeight: 400, color:"#005cab"}}>{counts[val].count}</NavLink>
					</Col>
				</Row>
			</List.Item>
		);
	});

	return (
		<List className="key-segments-list" style={{padding:"0.5rem 0.5rem 0 0"}}>
			{content}
		</List>
	);
};

export const OrderedKeySegments = ({config, activeBrand, activeTeam}) => {
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;
	const newTab = 'Details';

	const keySegmentsViz = useMashup(appId, `Mosaic_Main - OCNE_table_OCNE Key Segments`);
    const counts = useMemo(() => {
		if (keySegmentsViz?.table?.rows == null) return
		const tempCounts = [];
		const nameColumnIndex = 1, orderColumnIndex = 2, brandColumnIndex = 3, friendlyNameColumnIndex = 4, countColumnIndex = 5;
		const rows = [...keySegmentsViz.table.rows];
		const orderedRows = rows.sort((a, b) => a.cells[orderColumnIndex].qText - b.cells[orderColumnIndex].qText)
		orderedRows.forEach((row) => {
			const reportName = ids.get(row.cells[friendlyNameColumnIndex].qText)?.description;
			const link = PathGenerators.FieldSalesPathGenerator(activeTeam, `/${row.cells[brandColumnIndex].qText}/${newTab}/${encodeURIComponent(reportName.replace(/%/g, 'Percent'))}`);
			tempCounts.push({
				name: row.cells[nameColumnIndex].qText,
				count: row.cells[countColumnIndex].qText,
				link: link
			})
		});
		return tempCounts
    }, [keySegmentsViz?.table?.rows, keySegmentsViz?.table?.rows.length]);
	
	return (
		<List className="key-segments-list" style={{padding:"0.5rem 0.5rem 0 0"}}>
			{counts?.map((row) => {
				return (
					<List.Item
						className="key-segments-item"
						style={{padding: '0px'}}
						key={row.name}
						onClick={() => window.analytics?.track('Link Clicked', { activeTeam: activeTeam, activeBrand: activeBrand, text: row.name, context: 'Key Segments Link Clicked' })}
					>
						<Row className={KeySegmentsCountStyles.keySegmentsRow}>
								<Col span={16} className={KeySegmentsCountStyles.keySegmentsLabel}>
									<NavLink to={`${row.link}`} style={{color:"#595959"}}>{row.name} <LinkOutlined /></NavLink>
								</Col>
							<Col span={8} className={KeySegmentsCountStyles.keySegmentsCountValue}>
									<NavLink to={`${row.link}`} style={{fontWeight: 400, color:"#005cab"}}>{row.count}</NavLink>
								</Col>
						</Row>
					</List.Item>
				);
			})}
		</List>
	);
}

export default KeySegmentsCount;