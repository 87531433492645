import React, { useContext } from "react"
import { HCO360Context, HCO360State } from '@trinity-incyte/context'
import HCO360Router from "./HCO360Router"
import { RGLGrid, LeftSlider } from "@trinity-incyte/ui"
import { HCO360Org } from "./HCO360Org"
import HCO360TopNav from "./HCO360TopNav"
import "./HCO360.scss"

const layouts = {
    withOrg: [{
        w: 5,
        i: "HCO360Org",
        content: { component: (HCO360Org) }
    }, {
        w: 19,
        i: "HCO360Router",
        content: { component: (HCO360Router) }
    }],
    withoutOrg: [{
        w: 24,
        i: "HCO360Router",
        content: { component: (HCO360Router) }
    }]
}

const HCO360Content = () => {
    const hco360Context = useContext(HCO360Context);

    return (
        <>
            <HCO360TopNav />
            <RGLGrid
                layout={{responsive: hco360Context.state.showGroup ? layouts.withOrg : layouts.withoutOrg}}
            />
        </>
    )
}

export const HCO360Home = (props) => {
    const groupId = props?.groupId;
    return (
        <HCO360State groupId={groupId}>
            <HCO360Content />
            <LeftSlider />
        </HCO360State>
    )
}

export default HCO360Home