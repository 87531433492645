import React, { useEffect, useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { RGLGrid, BottomSlider, LeftSlider, SubPage } from '@trinity-incyte/ui';
import { useNon360ClearProfSelection } from '@trinity-incyte/hooks';
import FieldSalesNav from '../../Components/NavBars/FieldSales';
import { ConfigContext } from '@trinity-incyte/context';
import { 
	activeTeam,
	QSAppMetadataFamily,
 } from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const RegionAnalysis = (props) => {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;

	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;
	const selectedTeam = useRecoilValue(activeTeam);

	useNon360ClearProfSelection({
		selection: [
			{ fieldName: fields.get('Mosaic_Field Sales_Time Selection')?.id, qSelected: 'Month' },
			{ fieldName: 'DT.RetailPro.IsLast12Months', qSelected: [1] },
		],
	});

	useEffect(() => {
		const app = Mosaic.Qlik.app[appId];
		const selection = [
			{ fieldName: fields.get('Mosaic_Field Sales_Time Selection')?.id, qSelected: 'Month' },
			{ fieldName: 'DT.RetailPro.IsLast12Months', qSelected: [1] },
		];
		for ( let ii = 0; ii < selection.length; ii += 1 ) {
			const { fieldName, qSelected } = selection[ii];
			const field = app.field(fieldName);
			if ( qSelected === '*' ) {
				field.selectAll();
			} else {
				// Selection is case-sensitive
				let selectionArray;
				if (Array.isArray(qSelected)) {
					selectionArray = qSelected;
				} else {
					selectionArray = new Array(qSelected);
				}
				field.selectValues( selectionArray );
			}
		}
	}, [selectedTeam]);
	
	let defaultLayout = {
		"lg": [
			{
				"w": 24,
				"h": 2,
				"x": 0,
				"y": 0,
				"i": "Selections Bar",
				"moved": false,
				"static": true,
				"props": {
					config: config,
				}
			},
			{
				"w": 24,
				"h": 14,
				"x": 0,
				"y": 2,
				"i": "/FieldSales - RegionAnalysis",
				"moved": false,
				"static": true,
			},
		]
	}

  return (
    <>
      <FieldSalesNav />
      <RGLGrid
        title="Sales Details"
        layout={defaultLayout}
        className="hide-y"
      >
        <BottomSlider />
        <LeftSlider />
      </RGLGrid>
    </>
  );
};

export default RegionAnalysis;
