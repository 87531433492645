import React, { useMemo } from 'react';
import { StarFilled } from "@ant-design/icons";
import { useMashup } from '@trinity-incyte/hooks';

export const BarrierSegment = ({appId, mashupName: mashupName, customLabelStyle={}, customClass='qsTableTile', customContainerClass='qsTableTileContainer'}) => {
    const barriersViz = useMashup(appId, mashupName);
    const tileData = useMemo(() => {
        return barriersViz?.table?.rows.map((row, rowIndex)=>{
            return { 
                indication: row.cells[0].qText, 
                barrierName: row.cells[1].qText,
                barrierDate: row.cells[2].qText,
                recentFlag: row.cells[3].qText,
                hcpBarrier: row.cells[4].qText,
                highlightFlag: row.cells[5].qText,
                index: rowIndex
            }
        });
    }, [barriersViz?.table?.rows, barriersViz?.table?.rows.length]);

    let indication = "";
	let showIndication = false;
    let orangeColor = `#FF8000`;
    let indicationObj_selection = {};

    return (
        <div className={customContainerClass}>
            {
            tileData?.map((tile)=> {
                showIndication=false;
                if (indication!=tile.indication) {
                    showIndication=true;
                    indication=tile.indication;
                    indicationObj_selection[indication] = false;
                }

                let isSelected = (tile.highlightFlag!='-');
                if (isSelected) {
                    indicationObj_selection[indication] = true;
                }
                let optionStyle:React.CSSProperties = {
                   width:`100%`,
                    lineHeight:`1em`, 
                    margin:`1px 0px 0px 0px`,
                    borderRadius: '4px',
                    backgroundColor: isSelected?orangeColor:``, 
                    color:isSelected?`#fff`:`#ccc`, 
                    textAlign:isSelected?`left`:`left`,
                    padding:isSelected?`4px 3px 3px 3px`:``,
                    fontWeight: isSelected?700:400,
                };
                let starDisp = (isSelected&&tile.recentFlag=="Y")?(<StarFilled className="kpi-icon" style={{ fontSize:`11px`, marginRight:'4px', color:`gold`, display:`${'-'=='-'?'inline-block':'none'}` }} />):``;
            
                return (
                    <div key={`barrierKey_${tile.indication}_${tile.index}`}>
                    {showIndication&&(<div style={{fontWeight:'bold'}}>{tile.indication}</div>)}    
                    <div key={`barrierKey_${tile.indication}_${tile.barrierName}`} className={customClass} style={optionStyle}>
                            <div key={`barrierKey_${tile.indication}_${tile.barrierName}_barrierLabel`} className='qsTableTileLabel' style={customLabelStyle}>
                                {starDisp}
                                {isSelected&&(tile.barrierDate!='-')&&(<>{tile.barrierDate} </>)}
                                {tile.barrierName}
                            </div>
                    </div>
                    </div>
                );
            })}
        </div>
	);
}

export default BarrierSegment;