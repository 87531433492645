import { useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import CryptoJS from 'crypto-js';
import { useAppMetadata } from '@trinity-incyte/hooks';
import {
	activeBrand as activeBrandAtom,
	activeTeam as activeTeamAtom,
	primaryTeam as primaryTeamAtom,
	AppState as AppStateAtom,
	defaultSelection as defaultSelectionAtom,
	QlikUser as QlikUserAtom,
	UserTerritory as UserTerritoryAtom,
	myGeoState,
} from '@trinity-incyte/recoil';
import Utils, {convertTeam, getPrimaryBrandForTeam} from '@trinity-incyte/utils';
import { MosaicGlobal, QlikAppInstance } from '@trinity-incyte/api-interfaces';
export interface useUserDataProps {
  Config: any;
  app: QlikAppInstance;
  environment: any;
}

declare const window: any;
declare const Mosaic: MosaicGlobal;

export function useUserData ({ Config, app: fieldSalesApp, environment }: useUserDataProps) {
	const set_activeTeam = useSetRecoilState(activeTeamAtom);
	const set_primaryTeam = useSetRecoilState(primaryTeamAtom);
	const [QlikUser, set_QlikUser] = useRecoilState(QlikUserAtom);
	const set_UserTerritory = useSetRecoilState(UserTerritoryAtom);
	const set_activeBrand = useSetRecoilState(activeBrandAtom);
	const set_geographyFlag = useSetRecoilState(myGeoState);
	const set_defaultSelection = useSetRecoilState(defaultSelectionAtom);

  useAppMetadata({ids: Config.Qlik.FieldSales.ids, app: fieldSalesApp});

  useEffect(() => {
    if (fieldSalesApp === null || QlikUser.empType) return;

    const glob = Mosaic.QlikAPI.getGlobal(Config.Qlik.config);
    let au, auForHash, userHash;
    glob.getAuthenticatedUser((authUser) => {
      au = authUser.qReturn;
      auForHash = au + environment.userEnvironmentSalt; // salt is blank for production to preserve legacy data
      userHash = CryptoJS.SHA256(auForHash).toString();

      interface WalkMeInterface {
        TeamName: string,
        Name: string,
        Role: string,
        UserID: string,
      }
      window.WalkMeData = window.WalkMeData || {
        TeamName: '', Name: '', Role: '', UserID: ''
      } as WalkMeInterface;
      window.WalkMeData.UserID = au.split('=')[2];
  
      // Adds the Name to the WalkMe data
      fieldSalesApp.createGenericObject({ Name: { qStringExpression: `=${Config.Qlik.FieldSales.ids.fields['User First Name']} & ' ' & ${Config.Qlik.FieldSales.ids.fields['User Last Name']}` } }).then((genericObject) => {
        
        window.WalkMeData.Name = genericObject.layout.Name;
        window.analytics?.identify(userHash,
          { 
            app: environment.routerBasename,
            environmentName: environment.environmentName,
            Name: genericObject.layout.Name, 
            qUniqueId: au 
          }, 
          { anonymousId: userHash }
        );
  
        genericObject.close();
      });
  
    }).then(() => {
      // Get user data
      // TODO: close this after data fetch
      const username = `${au.split('; ')[0].split('=')[1]}\\${au.split('; ')[1].split('=')[1]}`;
      const userDataGeneric = {
        empType: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Emp Type']}]` },
        accessType: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Access Type']}]` },
        features: {
          APP: {
            is360Only: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['APP - Is 360 Only']}]` },
          },
          HOME: {
            showFilters: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['HOME - Show Filters']}]` },
          },
          REGIONANALYSIS: {
            showNation: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['REGIONANALYSIS - Show Nation']}]` },
            showRegion: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['REGIONANALYSIS - Show Region']}]` },
          },
        },
        masqueradeName: { qStringExpression: `=ONLY({1<[SA_USERID]={${username}}>} SA_EMP_NAME)` },
        team: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Team']}]` },
        primaryTeam: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Primary Team']}]` },
      };

      Object.assign(userDataGeneric, Config.Qlik.FieldSales.userDataFields);

      fieldSalesApp.createGenericObject(userDataGeneric, () => {}) // No-op callback, only need to fetch data once
      .then((obj) => {
        const reply = obj.layout;
        let { ...userData } = reply;

        // filling out details for user analytics
        window.analytics?.identify(userHash, {
          accessType: obj.layout.accessType,
          empType: obj.layout.empType
        });

        /*
         * Parse through the QlikUser data and set semantic properties for use throughout the app
         * This is a one-time operation, i.e. DOES NOT UPDATE FOR MASQUERADE
         */
        const userTeams = userData.team.toString().split(',') as Array<string>;
        const primaryTeam: any = convertTeam(userData.primaryTeam);
        set_primaryTeam(primaryTeam);
        set_activeTeam(primaryTeam);

        let activeBrand = getPrimaryBrandForTeam(Config.App.teamToBrand, primaryTeam);
        set_activeBrand(activeBrand);

        // Directors (DBD, OBD) and Admins see the team selector
        if (userTeams.length >= 2) {
           userData.showTeamSelector = userTeams.map((val) => (convertTeam(val)));
        } else {
           userData.showTeamSelector = false;
        }

        if (userData.accessType === "TRAINER") {
          userData.isTrainer = true;
        } else {
          userData.isTrainer = false;
        }

        if (userData.accessType === 'ADMIN' && userData.features.APP.is360Only !== 'Y' && userData.isTrainer === false) {
          userData.showMasquerade = true;
        } else {
          userData.showMasquerade = false;
        }

        if (userData.isTrainer) {
          userData.showSidebarFilters = false;
        } else {
          userData.showSidebarFilters = true;
        }

        // DERM - if the user is DTS don't show the avg calls per day page or the TRx Report Territory filter
        if (userData.empType !== 'DTS') {
          userData.showTerritoryComparison = true
          userData.showAvgCallsPerDay = true;
          userData.showTRxTerritoryFilter = true;
        } else {
          userData.showTerritoryComparison = false;
          userData.showAvgCallsPerDay = false;
          userData.showTRxTerritoryFilter = false;
        }

        // Only admins have myGeo checkbox set to false
        if (userData.accessType === 'ADMIN') {
          set_geographyFlag(false);
        }

        // DERM - if the user is DBD or DTS - don't show the region checkbox or the TRx Report Region filter
        if ((userData.empType === "DTS") || (userData.empType === "DBD")) {
          userData.showTRxRegionFilter = false;
          userData.showMyGeoControl = false;
          set_geographyFlag(true);
        } else if (userData.features.APP.is360Only === 'Y') {
          userData.showTRxRegionFilter = false;
          userData.showMyGeoControl = false;
        } else {
          userData.showMyGeoControl = true;
          userData.showTRxRegionFilter = true;
        }

        if (Config.App.name === 'MosaicDerm') {
          userData.showMyGeoControl = false;
        }

        userData.showHCO360 = true;

        set_QlikUser({
          authenticatedUser: au,
          ...userData,
        });
        const selection = {...Config.Qlik.FieldSales.defaultSelections};
    
        set_defaultSelection(selection);
    
        if (!window.WalkMeData.Role && userData.empType) {
          window.WalkMeData.Role = userData.empType;
        }
      });

      // Get user territory
      fieldSalesApp.createGenericObject(
        Config.App.teamToBrand.reduce((acc, team) => { 
          acc[team.teamName] = { qStringExpression: team.userTerritoryField };
          return acc;
        }, {})
      , (rawTeamTerritories) => {
        let teamTerritories = Config.App.teamToBrand.reduce((acc, team) => {
          acc[team.teamName.toLowerCase()] = rawTeamTerritories[team.teamName];
          return acc;
        }, {});
        set_UserTerritory(teamTerritories);
      });
    });
  }, [fieldSalesApp]);

  return null;
}

export default useUserData;