import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, NavGroupMosaic, LeftSlider } from '@trinity-incyte/ui';
import { activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { HCP360Context } from '@trinity-incyte/context';

const Bio = (props) => {
    const { profId } = props;
	const { profData } = useContext(HCP360Context);
    const isProfSelected = use360ProfSelection(profId);
    const activeTeam = useRecoilValue(activeTeamAtom);
    const urlSuffix = profId ? `/${profId}` : '';

    let defaultLayout;

    if (profId === false) {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Message Box',
                    props: {
                        color: 'green',
                        text: 'Connections',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId === '-') {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Message Box',
                    props: {
                        color: 'orange',
                        text: 'Please select one HCP to view the overview.',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId !== false && !isProfSelected) {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Loader',
                    props: {
                        color: 'blue',
                        text: 'Loading...',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId !== false && isProfSelected) {
        defaultLayout = {
            responsive: [
                {
                    w: 5,
                    style: { height: '100%' },
                    i: 'Mosaic Overview Prof Box',
                    props: { profId },
                },
                {
                    w: 19,
                    style: { height: '100%', padding: '4px 0 0 0' },
                    items: [
                        {
                            w: 24,
                            style: { height: '40%', padding: '0 4px 4px' },
                            contentStyle: {overflow: 'auto'},
                            i: 'Prof Bio Box',
                            props: {
                                profId,
                                profData,
                                horizontal: true,
                                activeTeam,
                            },
                        },
                        {
                            w: 24,
                            style: { height: '60%' },
                            i: 'Prof Bio Table Box',
                            props: { profId },
                        },
                    ],
                },
            ],
            /* xs: [
                {
                    w: 5,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Mosaic Overview Prof Box',
                    props: {
                        profId,
                    },
                    moved: false,
                    static: true,
                },
                {
                    w: 19,
                    h: 6,
                    x: 5,
                    y: 0,
                    i: 'Prof Bio Box',
                    props: {
                        profId,
                        profData,
                        horizontal: true,
                        activeTeam,
                    },
                    moved: false,
                    static: true,
                },
                {
                    w: 19,
                    h: 10,
                    x: 5,
                    y: 6,
                    i: 'Prof Bio Table Box',
                    props: {
                        profId,
                    },
                    moved: false,
                    static: true,
                },
            ], */
        };
    }

    const content = (
        <>
            <NavGroupMosaic urlSuffix={urlSuffix} />
            <Grid title="Bio" layout={defaultLayout} />
            <LeftSlider />
        </>
    );

    return content;
};

export default Bio;
