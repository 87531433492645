import React from 'react';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, LeftSlider } from '@trinity-incyte/ui';

const MonPemTrends = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection(profId);

	let defaultLayout;
	if (profId === false) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'green',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId === '-') {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== false && !isProfSelected) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Loader',
					props: {
						color: 'blue',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== '-' && profId !== false && isProfSelected) {
        defaultLayout = {
            responsive: [
                {
                    w: 24,
                    items: [
                        {
                            w: 24,
                            i: 'MONPEM Trends Sales',
                            props: { profId: profId },
                        },
                        {
                            w: 24,
                            i: 'MONPEM Trends Alerts',
                            props: { profId: profId },
                        },
                        {
                            w: 24,
                            i: 'MONPEM Trends Interactions',
                            props: { profId: profId },
                        }
                    ]
                }
            ]
        }
	} else {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	}

	return (
		<>
			<Grid
				title="Mosaic Overview"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
			<LeftSlider />
		</>
	);
};

export default MonPemTrends;
