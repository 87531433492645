import { useMashup } from '@trinity-incyte/hooks';
import React, { useMemo } from 'react';

export const QSTableTiles = ({
    appId, 
    mashupId, 
    labelColumnId, 
    valueColumnId, 
    customStyle={}, 
    customValueStyle={}, 
    customLabelStyle={}, 
    customClass='qsTableTile', 
    customContainerClass='qsTableTileContainer',
    onClickCallback=null,
}) => {

    const qviz = useMashup(appId, null, mashupId);

    const extractTileData = () => {
        const tiles = qviz?.table?.rows.map((row, rowIndex)=>{
            return { 
                label: row.cells[labelColumnId].qText, 
                value: row.cells[valueColumnId].qText,
                index: rowIndex
            }
        });
        return tiles;
    }

    if (onClickCallback!==null) {
        customStyle = {...customStyle, cursor:'pointer'}
    }

    const tileData = useMemo(extractTileData, [qviz?.table?.rows, qviz?.table?.rows?.length]);

	return (
        <div key={`container`} className={customContainerClass}>
            {tileData?.map((tile, index)=> {
                return (
                    <div key={`${tile.label}_${tile.value}`} className={customClass} style={customStyle} onClick={() => {
                            onClickCallback(index);
                        }}>
                        <div className='qsTableTileLabel' style={customLabelStyle}>{tile.label}</div>
                        <div className='qsTableTileValue' style={customValueStyle}>{tile.value}</div>
                    </div>
                );
            })}
        </div>
	);
}

export default QSTableTiles;