import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import HCO360Search from './Pages/HCO360Search';
import { HCO360Overview } from './Pages/Overview/HCO360Overview';
import { HCO360Context } from "@trinity-incyte/context";
import { HCO360ProductHome } from "./Pages/Product/HCO360ProductHome";
import { HCO360HCPs } from "./Pages/HCO360HCPs";
import { HCO360Connections } from "./Pages/HCO360Connections";
import { HCO360Reports } from "./Pages/Reports/HCO360Reports";

export const HCP360Router = () => {
    return (
        <Switch>
            <Route path="/HCO360/:groupId/:brand/Reports" render={(props) => {
                return (<HCO360Reports groupId={props.match.params.groupId} brand={props.match.params.brand} />)}
            } />
            <Route path="/HCO360/:groupId/:brand/Connections" render={(props) => {
                return (<HCO360Connections groupId={props.match.params.groupId} brand={props.match.params.brand} />)}
            } />
            <Route path="/HCO360/:groupId/:brand/HCPs" render={(props) => {
                return (<HCO360HCPs groupId={props.match.params.groupId} brand={props.match.params.brand} />)}
            } />
            <Route path="/HCO360/:groupId/:brand/" render={(props) => {
                return (<HCO360ProductHome groupId={props.match.params.groupId} brand={props.match.params.brand} />)}
            } />
            <Route path="/HCO360/:groupId" render={(props) => {
                return (<HCO360Overview groupId={props.match.params.groupId} />)}
            } />
            <Route path="/HCO360" render={(props) => {
                return (<HCO360Search />)}
            } />
        </Switch>
    )
}

export default HCP360Router;