import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360OverviewInteractions = () => {
    const defaultLayout = {
        responsive: [{
            w: 24,
            items: [{
                w: 24,
                i: 'HCO360 Overview Speaker Programs'
            },
            {
                w: 24,
                i: 'HCO360 Overview Interactions'
            },
            {
                w: 24,
                i: 'HCO360 Overview Account Enagement Affinity'
            }]
        }
    ]}

	return (
		<>
			<Grid
				title="Interactions Summary"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360OverviewInteractions;
