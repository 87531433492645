import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import TrendsPane from './Panes/Trends';
import DetailsPane from './Panes/Details';
import { TabbedContainer } from '@trinity-incyte/ui';

const TrendsContainer = ({layout}) => {
	const history = useHistory();
	
  const match = useRouteMatch("/:area/:page/:tab/:subTab");
	
	const tabChange = (activeKey) => {
		const {area, page} = (match as any).params;
    const newTab = activeKey;
		const newSubTab = 0; // This could be smarter, but defaulting back to 0 is safe
		const newUrl = `/${area}/${page}/${newTab}/${newSubTab}${history.location.hash}`;
    history.replace(newUrl);
	}

	const content = (
		<TabbedContainer
			onTabChange={tabChange}
			panes={[{
        name: 'Trends',
				content: (<TrendsPane layout={layout} />),
			}, {
        name: 'Details',
				content: (<DetailsPane layout={layout} />)
			}]}
		/>
	);

	return (content);
};

export default TrendsContainer;
