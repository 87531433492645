import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductPemazyreSales = () => {
    const defaultLayout = {
        responsive: [{
            w: 15,
            items: [{
                items: [{
                    w: 24,
                    i: 'HCO360 Pemazyre Bottles'
                }]
            }, {
                items: [{
                    w: 8,
                    i: 'HCO360 Pemazyre Growth Rate'
                }, {
                    w: 16,
                    i: 'HCO360 Pemazyre Top Prescribers'
                }]
            }, {
                items:[{
                        w: 8,
                        i: 'HCO360 Pemazyre Total Account SP Patients',
                    },
                    {
                        w: 16,
                        items: [{
                            w: 24,
                            h: 12,
                            i: 'HCO360 Pemazyre CCA Foundation Members',
                        }, {
                            w: 24,
                            h: 12,
                            i: 'HCO360 Pemazyre Average Quintile of HCPs',
                        }]
                    }
                ]
            }, {
                items: [{
                    w: 24,
                    i: 'HCO360 Pemazyre Key Individuals',
                }]
            },
            ]
        },
        {
            w: 9,
            items: [{
                w: 24,
                i: 'HCO360 Pemazyre R12M Volume'
            },
            {
                w: 24,
                i: 'HCO360 Affiliations'
            }]
        }
    ]}

	return (
		<>
			<Grid
				title="Pemazyre Sales"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductPemazyreSales;
