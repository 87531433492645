import React, { useEffect, useContext } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { RGLGrid } from '@trinity-incyte/ui';
import { LeftSlider, SubPage } from '@trinity-incyte/ui';
import { 
	activeBrand as activeBrandAtom,
	QSAppMetadataFamily
} from '@trinity-incyte/recoil';
import { useNon360ClearProfSelection } from '@trinity-incyte/hooks';
import FieldSalesNav from '../../Components/NavBars/FieldSales';
import { ConfigContext } from '@trinity-incyte/context';

const Jakafi = () => {
	const set_activeBrand = useSetRecoilState(activeBrandAtom);
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;

	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

  useNon360ClearProfSelection({
		selection: [
			{ fieldName: fields.get('Mosaic_Field Sales_Time Selection')?.id, qSelected: 'Quarter' },
		],
	});

	useEffect(() => {
		set_activeBrand('JAKAFI');
	}, []);

	let defaultLayout = {
		"lg": [
			{
				"w": 24,
				"h": 2,
				"x": 0,
				"y": 0,
				"i": "Selections Bar",
				"moved": false,
				"static": true,
				"props": {
					config: Config.Qlik.FieldSales,
				}
			},
			{
				"w": 24,
				"h": 14,
				"x": 0,
				"y": 2,
				"i": "/FieldSales - Table Container",
				"moved": false,
				"static": true,
				"props": {
					activeBrand: 'JAKAFI',
				}
			},
		]
	}

	return (
		<>
			<FieldSalesNav />
			<RGLGrid
				title="Sales Details"
				layout={defaultLayout}
				className="hide-y"
			>
				<LeftSlider />
			</RGLGrid>
		</>
	);
};

export default Jakafi;
