import React, { useState, useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { Checkbox, Button, Row } from 'antd';
import {
	QSAppMetadataFamily,
	myGeoState,
	QlikUser as QlikUserAtom,
	masqueradeUserId as masqueradeUserIdAtom,
} from '@trinity-incyte/recoil';
import { RGLGrid as Grid, LeftSlider, InnerPageNavGroup } from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';
import { useDefaultSelections, useQlikUserId } from '@trinity-incyte/hooks';
import useGlobal from '@trinity-incyte/store';
import styled from 'styled-components';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';


declare const window: any;
declare const Mosaic: MosaicGlobal;

const StyledResetButton = styled(Button)`
	font-weight: bold;
	font-size: .78571429rem;
`;

const StyledMyGeoCheckbox = styled(Checkbox)`
	font-weight: bold;
	font-size: 1rem;
	color: white;
`

const ListBuilder = (props) => {
	const [reset, set_reset] = useState(Date.now());
	const [myGeo, set_myGeo] = useRecoilState(myGeoState);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [, globalActions] = useGlobal();
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;
	const masqueradeUserId = useRecoilValue(masqueradeUserIdAtom);

	let qlikUserId = useQlikUserId();
	let userId = masqueradeUserId || qlikUserId;

	useDefaultSelections({config, selection: false}); // This clears selections on page unmount

	useEffect(() => {
		userId = masqueradeUserId || qlikUserId;
	}, [masqueradeUserId]);

	useEffect(() => {
		if (!userId) return;
        const MASQUERADE_FIELD_NAME = fields.get('Mosaic_HCP 360_Masquerade User Id')?.id; // maps to SA_USERID
        const prof360App = Mosaic.Qlik.app[config.ids.appId];
        const field = prof360App.field(MASQUERADE_FIELD_NAME);

		if (((QlikUser.showMasquerade) && (masqueradeUserId)) || (!QlikUser.showMasquerade)) {
			if (myGeo) {
				field.selectValues([userId], false, false).then(() => {
					field.lock();
				});
			} else {
				field.unlock().then(()=> {field.clear()})
			}
        } else if (masqueradeUserId===false) {
            // this condition only happens for admin
            // MasqueradeUserId can be false or undefined. If it is undefined, unlocking or clearing will cause a race condition when the hook resets
            field.unlock().then(()=> {field.clear()})
        }
    }, [userId, myGeo]);

	let defaultLayout = {
		"lg": [
			{
				"w": 24,
				"h": 2,
				"x": 0,
				"y": 0,
				"i": "Selections Bar",
				"moved": false,
				"static": true,
				"props": {
					config
				}
			},
			{
				"w": 24,
				"h": 14,
				"x": 0,
				"y": 2,
				"i": "Complex List Builder",
				"moved": false,
				"static": true,
				"props": {
					reset
				}
			},
		]
	}


	return (
		<>
			<InnerPageNavGroup
				dropdownText="List Builder"
				showFilters
				config={config}
				attached={(
					<Row align="middle">
						{(QlikUser.showMyGeoControl) && (
							<StyledMyGeoCheckbox
								onChange={(e) => {
									let current_geo = e.target.checked; 
									set_myGeo(current_geo);
								}}
								checked={myGeo}
							>
								My Geography
							</StyledMyGeoCheckbox>
						)}
						<StyledResetButton
							size="small"
							className="btn-zoomer blue"
							onClick={() => {
								globalActions.Qlik.clearSelectionsWithoutLocks(config, fields);
								set_reset(Date.now());
								window.analytics?.track('Button Clicked', { text: 'Reset List', context: 'List Builder Selections Reset'});
							}}
						>Reset List</StyledResetButton>
					</Row>
				)}
			/>
			<Grid
				title="List Builder"
				layout={defaultLayout}
				useTeamBasedFilter
			/>
			<LeftSlider />
		</>
	);
};

export default ListBuilder;
