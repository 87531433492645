import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import DateSelectionButtons from '../../Groups/DateSelectionButtons';
import { Layout, Card, Row, Button } from 'antd';
import { QSMashupObject } from '@trinity-incyte/ui';
import { IncyteTeam, QSAppMetadataFamily, closeModalSelector, openModalSelector } from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import './tabs.scss';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ExportHelper, ExportType } from 'libs/ui/src/lib/Qlik/qsexport-helper';

const { Sider } = Layout;
declare var window: any;
declare const Mosaic: MosaicGlobal;

type TrendChartConfig = {
	id: string
	tableProps: object
	key: string
	chartName: string
}

const getTrendsCharts = (selectedTeam: IncyteTeam, selectedBrand: string):TrendChartConfig[] => {	
	const Config = useContext(ConfigContext);
	const { appId } = Config.Qlik.FieldSales.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;

	let trendsOptions = Config.App.reports.trends.filter(({types, teams, brand}, index) => {
		return teams.includes(selectedTeam) && brand === selectedBrand.toUpperCase();
	});

	let trendsCharts = trendsOptions.map((option, index) => {
		const {id, description, tableProps} = ids.get(option.friendlyName);
		return {
			id,
			tableProps,
			key: index.toString(),
			chartName: description
		};
	});

	return trendsCharts;
};

const TrendsPane = ({ layout }) => {
	const [resizer, set_resizer] = useState(() => () => undefined);
	const history = useHistory() as any;
	const { subTab } = useParams() as any;
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const fieldSalesApp = Mosaic.Qlik.app[appId];
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const activeTeam = useRecoilValue(activeTeamAtom);
	const openModal = useSetRecoilState(openModalSelector);
	const closeModal = useSetRecoilState(closeModalSelector);

	let activeChart = subTab.replace(/Percent/g, '%');

	let trendsCharts = getTrendsCharts(activeTeam, layout);

	const changeActiveFilter = (chartId) => {
		const currentUrl = encodeURIComponent(chartId.replace(/%/g, 'Percent'));
		history.replace(currentUrl);
	} 

	const handleFilterChange = ({ target }) => {
		const { chartId: key } = target.dataset;
		const currentUrl = encodeURIComponent(key.replace(/%/g, 'Percent'));
		history.replace(currentUrl);
		window.analytics?.track('Button Clicked', { text: key, context: 'Field Sales - Trends Tab - Table Clicked' });
	};

	// Maintains backwards compatibility with older-style URL
	if (Number.isNaN(Number(activeChart)) === false) {
		const tableIndex = Number(activeChart);

		if (tableIndex <= trendsCharts.length) {
			handleFilterChange({
				target: { dataset: { chartId: trendsCharts[tableIndex].chartName } }
			});
		} else {
			handleFilterChange({
				target: { dataset: { chartId: trendsCharts[0].chartName } }
			});
		}
	}

	useEffect(()=>{
		const last12QtrField = layout.toUpperCase() === 'MONJUVI' ? fieldSalesApp.field(fields.get('Mosaic_Field Sales_RetailInt Last 12 Qtr')?.id) : fieldSalesApp.field(fields.get('Mosaic_Field Sales_RetailPro Last 12 Qtr')?.id);
		last12QtrField.selectValues([1]);

		return () => {
			last12QtrField.clear();
		}	
	}, [layout]);

	const TrendsMainChart = () => {
		//If chart in URL not found, default to 1st chart
		const chartConfig = trendsCharts.find(chart => chart.chartName === activeChart) ?? trendsCharts[0];
		return (
			<QSMashupObject
				showExports
				exportAsImg
				exportAsPPT
				exportAsXLS
				key={`main_${chartConfig.chartName}`}
				appId={appId}
				mashupId={chartConfig.id}
				contentClass="no-margin"
			/>
		)
	};

	const gridStyle = {
		width: (trendsCharts.length >= 8) ? 'calc(50% - 8px)' : 'calc(100% - 8px)',
		padding: 0,
		margin: '4px',
		maxHeight: '100px',
		overflow: 'hidden'
	};

	const exportAllToPPT = () => {
		if (!fieldSalesApp) {
			return;
		}

		const exportHelper = ExportHelper(Config, Mosaic.Qlik.app[appId], trendsCharts.map(tc => tc.id), openModal, closeModal, `${layout} Trends`)
		exportHelper.exportVisualization(ExportType.exportAsPPT)
	}

	return (
		<>
			<div style={{ padding: '8px' }}>
				<DateSelectionButtons size="small" layout={layout} config={config} />
			</div>
			<Layout hasSider style={{ height: 'calc(100% - 40px)', overflow: 'auto' }}>
				<Sider
					className='field-sales-trend-sider'
					collapsedWidth="100"
					width="400"
					collapsible theme="light" 
					onCollapse={() => {
						resizer();
					}}
				>
					<Row>
						<Button 
							className="download-button" 
							onClick={exportAllToPPT}
							style={{width:'100%'}}
						>
							Download All
						</Button>
					</Row>
					<Row className='field-sales-trend-row' gutter={[16, 0]}>
						{trendsCharts.map((trendChart) => (
							<Card.Grid style={gridStyle} onClick={() => changeActiveFilter(trendChart.chartName)} key={`trends-chart ${trendChart.id}`}>
								<div className='fieldSalesThumbnailLabelContainer'>{trendChart.chartName}</div>
								<div
									className='fieldSalesThumbnailContainer'								
								>
									<img 
										src={`../../../../assets/Images/field_sales_nav_thumbnails/${trendChart.chartName.replace(/%/g, 'Percent').replace(/ /g, '_').replace(/-/g, '_')}.png`} 
										className='fieldSalesThumbnails'
										style={{border:'none', width:'100%', maxWidth:'100%'}} />
								</div>		
							</Card.Grid>
						))}
					</Row>
				</Sider>
				<Layout style={{ backgroundColor: '#FFFFFF' }}>
					<div id="trendsMainChart" style={{ height: '100%' }}>
						<TrendsMainChart />
					</div>
				</Layout>
			</Layout>
		</>
	);
};

export default TrendsPane;
