import { useMashup } from "@trinity-incyte/hooks"
import React from "react";

export type PivotAxisNodeId = string

export class PivotAxisNode {
    #name: string; get name() { return this.#name }
    #value: string; get value() { return this.#value }; set value(newValue) { this.#value = newValue }
    #elementNum: number; get elementNum() { return this.#elementNum }
    #children: Array<PivotAxisNode>; get children() { return this.#children }
    #parent: PivotAxisNode; get parent() { return this.#parent }
    #visible: boolean; get visible() { return this.#visible }; set visible(value) { this.#visible = value }

    get id():PivotAxisNodeId {
        if (!this.parent) return btoa(this.name)
        return `${btoa(this.name)}_${this.parent.id}`
    }
    get level():number {
        if (!this.children.length) return 0
        return Math.max(...this.children.map(child => child.level)) + 1
    }
    get path():Array<string> {
        return this.id.split('_').map((a) => atob(a)).reverse()
    }
    get nextVisibleParent():PivotAxisNode {
        if(this?.parent?.visible) return this.parent
        return this?.parent?.nextVisibleParent
    }
    get visibleChildren():Array<PivotAxisNode> {
        return this.children.filter((child) =>  { return child.visible })
    }
    get allDecendants():Array<PivotAxisNode> {
        return this.children.flatMap((child) => {
            return [child, ...child.allDecendants]
        })
    }

    findDecendantById(id: PivotAxisNodeId):PivotAxisNode {
        for(let i = 0; i < this.children.length; i++) {
            let child = this.children[i]
            if (child.id == id) return child
            let searchResult = child.findDecendantById(id)
            if (searchResult) return searchResult
        }
    }

    getAllLeaves() {
        const leaves = new Array<PivotAxisNode>()
        this.children.forEach(child => {
            if (child.children.length) {
                leaves.push(...child.getAllLeaves())
            }
            else {
                leaves.push(child)
            }
        })
        return leaves
    }

    constructor(name: string, value: string, elementNum: number, parent: PivotAxisNode = null) {
        this.#name = name
        this.#value = value
        this.#elementNum = elementNum
        this.#children = new Array<PivotAxisNode>()
        this.#parent = parent
        this.#visible = true
    }

    addChild(newChild: PivotAxisNode) {
        this.#children.push(newChild)
    }

    static fromRawNodeData(rawNodeData, axis: PivotAxis, parent: PivotAxisNode = null) {
        var axisNode = new PivotAxisNode(rawNodeData.qText, rawNodeData.qText, rawNodeData.qElemNo, parent)
        
        if (rawNodeData.qSubNodes.length) {
            const children = rawNodeData.qSubNodes.map((subNode) => PivotAxisNode.fromRawNodeData(subNode, axis, axisNode))
            
            children.forEach((child) => {
                axisNode.addChild(child)
            })
        }

        
        return axisNode
    }
}

export class PivotAxis {
    #leafIds: Array<PivotAxisNodeId>; get leafIds() { return this.#leafIds }
    #rootNode: PivotAxisNode; get rootNode() { return this.#rootNode } 

    getNodeById(id: PivotAxisNodeId) {
        return this.rootNode.findDecendantById(id)
    }

    constructor() {
        this.#rootNode = new PivotAxisNode('root', 'root', 0, null)
    }

    static fromRawAxisData(rawAxisData) {
        const newAxis = new PivotAxis()
        rawAxisData.forEach((rawNode) => {
            newAxis.rootNode.addChild(PivotAxisNode.fromRawNodeData(rawNode, newAxis, newAxis.rootNode))
        })

        newAxis.#leafIds = newAxis.rootNode.getAllLeaves().map(leaf => leaf.id)

        return newAxis
    }
}

export class PivotDataCell {
    #columnId: PivotAxisNodeId; get columnId() { return this.#columnId }
    #rowId: PivotAxisNodeId; get rowId() { return this.#rowId }
    #value: any; get value() { return this.#value }

    constructor(rowId: PivotAxisNodeId, columnId: PivotAxisNodeId) {
        this.#columnId = columnId
        this.#rowId = rowId
    }

    static fromRawCellData(rawCellData, rowNode: PivotAxisNode, columnNode: PivotAxisNode) {
        const newCellData = new PivotDataCell(rowNode.id, columnNode.id)

        newCellData.#value = rawCellData.qText

        return newCellData
    }
}

export class PivotDataRow {
    #columns: Map<PivotAxisNodeId, PivotDataCell>; get columns() { return this.#columns }
    #rowId: PivotAxisNodeId; get rowId() { return this.#rowId }

    constructor(rowId: PivotAxisNodeId) {
        this.#columns = new Map<PivotAxisNodeId, PivotDataCell>()
        this.#rowId = rowId
    }
    
    get(id: PivotAxisNodeId) { return this.#columns.get(id) }

    static fromRawRowData(rawRowData: Array<any>, rowNode: PivotAxisNode, columnAxis: PivotAxis) {
        const newRowData = new PivotDataRow(rowNode.id)

        rawRowData.forEach((rawCellData, columnIndex) => {
            const correspondingColumnNodeId = columnAxis.leafIds[columnIndex]
            const newCellData = PivotDataCell.fromRawCellData(rawCellData, rowNode, columnAxis.getNodeById(correspondingColumnNodeId))
            newRowData.#columns.set(correspondingColumnNodeId, newCellData)
        })

        return newRowData
    }
}

export class PivotData {
    #rows: Map<PivotAxisNodeId, PivotDataRow>; get rows() { return this.#rows }

    constructor() {
        this.#rows = new Map<PivotAxisNodeId, PivotDataRow>()
    }

    get(id: PivotAxisNodeId) { return this.#rows.get(id) }

    //TODO replace Array<Array<any>> with new MosaicGlobal types for pivot tables
    static fromRawData(rawData: Array<Array<any>>, rowAxis: PivotAxis, columnAxis: PivotAxis) {
        const newTableData = new PivotData()
        
        rawData.forEach((rawRowData, rowIndex) => {
            const correspondingRowNodeId = rowAxis.leafIds[rowIndex]
            const newRowData = PivotDataRow.fromRawRowData(rawRowData, rowAxis.getNodeById(correspondingRowNodeId), columnAxis)
            newTableData.#rows.set(correspondingRowNodeId, newRowData)
        })

        return newTableData
    }
}

export class PivotTable {
    #columnAxis: PivotAxis; get columnAxis() { return this.#columnAxis }
    #rowAxis: PivotAxis; get rowAxis() { return this.#rowAxis }
    #data: PivotData; get data() { return this.#data }

    constructor() {
        this.#columnAxis = new PivotAxis()
        this.#rowAxis = new PivotAxis()
        this.#data = new PivotData()
    }

    static fromRawPivotTable(rawTableData) {
        const newPivotTable = new PivotTable()
        newPivotTable.#columnAxis = PivotAxis.fromRawAxisData(rawTableData.qTop)
        newPivotTable.#rowAxis = PivotAxis.fromRawAxisData(rawTableData.qLeft)
        newPivotTable.#data = PivotData.fromRawData(rawTableData.qData, newPivotTable.#rowAxis, newPivotTable.#columnAxis)

        return newPivotTable
    }
}

export const QSPivotTable = ({appId, mashupId, rules=[]}) => {
    const qViz = useMashup(appId, null, mashupId)

    const buildColumnHeaders = (columnAxis: PivotAxis, numRowHeaders: number) => {
        //Build a 2d array of column headers
        const columnHeaderRows = new Array<Array<JSX.Element>>()
        //One row per column header level
        for(let i = 0; i < columnAxis.rootNode.level; i++) {
            columnHeaderRows.push(new Array<JSX.Element>())
        } 

        //Make the top left cell
        columnHeaderRows[0].push((<th colSpan={numRowHeaders} rowSpan={columnAxis.rootNode.level}></th>))

        const renderHeader = (columnHeaderNode: PivotAxisNode, spanA: number, spanB: number, columnHeaderIndex: number) => {
            return (<th key={`${columnHeaderNode.id}_${columnHeaderIndex}`} colSpan={spanA} rowSpan={spanB}>{columnHeaderNode.value}</th>)
        }

        //Assemble leaf headers to build
        let nextNodeList:Array<{node: PivotAxisNode, span: number}> = columnAxis.leafIds
            .filter((nodeId) => { return columnAxis.getNodeById(nodeId).visible })
            .map((nodeId) => {
                return { node: columnAxis.getNodeById(nodeId), span: 1 }
            })
        for(let headerRowIndex = columnHeaderRows.length - 1; headerRowIndex >= 0; headerRowIndex--) {
            let level = (columnHeaderRows.length - 1) - headerRowIndex
            let currentNodeList = nextNodeList
            nextNodeList = []

            let previousNode:{node: PivotAxisNode, span: number} = null
            let parentSpanCount = 0
            currentNodeList.forEach((columnNode, columnNodeIndex) => {
                if (headerRowIndex > 0 && previousNode != null && columnNode.node.nextVisibleParent != previousNode.node.nextVisibleParent) {
                    //Add parent of previous to nextNodeList
                    nextNodeList.push({ node: previousNode.node.nextVisibleParent, span: parentSpanCount})
                    //Reset parentSpanCount
                    parentSpanCount = 0
                }

                previousNode = columnNode

                if (columnNode.node.nextVisibleParent.level - level > 1) {
                    //If this node is more than 1 level below it's parent, reschedule processing this until the next level
                    nextNodeList.push(columnNode)
                    return
                }

                parentSpanCount += columnNode.span

                //Last header? Process parent node of this node
                if (headerRowIndex > 0 && columnNodeIndex == currentNodeList.length - 1) {
                    nextNodeList.push({ node: columnNode.node.nextVisibleParent, span: parentSpanCount})
                }
                
                columnHeaderRows[headerRowIndex].push(renderHeader(columnNode.node, columnNode.span, level - columnNode.node.level + 1, columnNodeIndex))
            })
        }

        return (
            <thead style={{width: '100%'}}>
                {columnHeaderRows.map((columnHeaderRow, levelIndex) => 
                    <tr key={`column-level-${levelIndex}`} style={{width: '100%'}}>
                        {columnHeaderRow}
                    </tr>
                )}
            </thead>
        )
    }

    const buildRows = (pivotTable: PivotTable) => {
        //Build a 2d array of column headers
        const rowHeadersTable = new Array<Array<JSX.Element>>()
        //One row per column header level
        for(let i = 0; i < pivotTable.rowAxis.leafIds.length; i++) {
            rowHeadersTable.push(new Array<JSX.Element>())
        } 

        const renderHeader = (rowHeaderNode: PivotAxisNode, spanA: number, spanB: number, rowHeaderIndex: number) => {
            return (<th key={`${rowHeaderNode.id}_${rowHeaderIndex}`} rowSpan={spanA} colSpan={spanB}>{rowHeaderNode.value}</th>)
        }

        //Assemble leaf headers to build
        let nextNodeList:Array<{node: PivotAxisNode, span: number}> = pivotTable.rowAxis.leafIds
            .filter((nodeId) => { return pivotTable.rowAxis.getNodeById(nodeId).visible })
            .map((nodeId) => {
                return { node: pivotTable.rowAxis.getNodeById(nodeId), span: 1 }
            })
        let level = 0
        for(let headerRowIndex = pivotTable.rowAxis.rootNode.level - 1; headerRowIndex >= 0; headerRowIndex--) {
            let currentNodeList = nextNodeList
            nextNodeList = []

            let previousNode:{node: PivotAxisNode, span: number} = null
            let parentSpanCount = 0
            currentNodeList.forEach((rowNode, rowNodeIndex) => {
                if (headerRowIndex > 0 && previousNode != null && rowNode.node.nextVisibleParent != previousNode.node.nextVisibleParent) {
                    //Add parent of previous to nextNodeList
                    nextNodeList.push({ node: previousNode.node.nextVisibleParent, span: parentSpanCount})
                    //Reset parentSpanCount
                    parentSpanCount = 0
                }

                previousNode = rowNode

                if (rowNode.node.nextVisibleParent.level - level > 1) {
                    //If this node is more than 1 level below it's parent, reschedule processing this until the next level
                    nextNodeList.push(rowNode)
                    return
                }

                parentSpanCount += rowNode.span

                //Last header? Process parent node of this node
                if (headerRowIndex > 0 && rowNodeIndex == currentNodeList.length - 1) {
                    nextNodeList.push({ node: rowNode.node.nextVisibleParent, span: parentSpanCount})
                }
                
                rowHeadersTable[rowNodeIndex].unshift(renderHeader(rowNode.node, rowNode.span, level - rowNode.node.level + 1, headerRowIndex))
            })

            level++
        }

        return (
            <tbody style={{width: '100%'}}>
                {rowHeadersTable.map((rowHeaders, rowIndex) => {
                    return (
                        <tr key={`row-${rowIndex}`} style={{width: '100%'}}>
                            {rowHeaders}
                            {pivotTable.columnAxis.leafIds
                                .filter(columnNodeId => {
                                    return pivotTable.columnAxis.getNodeById(columnNodeId).visible
                                })
                                .map((columnNodeId) => {
                                    const rowNodeId = pivotTable.rowAxis.leafIds[rowIndex]
                                    const node = pivotTable.data.get(rowNodeId).get(columnNodeId)
                                    return (<td key={`${rowNodeId}-${columnNodeId}`}>{node.value}</td>)
                                })
                            }
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    const rawPivotData = qViz?.model?.layout?.qHyperCube?.qPivotDataPages?.[0]

    if (rawPivotData == null) return (<></>)

    const pivotTable = PivotTable.fromRawPivotTable(rawPivotData)

    rules?.forEach((rule) => rule(pivotTable))

    const columnHeaders = buildColumnHeaders(pivotTable.columnAxis, pivotTable.rowAxis.rootNode.level)
    const rows = buildRows(pivotTable)

    return (
        <table className="basicTable" style={{width: '100%'}}>
            {columnHeaders}
            {rows}
        </table>
    )
}

export const moveTotalsToTheRight = (pivotTable:PivotTable) => {
    //RULE: Move Totals to the Right
    const leavesToMove = pivotTable.columnAxis.getNodeById(`${btoa("Total")}_${btoa("root")}`).getAllLeaves().map(leaf => leaf.id)
    leavesToMove.forEach(leafToMove => {
        const leafIndex = pivotTable.columnAxis.leafIds.findIndex((leafId => leafId == leafToMove))
        pivotTable.columnAxis.leafIds.splice(leafIndex, 1)
        pivotTable.columnAxis.leafIds.push(leafToMove)
    })
    //END RULE
}

export const removeCntColumns = (pivotTable:PivotTable) => {
    //RULE: Remove Cnt Column Headers
    for (let nodeIndex = pivotTable.columnAxis.leafIds.length - 1; nodeIndex >= 0; nodeIndex--) {
        const nodeId = pivotTable.columnAxis.leafIds[nodeIndex]
        const node = pivotTable.columnAxis.getNodeById(nodeId)
        if (node.name == "Cnt") {
            node.visible = false
        }
    }
    //END RULE
}

export const hideColumnsWithVisibleOneChild = (pivotTable:PivotTable) => {
    //RULE: Hide column headers with one visible child and copy the value of the parent to the child
    pivotTable.columnAxis.rootNode.allDecendants.forEach(node => {
        const allVisibleDecendants = node.allDecendants.filter(decendant => decendant.visible)
        if (allVisibleDecendants.length == 1) {
            node.visible = false
            allVisibleDecendants[0].value = node.value
        }
    })
    //END RULE
}

export default QSPivotTable