import { QSMashupObject } from "@trinity-incyte/ui";
import { Button, Divider, Layout, Row, Space, Typography } from "antd";
import React, { useContext, useState } from "react";
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { useRecoilValue } from "recoil";
import { QSAppMetadataFamily } from "@trinity-incyte/recoil";
import { useSelections } from "@trinity-incyte/hooks";

const { Sider } = Layout;
declare const Mosaic: MosaicGlobal;

const size = (window.innerHeight < 800) ? 'small' : 'middle';

const FSNDateSelectionButtons = () => {
	const [activeButton, setActiveButton] = useState(0);
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSalesNation;
	const { appId } = config.ids;

	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	let actualCurrentYearToDate = fields.get('Mosaic_Field Sales Nation_Actual CYTD')?.id;
	let actualPreviousYear = fields.get('Mosaic_Field Sales Nation_Actual PY')?.id;
	
	const knownFieldsToBeCleared = [
		actualCurrentYearToDate,
        actualPreviousYear
	];

	const {
		appSelections,
		simpleSelections
  } = useSelections({config});

	const buttonSelection = (event, appId, fieldsToBeCleared, clearSelection?) => {
		let button;
		if (event.target.nodeName === 'BUTTON') {
			button = event.target;
		} else if (event.target.nodeName === 'SPAN') {
			button = event.target.parentElement;
		} else {
			button = event.target.firstElementChild;
		}
	
		const selectionData = JSON.parse(button.dataset.selection);
		const selectionDataKeys = Object.keys(selectionData);
	
		// Clear all possible selected fields before choosing another selection
		for (let ij = 0; ij < fieldsToBeCleared.length; ij += 1) {
			const fieldName = fieldsToBeCleared[ij];
			Mosaic.Qlik.app[appId].field(fieldName).clear();
		}
	
		if (clearSelection) return;
		for (let ii = 0; ii < selectionDataKeys.length; ii += 1) {
			const fieldName = selectionDataKeys[ii];
			const selection = selectionData[fieldName];
			const app = Mosaic.Qlik.app[appId];
			const field = app.field(fieldName);
			if (selection === '*') {
				field.selectAll();
			} else {
				// Selection is case-sensitive
				let selectionArray;
				if (Array.isArray(selectionData[fieldName])) {
					selectionArray = selectionData[fieldName];
				} else {
					selectionArray = new Array(selectionData[fieldName]);
				}
	
				field.clear().then(() => {
					field.selectValues(selectionArray);
				});
			}
		}
	};
  
	const isSelected = (option) => {
		if (!appSelections) return false;

		let optionFields = Object.keys(option);
		const optionMatches = new Array(optionFields.length).fill(0); // 1 = match

		if (optionFields.length > 0) {
			for (let ii = 0; ii < optionFields.length; ii += 1) {
				let optionValues = option[optionFields[ii]];
				if (!Array.isArray(optionValues)) optionValues = [optionValues];
				const singleFieldMatches = new Array(optionValues.length).fill(0);

				for (let ij = 0; ij < appSelections.length; ij += 1) {
					if (optionFields[ii] === appSelections[ij]?.fieldName) { // The field matches
						for (let ik = 0; ik < optionValues.length; ik += 1) {
							const optionVal = optionValues[ik];
							for (let il = 0; il < appSelections[ij]?.selectedValues.length; il += 1) {
								const selectionValue = appSelections[ij]?.selectedValues[il]?.qName;
								if (typeof optionVal === 'string' && optionVal === selectionValue) { // The field & values match
									singleFieldMatches[ik] = 1;
								} else if (typeof optionVal === 'number' && optionVal === parseInt(selectionValue, 10)) { // The field & values match
									singleFieldMatches[ik] = 1;
								}
							}
						}
					}
				}

				const countSingleFieldMatches = singleFieldMatches.reduce((acc, curr) => (acc + curr), 0);
				if (countSingleFieldMatches === optionValues.length) optionMatches[ii] = 1;
			}
		}

		const countAllFieldMatches = optionMatches.reduce((acc, curr) => (acc + curr), 0);
		const retVal = (countAllFieldMatches === optionFields.length);
		return retVal;
	};

	let buttonsArray = [
			{
				ind: 1,
				disabled: false,
				text: 'Current Year to Date',
				selection: `{"${actualCurrentYearToDate}": [-1]}`,
			},
			{
				ind: 2,
				disabled: false,
				text: 'Previous Year',
				selection: `{"${actualPreviousYear}": [-1]}`,
			}
		];

	const fieldsToBeCleared = [...knownFieldsToBeCleared];

	const content = (
		<Row style={{ width: '100%' }} justify="space-around">
			<Space align="baseline" size={[8, 16]} wrap>
				{buttonsArray.map((val) => (
						<Button
						style={{ fontSize: '1rem' }}
						key={`date-button-${val.ind}`}
						disabled={val.disabled}
						data-appid={appId}
						data-selection={val.selection}
						onClick={(event) => {
							if (val.disabled) return;
							if (activeButton !== val.ind) {
								setActiveButton(val.ind);
								buttonSelection(event, appId, fieldsToBeCleared);
							} else {
								setActiveButton(0);
								buttonSelection(event, appId, fieldsToBeCleared, true);
							}
						}}
						onKeyPress={(event) => {
							if (val.disabled) return;
							if (activeButton !== val.ind) {
								setActiveButton(val.ind);
								buttonSelection(event, appId, fieldsToBeCleared);
							} else {
								setActiveButton(0);
								buttonSelection(event, appId, fieldsToBeCleared, true);
							}
						}}
						size={size}
						type={(isSelected(JSON.parse(val.selection))) ? 'default' : 'primary'}
					>
						{val.text}
					</Button>
				))}
			</Space>
		</Row>
	);

	return (content);
};

const OregonDisclosure = (props) => {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSalesNation;
    const appId = config.ids.appId;
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids } = metadata;

    const DividerStyle = {
        marginBottom: "0px",
        marginTop: "18px",
    }

    const filterList = (
        <div> 
            <Divider orientation="left" style={DividerStyle}>
                <Typography.Title level={5}>Date Selection</Typography.Title>
            </Divider>
            <Row gutter={[2, 16]}>
                <FSNDateSelectionButtons></FSNDateSelectionButtons>
            </Row>
        </div> 
    )

    const cardContent = (
        <QSMashupObject
            appId={appId}
            mashupId={ids.get('Mosaic_Field Sales_table_Oregon Call Reporting')?.id}
            key={`Mosaic_Field Sales_table_Oregon Call Reporting`}
            contentStyle={{ height: '70vh' }}
            showExports={(true)}
            isTable
        />
    );

    return (
		<Layout hasSider style={{ height: '100%', overflow: 'auto', transform: 'scale(1.0)' }}>
			<Sider
				width={170} collapsible theme="light"
				style={{ height: 'calc(100% - 4rem)', overflow: 'auto', padding: '10px' }}
			>
                {filterList}
			</Sider>
			<Layout style={{ backgroundColor: 'white' }}>
                {cardContent}
			</Layout>
		</Layout>
	);
}

export default OregonDisclosure