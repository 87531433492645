import React, { useEffect, useState, useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import {
	Select,
	Row,
	Dropdown,
	Button,
	Menu,
	Typography,
	Space,
	Divider,
	Layout,
	Result
} from 'antd';
import {
	DownOutlined,
	CheckCircleFilled,
	CloseSquareOutlined,
	DownloadOutlined,
} from '@ant-design/icons';
import { Loader } from 'libs/ui/src/lib/Other/loader/loader';
import pptxgen from "pptxgenjs";
import DateSelectionButtons from '../Groups/DateSelectionButtons';
import { QSMashupObject } from '@trinity-incyte/ui';
import { useQSListbox, useQSGeneric, useNon360ClearProfSelection } from '@trinity-incyte/hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	closeModalSelector,
	openModalSelector,
	QlikUser as QlikUserAtom,
	QSAppMetadataFamily,
	QlikLastModified as QlikLastModifiedAtom,
	regionSelectionCount as regionSelectionCountAtom,
	activeTeam as activeTeamAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import Flavor from 'apps/mosaic/src/assets/Powerpoint/Incyte_Flavor.png';
import logo from 'apps/mosaic/src/assets/Powerpoint/Incyte_Logo.png';
import whiteRectangle from 'apps/mosaic/src/assets/Powerpoint/white_rectangle.png';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

const { Sider } = Layout;
declare const Mosaic: MosaicGlobal;
declare var window: any;

const DividerStyle = {
	marginBottom: "0px",
	marginTop: "18px",
}

const limitedDateFilterItems = ['Percent Followed Up by Geography', 'Follow Up By Channel by Geography', 'Received vs Followed Up by Channel'];

const size = (window.innerHeight < 800) ? 'small' : 'middle';

const AlertRecordTypeFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);
	const [defaultFilterSetting, set_defaultFilterSetting] = useState(0);

	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'RecordType',
	});

	const filterProps = {
		style: { width: '100%', margin: '0px', color: 'rgb(0,0,0,0.85)' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) {
				return curr.qElemNumber;
			} else {
				return acc;
			}
		}, null),
		options: listbox.map((val) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qElemNumber === newValue) {
					if (listbox[0]) {
						listbox[0].clear();
					}
					curr.select();
					return curr.qElemNumber;
				} else {
					return acc;
				}
			}, defaultFilterSetting);

		},
		placeholder: 'Select Type...',
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label filter-label'>Alert Type</div>
				<div className='filter-reset'>
					<Button type="link" onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Select {...filterProps} />
		</>
	);
	return content;
}

const BrandFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);

	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Brand',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field('FSD.BRAND_NAME').selectValues(newListBoxes);

			}
		},
		placeholder: 'Select Brand...',
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label filter-label'>Brand</div>
				<div className='filter-reset'>
					<Button type="link" onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);
	return content;
};

const RegionFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);

	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Region',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: 'Select Regions...',
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label filter-label'>Region</div>
				<div className='filter-reset'>
					<Button type="link" onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const TerritoryFilter = ({ config, fieldName }) => {
	const Config = useContext(ConfigContext);
	const { rows: listbox } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Territory',
	});

	const filterProps = {
		mode: 'multiple' as const,
		style: { width: '100%', margin: '4px' },
		value: listbox.reduce((acc, curr) => {
			if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
			return acc;
		}, []),
		options: listbox.map((val, ind) => ({
			label: val.qText,
			value: val.qElemNumber,
			disabled: (val.qState === Config.Qlik.qStateValues.EXCLUDED),
		})).filter((option) => { return option.disabled === false }),
		onChange: (newValue: number[]) => {
			const currentSelectedElems = listbox.reduce((acc, curr) => {
				if (curr.qState === Config.Qlik.qStateValues.SELECTED) acc.push(curr.qElemNumber);
				return acc;
			}, []);

			if (currentSelectedElems.length > newValue.length) { // Deselect
				const changedCellElem = currentSelectedElems.filter((curr) => (newValue.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				const cell = listbox.reduce((acc, curr) => {
					if (curr.qElemNumber === changedCellElem[0]) acc = curr;
					return acc;
				}, null);
				cell.unselect();
			} else if (currentSelectedElems.length < newValue.length) { // Select
				const changedCellElem = newValue.filter((curr) => (currentSelectedElems.indexOf(curr) === -1));
				if (!changedCellElem) {
					listbox[0].clear();
					return;
				}

				let newListBoxes = []; // array for qlik
				listbox.forEach((listBoxItem) => {
					if ((listBoxItem.qElemNumber === changedCellElem[0]) || (listBoxItem.qState == Config.Qlik.qStateValues.SELECTED)) {
						newListBoxes.push({ qText: listBoxItem.qText });
					}
				});
				const appId = config.ids.appId;
				Mosaic.Qlik.app[appId].field(fieldName).selectValues(newListBoxes);
			}
		},
		placeholder: 'Select Territories...',
		maxTagCount: 'responsive' as const,
	};

	const content = (
		<>
			<div className="filter-label-container">
				<div className='filter-label'>Territory</div>
				<div className='filter-reset'>
					<Button type="link" onClick={() => { listbox[0].clear() }}>Reset</Button>
				</div>
			</div>
			<Row gutter={[2, 16]}>
				<Select {...filterProps} />
			</Row>
		</>
	);

	return content;
};

const AlertsFollowUp = (props) => {
	const { config } = props;
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const regionSelectionCount = useRecoilValue(regionSelectionCountAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [alertsFollowUpTables, set_alertsFollowUpTables] = useState([]);
	const openModal = useSetRecoilState(openModalSelector);
	const closeModal = useSetRecoilState(closeModalSelector);
	const [showFilters, set_showFilters] = useState<boolean>(true);
	const defaultActiveChart = 0;
	const [activeChart, set_activeChart] = useState<number>(defaultActiveChart);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const transitions = useTransition(showFilters, null, {
		from: { position: 'relative', opacity: 0.5 },
		enter: { opacity: 1 },
		leave: { opacity: 0.5 },
	});
	const [defaultDateSelections, set_defaultDateSelections] = useState([
		{ fieldName: fields.get('Mosaic_Field Sales_Time Selection')?.id, qSelected: 'Week' },
		{ fieldName: fields.get('Mosaic_Field Sales_Alert Source Record Desc')?.id, qSelected: 'Clinical' },
		{ fieldName: fields.get('Mosaic_Field Sales_Time Selection')?.id, qSelected: [-1] },
	]);

	useNon360ClearProfSelection({
		selection: defaultDateSelections
	});

	const fieldNameGeneric = useQSGeneric({
		definition: {
			region: {
				qStringExpression:
					`=IF(FSD.TeamID=11, 'DSF.TM11.DIST_NAME_WITH_MGR'
				, IF(FSD.TeamID=12, 'DSF.TM12.DIST_NAME_WITH_MGR'
				, IF(FSD.TeamID=21, 'DSF.TM21.DIST_NAME_WITH_MGR'
				)))`
			},
			territory: {
				qStringExpression:
					`=IF(FSD.TeamID=11, 'DSF.TM11.TERR_NAME_WITH_REP'
				, IF(FSD.TeamID=12, 'DSF.TM12.TERR_NAME_WITH_REP'
				, IF(FSD.TeamID=21, 'DSF.TM21.TERR_NAME_WITH_REP'
				)))`
			},
		},
		app
	});

	const [fieldNameRegion, set_fieldNameRegion] = useState(null);
	const [fieldNameTerritory, set_fieldNameTerritory] = useState(null);
	const checkAccessToNation = () => {
		const {
			REGIONANALYSIS: { showNation } = {
				showNation: 'N',
			},
		} = QlikUser?.features || {};
		return (showNation === 'Y');
	};
	const checkAccessToRegion = () => {
		const {
			REGIONANALYSIS: { showRegion } = {
				showRegion: 'N',
			},
		} = QlikUser?.features || {};
		return (showRegion === 'Y');
	};
	const [isOneRegion, set_isOneRegion] = useState<boolean>(!checkAccessToNation());

	let latest13Weeks = fields.get('Mosaic_Field Sales_Latest 13 Weeks')?.id;
	let latest26Weeks = fields.get('Mosaic_Field Sales_Latest 26 Weeks')?.id;
	let latest52Weeks = fields.get('Mosaic_Field Sales_Latest 52 Weeks')?.id;
	let actualCurrentQuarter = fields.get('Mosaic_Field Sales_CQT')?.id;
	let actualCurrentMonthToDate = fields.get('Mosaic_Field Sales_CMTD')?.id;
	let actualCurrentYearToDate = fields.get('Mosaic_Field Sales_YTD')?.id;
	let actualCurrentQuarterToDate = fields.get('Mosaic_Field Sales_QTD')?.id;
	let retailIntLast12Mths = fields.get('Mosaic_Field Sales_RetailInt Last 12 Months')?.id;
	let retailIntLast12Qtr = fields.get('Mosaic_Field Sales_RetailInt Last 12 Qtr')?.id;
	let retailInt13WTD = fields.get('Mosaic_Field Sales_RetailInt 13 WTD')?.id;
	let retailInt26WTD = fields.get('Mosaic_Field Sales_RetailInt 26 WTD')?.id;
	let retailInt52WTD = fields.get('Mosaic_Field Sales_RetailInt 52 WTD')?.id;
	let retailIntIsQTD = fields.get('Mosaic_Field Sales_RetailInt QTD')?.id;
	let retailIntIsYTD = fields.get('Mosaic_Field Sales_RetailInt YTD')?.id;
	let retailProIsQTD = fields.get('Mosaic_Field Sales_RetailPro QTD')?.id;
	let retailProIsYTD = fields.get('Mosaic_Field Sales_RetailPro YTD')?.id;
	let retailPro13WTD = fields.get('Mosaic_Field Sales_RetailPro 13 WTD')?.id;
	let retailPro26WTD = fields.get('Mosaic_Field Sales_RetailPro 26 WTD')?.id;
	let retailPro52WTD = fields.get('Mosaic_Field Sales_RetailPro 52 WTD')?.id;
	let retailProLast12Mths = fields.get('Mosaic_Field Sales_RetailPro Last 12 Months')?.id;
	let retailProLast12Qtr = fields.get('Mosaic_Field Sales_RetailPro Last 12 Qtr')?.id;

	const fieldsToBeCleared = [
		fields.get('Mosaic_Field Sales_RetailPro Latest 52 Weeks')?.id,
		fields.get('Mosaic_Field Sales_RetailPro Last 3 Years')?.id,
		fields.get('Mosaic_Field Sales_RetailInt Latest 52 Weeks')?.id,
		fields.get('Mosaic_Field Sales_RetailInt Last 3 Years')?.id,
		latest13Weeks,
		latest26Weeks,
		latest52Weeks,
		retailProIsQTD,
		retailProIsYTD,
		retailPro13WTD,
		retailPro26WTD,
		retailPro52WTD,
		retailProLast12Mths,
		retailProLast12Qtr,
		retailIntLast12Mths,
		retailIntLast12Qtr,
		retailInt13WTD,
		retailInt26WTD,
		retailInt52WTD,
		retailIntIsQTD,
		retailIntIsYTD,
		actualCurrentQuarter,
		actualCurrentMonthToDate,
		actualCurrentYearToDate,
		actualCurrentQuarterToDate,
	];

	useEffect(() => {
		if (!checkAccessToNation()) return;

		const varName = 'isOneRegion';
		const getVariable = () => {
			app.variable.getByName(varName).then(function (model) {
				model.getLayout().then(layout => {
					set_isOneRegion((layout.qText) || (regionSelectionCount === 1) ? true : false);
				})
			},
				function (errorObject) {
					if (errorObject?.error?.code === 2) {
						app.variable
							.createSessionVariable({ qName: varName, qDefinition: `=ONLY([${fields.get('Mosaic_Field Sales_Region HO1')?.id}])` })
							.then(() => {
								getVariable();
							});
					}
				});
		};

		getVariable();
	}, [QlikLastModified, regionSelectionCount]);

	useEffect(() => {
		const locationField = app.field('LocationSelectionDesc');
		if (isOneRegion) {
			locationField.selectValues(['Territory']);
		} else {
			locationField.selectValues(['Region']);
		}
	}, [isOneRegion, activeTeam]);

	useEffect(() => {
		if (!fieldNameGeneric) return;
		set_fieldNameRegion(fieldNameGeneric.region);
		set_fieldNameTerritory(fieldNameGeneric.territory);
	}, [JSON.stringify(fieldNameGeneric)]);

	let tables;

	useEffect(() => {
		tables = [
			{ ...ids.get('Mosaic_Alerts Follow Up_barchart_Percent Followed Up') },
			{ ...ids.get('Mosaic_Alerts Follow Up_barchart_Followed Up by Channel') },
			{ ...ids.get('Mosaic_Alerts Follow Up_piechart_Percent Followed Up by Geography') },
			{ ...ids.get('Mosaic_Alerts Follow Up_barchart_Follow Up By Channel by Geography') },
			{ ...ids.get('Mosaic_Alerts Follow Up_barchart_Received vs Followed Up by Channel') },
		].map(({ id, description }, index) => ({
			id,
			key: index.toString(),
			label: description,
		}));

		if (activeChart > tables.length) {
			set_activeChart(0); // reset the menu to the first item if the list changes
		}

		// show a smaller set of tables to reps/non managers
		let filteredTables = tables.filter((table) => {
			return !(limitedDateFilterItems.includes(table.label));
		});

		if (checkAccessToNation() || checkAccessToRegion()) {
			set_alertsFollowUpTables(tables);
		} else {
			set_alertsFollowUpTables(filteredTables);
		}

	}, [activeTeam]);

	const filterFields = {
		region: fieldNameRegion,
		territory: fieldNameTerritory,
		alertRecordType: `=[${fields.get('Mosaic_Field Sales_Alert Source Record Desc')?.id}]`, // AFU.SOURCE_RECORD_DESC
		brandType: `${fields.get('Mosaic_Field Sales_Team Brand Names')?.id}` // FSD.BRAND_NAME
	};

	function handleChange(value) {
		let newActiveChart = parseInt(value.key, 10);
		// clear existing date fields
		for (let ij = 0; ij < fieldsToBeCleared.length; ij += 1) {
			const fieldName = fieldsToBeCleared[ij];
			Mosaic.Qlik.app[appId].field(fieldName).clear();
		}
		let field;
		if (limitedDateFilterItems.includes(alertsFollowUpTables[newActiveChart]?.label)) {
			field = app.field(fields.get('Mosaic_Field Sales_YTD')?.id);
		} else {
			field = app.field(fields.get('Mosaic_Field Sales_Latest 13 Weeks')?.id);
		}
		field.clear().then(() => {
			field.selectValues([-1]);
		});
		set_activeChart(newActiveChart);
	}

	interface SlidesProps {
		id: string;
		label?: string;
	}

	const onClickExportPPT = () => {
		window.analytics?.track('Button Clicked', { text: 'Download All', context: 'Download all in Field Sales Alerts Follow Up' });
		let downloadButton;
		let newDate = new Date;
		const slides: SlidesProps[] = [];

		for (let ii = 0; ii < alertsFollowUpTables.length; ii += 1) {
			if (Array.isArray(alertsFollowUpTables[ii].id)) {
				for (let ij = 0; ij < alertsFollowUpTables[ii].id.length; ij += 1) {
					slides.push({
						id: alertsFollowUpTables[ii].id[ij],
						label: `${alertsFollowUpTables[ii].label} - ${ij + 1}`,
					});
				}
			} else {
				slides.push({
					id: alertsFollowUpTables[ii].id,
					label: alertsFollowUpTables[ii].label
				})
			}
		}

		const getTheObjects = new Promise((resolve, reject) => {
			const vizPromiseArray = slides.map(({ id }) => {
				return app.visualization.get(id);
			});

			Promise.allSettled(vizPromiseArray)
				.then(results => {
					resolve(results);
				})
				.catch((err) => reject(err))
				.finally(() => {
					console.log('getTheObjects.finally');
				})
		});

		getTheObjects
			.then((results: any[]) => {
				openModal({
					content:
						<div>
							<Result
								title="Exporting Data"
								subTitle="Data is being exported. Please wait."
								extra={
									<div>
										<div style={{ height: "10%", marginBottom: "1em" }}>
											<Loader size="small" />
										</div>
										<br />
										<Button icon={<CloseSquareOutlined />} onClick={closeModal} className='close-button'>
											Close
										</Button>
									</div>
								}
							>
							</Result>
						</div>,
					size: 'small',
				});
				let numComplete = 0;
				const exportUrlArray = results.map((viz) => {
					return new Promise((resolve, reject) => {
						let isCompleted = false;

						const getImageData = (url, qViz) => {
							fetch(url, { credentials: 'include' })
								.then(response => response.blob())
								.then(function (blob) {
									const reader = new FileReader();
									reader.onload = function () {
										numComplete += 1;

										resolve({
											title: qViz?.model?.layout?.title,
											type: qViz?.model?.layout?.visualization,
											imgData: this.result.toString(),
										});
									};
									reader.readAsDataURL(blob);
								});
						};

						let imgSettings;
						if (viz.value?.model?.enigmaModel?.layout?.visualization === "piechart") {
							imgSettings = { format: 'png', height: '768', width: '1024' };
						} else {
							imgSettings = { format: 'png', height: '750', width: '1250' };
						}

						// TODO: write a better retry logic
						viz.value.exportImg(imgSettings)
							.then((url) => { getImageData(url, viz.value) })
							.catch((err) => { console.warn(err); reject(err); })
							.finally(() => { isCompleted = true });

						setTimeout(() => {
							if (!isCompleted) {
								viz.value.exportImg(imgSettings)
									.then((url) => { getImageData(url, viz.value) })
									.catch((err) => { console.warn(err); reject(err); })
									.finally(() => { isCompleted = true, console.log('got all exportImg') });
								setTimeout(() => {
									if (!isCompleted) {
										reject('Timed OUT');
									}
								}, 30000);
							}
						}, 30000);
					});
				});

				Promise.allSettled(exportUrlArray)
					.then((a: any[]) => {
						// Set up the initital PPT
						let pptx = new pptxgen();
						pptx.defineSlideMaster({
							title: "ParentSlide",
							objects: [
								{ image: { x: 8.1, y: 0.15, h: "50%", w: "17.5%", path: Flavor } },
								{ image: { x: 0.5, y: "90%", h: "6%", w: "5%", path: logo } }
							],
						});

						for (let ii = 0; ii < a.length; ii += 1) {
							if (!a[ii].value.title) continue;
							const {
								title,
								type,
								imgData,
							} = a[ii].value;
							let slide = pptx.addSlide({ masterName: "ParentSlide" });

							if (type === "piechart") {
								slide.addImage({ data: imgData, x: '12.5%', y: '7%', h: '90%', w: '67.5%' });
								slide.addImage({ path: whiteRectangle, x: '12.5%', y: '7%', h: '3%', w: '30%' })
							} else {
								slide.addImage({ data: imgData, x: '6%', y: '8%', h: '80%', w: '76%' });
								slide.addImage({ path: whiteRectangle, x: '6%', y: '8%', h: '3%', w: '30%' })
							}

							slide.addText(title, {
								x: '4.5%',
								y: 0.2,
								color: "005CAB",
								fontFace: "Tahoma (Headings)",
								fontSize: 26,
								underline: { style: 'sng' },
								align: 'left'
							});
						}
						downloadButton = (
							<Button
								className='download-button'
								onClick={(e) => {
									pptx.writeFile({ fileName: `RegionAnalysisReport - ${newDate.toISOString().substring(0, 10)}.pptx` });
									closeModal(e);
								}}
							>
								<DownloadOutlined />
								Download
							</Button>
						);
						openModal({
							content:
								<div>
									<Result
										status="success"
										title="Download Data"
										subTitle="Data is ready for download."
										extra={[
											<Space size="middle">
												{downloadButton}
												<Button onClick={closeModal} className='close-button'>
													<CloseSquareOutlined />
													Close
												</Button>
											</Space>
										]}
									>
									</Result>
								</div>,
							size: 'small',
						});
					})
					.catch(err => console.warn(err))
					.finally(() => {
						console.log('exportUrls allSettled .finally');
					});
			})
			.catch(err => console.warn(err));
	};

	const menu = (
		<Menu onClick={handleChange}>
			{alertsFollowUpTables.map(({ label }, ind) => (
				<Menu.Item key={ind} data-label={label}>
					{(ind === activeChart) && (
						<CheckCircleFilled style={{ color: 'var(--brand)', marginRight: '4px' }} />
					)}
					{label}
				</Menu.Item>
			))}
		</Menu>
	);

	let cardContent;

	if (alertsFollowUpTables.length > 0) {
		cardContent = (
			<QSMashupObject
				appId={appId}
				mashupId={alertsFollowUpTables[activeChart]?.id}
				key={`comparison_chart_${activeChart}`}
				contentStyle={{ height: '70vh' }}
				exportAsPPT={(true)}
				showExports={(
					alertsFollowUpTables[activeChart].label === 'Alerts Pct Followed Up' ||
					alertsFollowUpTables[activeChart].label === 'Alerts Followed Up by Channel' ||
					alertsFollowUpTables[activeChart].label === 'Alerts Pct Followed Up by Geography' ||
					alertsFollowUpTables[activeChart].label === 'Alerts Followed Up by Channel by Geography' ||
					alertsFollowUpTables[activeChart].label === 'Total Alerts Followed Up by Channel'
				)}
				exportAsImg={(true)}
			/>
		);
	}

	const filtersList = (
		transitions.map(({ item, key, props }) => (
			item && (
				<animated.div key={key} style={props}>
					<div style={{ maxWidth: '98%' }}>
						<div style={{ minWidth: '100%', textAlign: 'right' }}>
							<Button type="text" onClick={onClickExportPPT}>Download All</Button>
						</div>

						<AlertRecordTypeFilter config={config} fieldName={filterFields.alertRecordType} />

						<BrandFilter config={config} fieldName={filterFields.brandType} />

						<div className="filter-label-container" style={{ margin: '0px 0px 4px 0px' }}>
							<div className='filter-label filter-label-noreset'>Chart</div>
						</div>
						<Row gutter={[2, 16]}>
							<Space size="small" direction="vertical" style={{ width: '98%', marginLeft: '2px' }}>
								<Dropdown
									trigger={['click']}
									overlay={menu}
									placement="bottomRight"
								>
									<Button
										style={{ width: '100%' }}
										size={size}
									>
										<Typography.Text
											style={{
												width: '100%',
												fontSize: '1.1rem',
												textAlign: 'left',
												fontWeight: 'normal',
												textDecorationColor: 'var(--brand)'
											}}
											strong
										>
											{alertsFollowUpTables.length > 0 && alertsFollowUpTables[activeChart].label}
											<DownOutlined style={{
												float: 'right',
												marginTop: '2px',
												marginLeft: '0px',
												color: 'var(--brand)',
											}} />
										</Typography.Text>
									</Button>
								</Dropdown>
							</Space>
						</Row>
						{checkAccessToNation() && (
							<RegionFilter config={config} fieldName={filterFields.region} />
						)}
						{(checkAccessToNation() || checkAccessToRegion()) && (
							<TerritoryFilter config={config} fieldName={filterFields.territory} />
						)}
						<div className="filter-label-container" style={{ margin: '0px 0px 4px 0px' }}>
							<div className='filter-label filter-label-noreset'>Date</div>
						</div>
						<Row gutter={[2, 16]}>
							<DateSelectionButtons defaultSelection="YTD - Week" layout={(alertsFollowUpTables[activeChart]?.label && limitedDateFilterItems.includes(alertsFollowUpTables[activeChart]?.label)) ? 'ALERTSFOLLOWUPLIMITED' : 'ALERTSFOLLOWUP'} config={config} size={size} />
						</Row>
					</div>
				</animated.div>
			)))
	);

	return (
		<Layout hasSider style={{ height: '100%', overflow: 'auto', transform: 'scale(1.0)' }}>
			<Sider
				width={310} collapsible theme="light"
				style={{ height: 'calc(100% - 4rem)', overflow: 'auto', padding: '10px' }}
			>
				{filtersList}
			</Sider>
			<Layout style={{ backgroundColor: 'white' }}>
				{cardContent}
			</Layout>
		</Layout>
	);
}

export default AlertsFollowUp;
