import React, { Suspense, lazy, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { 
	DisclaimerGroup, 
	ModalGroup, 
	LocationKeeper, 
	SideNavGroup, 
	ProfWorkspaceGroup,
	Loader,
	SubPage,
	TrainerSelect
 } from '@trinity-incyte/ui';
import ActionCenterCalendar from '../Pages/ActionCenter/Calendar';
import ActionCenter from '../Pages/ActionCenter/index';
import EventsCalendar from '../Pages/Events/Calendar';
import Events from '../Pages/Events/index';
import LandingPage from '../Pages/Index/index';
import ListBuilderIndex from '../Pages/ListBuilder/index';
import ListBuilderSegments from '../Pages/ListBuilder/Segments';
import { sideNavIsExpandedState, isMasqueradeActive as isMasqueradeActiveAtom,  QlikUser as QlikUserAtom } from '@trinity-incyte/recoil';
import { Layout } from 'antd';
import HCP360Home from '../Pages/HCP360/HCP360Home';
import HCO360Home from '../Pages/HCO360/HCO360Home';
import ChatBot from '../Pages/ChatBot/ChatBot';
import FieldSalesHome from '../Pages/FieldSales/FieldSalesHome';
import { hasFeature } from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';

/*
 * REMEMBER: Switch goes first-match, so go most complex 1st to least complex
 */

const AppRouterSwitch = ({ Config, QlikUser, isMasqueradeActive }) => (
	<Switch>
		{/*
			* This section is all specific routes that need params
			* There's got to be a better way to do this, right?
			*/}
		<Route
			path="/Trainers" 
            render={(props) => {
				return (<TrainerSelect selectorUse={"trainers"} />);
			}}
		/>
		{(QlikUser?.isTrainer) && (!isMasqueradeActive) && (
			<Route>
				<Redirect to="/Trainers" />
			</Route>
		)}
		<Route path="/HCP360/*/:profId" render={(props) => {
                const profId = props.match.params.profId;
                return ( <HCP360Home profId={profId} /> ); 
			}}
		/>
		{hasFeature(Config.App.features, "chatbot") && (
		<Route
			path="/chatbot" 
            render={() => {
				return <ChatBot />
			}}
		/>
		)}

		<Route path="/HCP360/" component={HCP360Home} />
		{QlikUser.showHCO360 && hasFeature(Config.App.features, "HCO360") && (
			<Route path="/HCO360/" component={HCO360Home} />
		)}

		<Route path="/ListBuilder" component={ListBuilderIndex} />
		<Route path="/ListBuilder/Segments" component={ListBuilderSegments} />
		{/* If no 360-only route matches, will redirect back to 360 search */}
		{(QlikUser?.features?.APP?.is360Only === 'Y') && (
			<Route>
				<Redirect to="/HCP360" />
			</Route>
		)}
		<Route path="/ListBuilder/Segments/:segmentName" render={(props) => {
			const { segmentName } = props.match.params;
			return ( <ListBuilderSegments segmentName={segmentName} /> );
		}}
		/>
		
		<Route path="/FieldSales/" component={FieldSalesHome} />
		<Route path="/OCNEReports/" component={FieldSalesHome} />

		<Route path="/Events/Calendar" component={EventsCalendar} />
		<Route path="/Events/:param1" render={(props) => {
			const { param1 } = props.match.params;
			return ( <Events activeTab={param1} /> );
		}}
		/>
		<Route path="/Events" component={Events} />
		<Route path="/ActionCenter/Calendar" component={ActionCenterCalendar} />
		<Route path="/ActionCenter/:actionId" render={(props) => {
			const { actionId } = props.match.params;
			return ( <ActionCenter actionId={actionId} /> );
		}}
		/>
		<Route path="/ActionCenter" component={ActionCenter} />
		<Route path="/">
			<LandingPage />
		</Route>
		<Route>
			<Redirect to="/" />
		</Route>
	</Switch>
);

const AppRouter = (props) => {
	const Config = useContext(ConfigContext);
	const sideNavIsExpanded = useRecoilValue(sideNavIsExpandedState);
	const QlikUser = useRecoilValue(QlikUserAtom);

 	const isMasqueradeActive = useRecoilValue(isMasqueradeActiveAtom);
	return (
		<Layout>
			<SideNavGroup />
			<div className={`page transitionPoint3 ${sideNavIsExpanded ? 'navOpen' : 'navClosed'}`}>
				<Layout style={{ background:'#BFD9F1', height: '100%', width:'100%' }}>
					<Suspense
						fallback={(
							<>
								<Loader size="large"></Loader>
								<div style={{ height: '100%' }} />
							</>
						)}
					>
						<AppRouterSwitch Config={Config} QlikUser={QlikUser} isMasqueradeActive={isMasqueradeActive} />
					</Suspense>
				</Layout>
			</div>
			<DisclaimerGroup /> 
			<SubPage />
			<ModalGroup />
			<ProfWorkspaceGroup />
			<LocationKeeper />
		</Layout>
	);
};

export default AppRouter;
