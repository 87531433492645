import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ConfigContext } from '@trinity-incyte/context';
import React, { useContext, useEffect } from 'react'
import { TabGroup } from "@trinity-incyte/ui"
import { HCO360Context } from '@trinity-incyte/context'
import HCO360OverviewProduct from './HCO360OverviewProduct'
import HCO360OverviewInteractions from './HCO360OverviewInteractions'
declare const Mosaic: MosaicGlobal;

type HCO360OverviewParams = {
    groupId: string
}

export const HCO360Overview = ({ groupId }: HCO360OverviewParams) => {
    const Config = useContext(ConfigContext);
    const hco360Context = useContext(HCO360Context)

    const clearFilters = () => {
        const hcp360App = Mosaic.Qlik.app[Config.Qlik.Prof360.ids.appId];
        if ( hcp360App ) {
            hcp360App.field( '%GP_ID' ).clearOther( true );
        }
    };

    useEffect(() => {
        hco360Context.setActiveView('Overview')
        hco360Context.setBrand(null)
		hco360Context.applyGroupId(groupId)
		hco360Context.setShowGroup(true)
	}, [])

    const tabsByTeam = {
        'HO1': [{name: "Incyte Product Summary", comp: HCO360OverviewProduct}, {name: "Incyte Interactions Summary", comp: HCO360OverviewInteractions}],
        'HO2': [{name: "Incyte Product Summary", comp: HCO360OverviewProduct}, {name: "Incyte Interactions Summary", comp: HCO360OverviewInteractions}],
        'HO3': [{name: "Incyte Product Summary", comp: HCO360OverviewProduct}, {name: "Incyte Interactions Summary", comp: HCO360OverviewInteractions}],
        'OCNE': [{name: "Incyte Product Summary", comp: HCO360OverviewProduct}, {name: "Incyte Interactions Summary", comp: HCO360OverviewInteractions}]
    }
    return (
        <TabGroup tabsByTeam={tabsByTeam} onTabChange={clearFilters} />
    )
}
