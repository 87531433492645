/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React from 'react';
import { Card, Space, Divider } from 'antd';
import { HCP360Cell as MosaicCellAddon, ProfCell as ProfCellAddon, QSMashupObject } from '@trinity-incyte/ui';
import PerformanceContainer from '../Performance/Container';

import Config from '../../Config';
import RegionAnalysis from '../Other/RegionAnalysis';
import AlertsFollowUp from '../Other/AlertsFollowUp';
import OregonDisclosure from '../Other/OregonDisclosure';
import BarriersAnalysis from '../Other/BarriersAnalysis';

const config = Config.Qlik.FieldSales;
const { appId, items } = config.ids;
const FieldSales = {
	'details_direct_sales_table': (
		<QSMashupObject
			appId={appId}
			mashupId={items['Direct Sales Table']}
			elementId="details_table2"
			contentStyle={{ height: '62vh' }}
		/>
	),
	'details_new_patients_table': <QSMashupObject
		isTable
		tableProps={{}}
		appId={appId}
		mashupId={items['New Patients Table']}
		elementId="details_table3"
		contentStyle={{ height: '62vh' }}
	/>,
	'details_new_prescribers_table': (
		<QSMashupObject
			isTable
			tableProps={{}}
			appId={appId}
			mashupId={items['New Prescribers Table']}
			elementId="details_table4"
			contentStyle={{ height: '62vh' }}
		/>
	),
	'details_new_enrollments_table': <QSMashupObject isTable tableProps={{}} appId={appId} mashupId={items['Direct Sales Table']} elementId="details_table5" />,
	'landing_directsales_trend': <QSMashupObject isTable tableProps={{}} appId={appId} mashupId={items['New SP Patients']} elementId="qs_203" />,
	'Prof Last Called Date KPI': <QSMashupObject appId={appId} mashupId={items['Prof Last Call Date KPI']} elementId="qs_6" />,
	'Alerts Table': <QSMashupObject appId={appId} mashupId={items['Alerts Table']} elementId="qs_173" />,
	'/FieldSales - Table Container': {
		parentClass: 'no-padding no-border',
		component: ({activeBrand}) => (
			<PerformanceContainer
				layout={activeBrand}
			/>
		),
	},
	'/FieldSales - RegionAnalysis': {
		parentClass: ['no-padding', 'no-border'],
		component: ({chart}) => ( <RegionAnalysis config={config} chart={chart} /> ),
	},
	'/FieldSales - BarriersAnalysis': {
		parentClass: ['no-padding', 'no-border'],
		component: ({chart}) => ( <BarriersAnalysis config={config} chart={chart} /> ),
	},
	'/FieldSales - AlertsFollowUp': {
		parentClass: ['no-padding', 'no-border'],
		component: ({chart}) => ( <AlertsFollowUp config={config} chart={chart} /> ),
	},
	'/FieldSales - OregonDisclosure': {
		parentClass: ['no-padding', 'no-border'],
		component: ({chart}) => ( <OregonDisclosure /> ),
	},
	'performance_table_container_monjuvi': {
		parentClass: ['no-padding', 'no-border'],
		content: (
			<PerformanceContainer layout="MONJUVI" />
		),
	},
	'performance_table_container_pemi': {
		parentClass: ['no-padding', 'no-border'],
		content: (
			<PerformanceContainer layout="PEMAZYRE" />
		),
	},
	// 'performance_charts_list2': {
	// 	component: (props) => (
	// 		<Space split={<Divider type="vertical" />} style={{ height: '100%', overflowY: 'auto', paddingTop: '4px' }}>
	// 			<Card className="trends-chart-item">
	// 				<QSMashupObject
	// 					segmentColor="teal"
	// 					addon={(<div><div onClick={props.onClick} onKeyUp={props.onClick} data-chart-id="Calls Chart" style={{ position: 'absolute', zIndex: 1, height: '100%', width: '100%', opacity: 0, bottom: '0px', marginLeft: '-1rem' }} /></div>)}
	// 					contentStyle={{ height: '100%', width: '100%', padding: '2px' }}
	// 					appId={appId}
	// 					mashupId={items['Calls Chart']}
	// 					elementId="performance_chart11"
	// 				/>
	// 			</Card>
	// 		</Space>
	// 	),
	// 	content: (
	// 		<span>null</span>
	// 	),
	// }
};

export default FieldSales;
