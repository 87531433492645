import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { useMashup } from '@trinity-incyte/hooks';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';

declare const Mosaic: MosaicGlobal;

export const QSKPITile = ({appId, mashupId, customStyle={}, customValueStyle={}, customLabelStyle={}, customClass='qsTableTile', to=null}) => {
	const history = useHistory();
    const style: CSSProperties = {...customStyle};
    let onClick = () => {};
    if (to != null) {
        style.cursor = 'pointer';
        onClick = () => {
            history.push(to);
        };
    }

    const mashupData = useMashup(appId, mashupId);

    let dataObj = mashupData?.table?.rows[0]?.cells[0];
    const dataMeasureValue = dataObj?.qText ?? "-";
    const dataMeasureTitle = dataObj?.qMeasureInfo?.qFallbackTitle ?? ``;
    return (
        <div className={customClass} style={style} onClick={onClick}>
            <div className='qsTableTileLabel' style={customLabelStyle}>{dataMeasureTitle}</div>
            <div className='qsTableTileValue' style={customValueStyle}>{dataMeasureValue}</div>
        </div>
    )
}

export default QSKPITile;