import React from 'react';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, LeftSlider } from '@trinity-incyte/ui';

const OCNEOverview = (props) => {
	const { profId } = props;
	const urlSuffix = profId ? `/${profId}` : '';
	const isProfSelected = use360ProfSelection(profId);

	let defaultLayout;
	if (profId === false) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'green',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId === '-') {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== false && !isProfSelected) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Loader',
					props: {
						color: 'blue',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== '-' && profId !== false && isProfSelected) {
        defaultLayout = {
            responsive: [
                {
                    w: 24,
                    items: [
                        {
                            items: [
                                {
                                    w: 5,
                                    i: 'Bottles KPI Box - Jakafi',
                                },
                                {
                                    w: 5,
                                    i: 'SP Patients KPI Box',
                                },
                                {
                                    w: 5,
                                    i: 'Direct Sales KPI Box',
                                },
                                {
                                    w: 9,
                                    i: 'Face To Face KPI Box',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 5,
                                    wrapped: {maxHeight: '100%'},
                                    items: [
                                        {
                                            w: 24,
                                            style: {marginBottom: '6px'},
                                            i: 'Treatment KPI Box',
                                        },
                                        {
                                            w: 24,
                                            i: 'Trial Card / IncyteCares KPI Box'
                                        },
                                    ]
                                },
                                {
                                    w: 5,
                                    wrapped: {minHeight: '100%', maxHeight: '100%'},
                                    items: [
                                        {
                                            style: {marginBottom: '6px'},
                                            w: 24,
                                            i: 'Connections KPI Box',
                                            props: { to: `/HCP360/Connections${urlSuffix}` },
                                        },
                                        {
                                            w: 24,
                                            i: 'Textbooks Box'
                                        },
                                    ],
                                },
                                {
                                    w: 5,
                                    i: 'Diagnosis Claims Quintile KPI Box',
                                },
                                {
                                    w: 9,
                                    i: 'Peer Interaction KPI Box',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 5,
                                    i: 'Monjuvi Landscape KPI Box'
                                },
                                {
                                    w: 10,
                                    style: {padding: '0px 4px'},
                                    i: 'Pemazyre Landscape KPI Box',
                                },
                                {
                                    w: 9,
                                    wrapped: {height: '100%'},
                                    items: [
                                        {
                                            w: 24,
                                            style: {padding: '0 0 4px'},
                                            i: 'Digital Push KPI Box',
                                            props: { profId: profId },
                                        },
                                        {
                                            w: 24,
                                            style: {padding: '4px 0 0'},
                                            i: 'Digital Pull KPI Box',
                                            props: { profId: profId },
                                        }
                                    ],
                                },
                            ]
                        },
                    ]
                }
            ],
        };
	} else {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	}

	return (
		<>
			<Grid
				title="Mosaic Overview"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default OCNEOverview;
