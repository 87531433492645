import ReactResizeObserver from 'rc-resize-observer';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Typography } from 'antd'; 
import { useBackTop } from '@trinity-incyte/ui';
import Alert from './Alert';
import { useRecoilValue } from 'recoil';
import { dismissedAlerts as dismissedAlertsAtom } from '@trinity-incyte/recoil';
import './index.scss'

type AlertsListProps = {
  items: any,
	selectedCompany: any,
	selectedTeam: any,
	selectedType: any,
  selectedGroup?: string,
  selectedIndications?: Array<string>
}

const AlertsList = ({
	items,
	selectedCompany,
	selectedTeam,
	selectedType,
  selectedGroup,
  selectedIndications
}: AlertsListProps) => {
  const dismissedAlerts = useRecoilValue(dismissedAlertsAtom);
	const [rows, setRows] = useState([]);
	const [showDismissedAlerts, set_showDismissedAlerts] = useState(false);
	const [dismissedAlertsDialog, set_dismissedAlertsDialog] = useState(<></>);
  const [set_scroller, onResizer, backTop] = useBackTop();
  const self = useRef<any>();
  const getAlertsKeys = (alertItems:any) => {
    let alertKeys = alertItems?.map(r => r.alertKey) ?? []; 
    return alertKeys;
  }
  
  const isLoading = (items == null);
  const itemAlertIds = useMemo(() => getAlertsKeys(items), [items]); 

  const createToggleAlertDialog = () => {
    if (dismissedAlerts && !isLoading) {
      let dismissedAlertsByType = dismissedAlerts.filter(r => r.AlertType===selectedType && itemAlertIds.includes(r.AlertKey)); 
      let dismissedAlertCount = dismissedAlertsByType.length; 
      if ((dismissedAlerts.length>0) && (dismissedAlertCount>0)) {
        return (
          <div onClick={toggleDismissedAlerts} className="alert-fill" style={{fontSize: '0.9em', textAlign:'left', cursor:'pointer', padding:'4px 8px 2px 8px', width: '98%', color:'#000', margin:'3px 12px 5px 4px', borderRadius: '6px'}}>
          {showDismissedAlerts?`Showing ${dismissedAlertCount} dismissed alerts. `:` `}
          <span style={{textDecoration:'underline'}}>
          {showDismissedAlerts?'Hide':'Show'} {dismissedAlertCount} dismissed alerts
          </span>
          </div>
        )
      } else {
        return (
          <></>
        )
      }
    } else {
     return (
       <></>
     )
    }
 }

  // toggle alert dialog
  useEffect(() => {
    set_dismissedAlertsDialog(createToggleAlertDialog);
  }, [showDismissedAlerts, dismissedAlerts]);

  // load the dismissed alerts
	useEffect(() => {
    if (items == null) {
      setRows([]);
      set_dismissedAlertsDialog(createToggleAlertDialog);
      return;
    }
    // condition to make alerts visible again
    let dismissedAlertsKeys = dismissedAlerts.map(x => x.AlertKey);
    if (showDismissedAlerts) {
      setRows(
        items.filter(
          (val) =>
            (!selectedCompany && !selectedTeam && !selectedType) ||
            (selectedCompany && val.companyName === selectedCompany) ||
            (selectedTeam && val.teamName === selectedTeam) ||
            ((selectedType && val.alertType === selectedType) &&
            (!selectedGroup || (selectedGroup && val.alertGroup === selectedGroup)) &&
            (selectedIndications == null || selectedIndications.length == 0 || selectedIndications.includes(val.alertIndication)))
        )
      );
    } else {
      setRows(
        items.filter(
        (val) =>
          (!(dismissedAlertsKeys.includes(val.alertKey))) && 
          ((!selectedCompany && !selectedTeam && !selectedType) ||
          (selectedCompany && val.companyName === selectedCompany) ||
          (selectedTeam && val.teamName === selectedTeam) ||
          ((selectedType && val.alertType === selectedType) &&
          (!selectedGroup || (selectedGroup && val.alertGroup === selectedGroup)) &&
          (selectedIndications == null || selectedIndications.length == 0 || selectedIndications.includes(val.alertIndication))))
       )
      );
    }
    set_dismissedAlertsDialog(createToggleAlertDialog);
	}, [selectedCompany, selectedTeam, selectedIndications, selectedType, selectedGroup, items, showDismissedAlerts, dismissedAlerts]);

  const toggleDismissedAlerts = () => {
    let newState = !showDismissedAlerts;
    set_showDismissedAlerts(newState);
  }

  let content;

  if ( rows.length > 0 ) {
    // Set loading here
    content = (
      <div style={{
        width: '100%',
        height: '100%',
        display: 'block',
        maxWidth: '100% !important',
      }}>
        {dismissedAlertsDialog}
        <div style={{ height: '100%', margin: '0px', flex: 'auto' }}>
          <Virtuoso
            className='alert-virtuoso'
            style={{
              minHeight: self.current?.offsetHeight,
            }}
            data={rows}
            scrollerRef={set_scroller}
            components={{
              List: React.forwardRef((props, listRef) => (
                <ReactResizeObserver onResize={onResizer}>
                  <div {...props} ref={listRef} />
                </ReactResizeObserver>
              )),
            }}
            itemContent={(index) => (
              <div
                key={`item_${selectedGroup}_${selectedType}_${index}`}
                style={{
                  width: 'calc(100% - 20px)',
                  paddingTop:
                    index === 0 ? '7px' : '0px',
                  paddingBottom:
                    index === rows.length - 1 ? '2px' : '15px',
                  position: 'relative',
                  left: '8px',
                }}
              >
                <Alert {...rows[index]} />
              </div>
            )}
          />
          {backTop}
        </div>
      </div>
    );
  } else {
    content = (
      <div className='no-alerts'>
        <>{dismissedAlertsDialog}</>
        <Typography.Title level={5} style={{ color: "#595959" }}>{isLoading?"Loading...":"No Alerts"}</Typography.Title>
      </div>
    )
  }

  return content;
};

export default AlertsList;
