import React, { useContext, useEffect, useState } from 'react';
import { QSAppMetadataFamily } from "@trinity-incyte/recoil";
import { ConfigContext, HCO360Context } from '@trinity-incyte/context'
import { QSMashupObject } from '@trinity-incyte/ui';
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { useRecoilValue } from 'recoil';
import { Spin } from 'antd';

declare const Mosaic: MosaicGlobal;

const FormatDataCard = ({label, dataItem}) => {
    return (
        <div className="dataCard_split_block">
            <span className="dataCard_label">{label}</span>
            &nbsp;
            <span className="dataCard_data">{dataItem}</span>
        </div>
    )
}

export interface HCO360OrgProps {
	groupId: string;
}

export function HCO360Org(
    {
        groupId,
    }: HCO360OrgProps | any) {
        const hco360Context = useContext(HCO360Context);
        const Config = useContext(ConfigContext);
        const config = Config.Qlik.Prof360;
        const { appId } = config.ids;
        const app = Mosaic.Qlik.app[appId];
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids, FieldsTable: fields } = metadata;
    
        let groupData = hco360Context.state.groupData;

        if (groupData == null) {
            return (
                <div className='hco360-spinner-container'>
                    <Spin size="large"/>
                </div>
            )
        }
        
        return (
        <div className='dataCard_block'>
            <div style={{display:'flex', marginBottom: '8px'}}>
                <div style={{fontWeight:'bold'}}>{hco360Context.state.groupId}</div>
            </div>
            <div style={{textAlign:'center', width:'100%'}}>
            <h3 className="dataCard_name" style={{fontSize:'1.5em', lineHeight:'1em'}}>{groupData.name}</h3>
            <div className="dataCard_label">Corporate Address</div>
            <div className="dataCard_data">{groupData.address!=`-`?groupData.address:``}</div>
            <div className="dataCard_data">{groupData.city!=`-`?groupData.city:``}{groupData.city!='-'||groupData.state!='-'?`,`:``} {groupData.state!=`-`?groupData.state:`-`} {groupData.zip!=`-`?groupData.zip:``}</div>
            </div>

            <div style={{marginTop:'1.6em'}}>
                <FormatDataCard label='CAP Account: ' dataItem={groupData.capFlag} />
                <FormatDataCard label='Account Type: ' dataItem={groupData.accountType} />
                <FormatDataCard label='BMT: ' dataItem={groupData.bmtFlag} />
                <FormatDataCard label='CAR-T: ' dataItem={groupData.cartFlag} />
            </div>

            <div style={{marginTop:'2em'}}></div>

            <div style={{marginTop: '1.4em'}}>
                <div className='dataCard_label' style={{lineHeight:'1em'}}>Targets Associated to Account</div>
                <div className='dataCard_label_notation'>Target count includes overlap between products</div>
                <QSMashupObject
                appId={appId}
                customClass="basicTableThree"
                mashupId={ids.get('Mosaic_HCO 360_Overview_Targets and Key Individuals')?.id}
                isBasicTable
                compactMargin
                compact
                />
            </div>

        </div>    
    )
}