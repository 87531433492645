
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { QSMashupObject, NavLinkWrapper } from '@trinity-incyte/ui';

const Org = {
};

// are we using this? There are no references to this file in the app
// TO DO - delete this file if not in use

export default Org;
