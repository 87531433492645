import React from 'react';

import { RGLGrid as Grid, NavGroupMosaic, SubPage, LeftSlider } from '@trinity-incyte/ui';
import { use360ProfSelection } from '@trinity-incyte/hooks';

const CMS_Spend = (props) => {
    const { profId } = props;
    const isProfSelected = use360ProfSelection(profId);
    const urlSuffix = profId ? `/${profId}` : '';

    let defaultLayout;

    if (profId === false) {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Message Box',
                    props: {
                        color: 'green',
                        text: 'Connections',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId === '-') {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Message Box',
                    props: {
                        color: 'orange',
                        text: 'Please select one HCP to view the overview.',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId !== false && !isProfSelected) {
        defaultLayout = {
            xs: [
                {
                    w: 24,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Loader',
                    props: {
                        color: 'blue',
                        text: 'Loading...',
                    },
                    moved: false,
                    static: true,
                },
            ],
        };
    } else if (profId !== false && isProfSelected) {
        defaultLayout = {
            xs: [
                {
                    w: 5,
                    h: 16,
                    x: 0,
                    y: 0,
                    i: 'Mosaic Overview Prof Box',
                    moved: false,
                    props: {
                        profId,
                        key: `profBox-${profId}`,
                    },
                    static: true,
                },
                {
                    w: 19,
                    h: 8,
                    x: 5,
                    y: 0,
                    i: 'CMS Spend Industry Spend',
                    moved: false,
                    props: {
                        profId,
                        tableProps: {
                            colOverrides: [
                                {
                                    columns: [0],
                                    hStyles: { width: '17rem' },
                                },
                                {
                                    columns: [1],
                                    hStyles: { width: '6rem' },
                                },
                            ],
                        },
                    },
                    static: true,
                },
                {
                    w: 19,
                    h: 8,
                    x: 5,
                    y: 8,
                    i: 'CMS Spend By Year Chart',
                    moved: false,
                    props: {
                        profId,
                        onClick: () => {}
                    },
                    static: true,
                },
            ],
        };
    }

    const content = (
        <>
            <NavGroupMosaic urlSuffix={urlSuffix} />
            <Grid title="CMS Spend" layout={defaultLayout} />
            <LeftSlider />
        </>
    );

    return content;
};

export default CMS_Spend;
