import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductMonjuviInteractions = () => {
    const defaultLayout = {
        responsive: [{
            w: 24,
            items: [{
                items: [{
                    w: 4,
                    i: 'HCO360 Monjuvi Interactions Alerts'
                },
                {
                    w: 20,
                    i: 'HCO360 Monjuvi Interactions Barriers'
                }
                ],
            },
            {
                w: 24,
                i: 'HCO360 Monjuvi Speaker Programs'
            },
            {
                w: 24,
                i: 'HCO360 Monjuvi Interactions'
            }]
        }
    ]}

	return (
		<>
			<Grid
				title="Monjuvi Interactions"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductMonjuviInteractions;
