import React, { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import FieldSalesJakafi from './Jakafi';
import FieldSalesMonjuvi from './Monjuvi';
import FieldSalesNiktimvo from './Niktimvo';
import FieldSalesPemazyre from './Pemazyre';
import FieldSalesZynyz from './Zynyz';
import FieldSalesRegionAnalysis from './RegionAnalysis';
import FieldSalesBarriersAnalysis from './BarriersAnalysis';
import FieldSalesAlertsFollowUp from './AlertsFollowUp';
import FieldSalesOregonDisclosure from './OregonDisclosure';
import { 
    activeTeam as activeTeamAtom,
} from "@trinity-incyte/recoil";
import { useRecoilValue } from "recoil";
import { getPrimaryBrandForTeam, teamHasBrand, teamHasFeature, toTitleCase,  } from "@trinity-incyte/utils";
import { ConfigContext } from "@trinity-incyte/context";
import { FieldSalesPathGenerator } from "libs/utils/src/lib/path-generators";

const getSubpathString = (str) => {
    const secondSlashIndex = str.indexOf('/', str.indexOf('/') + 1);
    const substring = str.substring(secondSlashIndex);
    return substring;
}

export const FieldSalesRouter = () => {
	const Config = useContext(ConfigContext);
    const activeTeam = useRecoilValue(activeTeamAtom);
    const location = useLocation();

    if (!location.pathname.startsWith(FieldSalesPathGenerator(activeTeam, ""))) {
        return (<Redirect to={FieldSalesPathGenerator(activeTeam, getSubpathString(location.pathname)) } />)
    }

    return (
        <Switch>
            <Route path="/:pageName/:subPage/:tab/:subTab" render={(props) => {
                const { subPage, tab, subTab } = props.match.params;

                if ((activeTeam!==null) && (!teamHasBrand(Config.App.teamToBrand, activeTeam, subPage.toUpperCase()))) {
                    const defaultBrandForTeam = getPrimaryBrandForTeam(Config.App.teamToBrand, activeTeam);
                    return (<Redirect to={FieldSalesPathGenerator(activeTeam, `/${toTitleCase(defaultBrandForTeam)}/0/0`)} />)    
                }

                switch ( subPage.toUpperCase() ) {
                    case "JAKAFI":
                        return ( <FieldSalesJakafi /> );
                    case "PEMAZYRE":
                        return ( <FieldSalesPemazyre tab={tab} subTab={subTab} /> );
                    case "MONJUVI":
                        return ( <FieldSalesMonjuvi tab={tab} subTab={subTab} /> );
                    case "NIKTIMVO":
                        return ( <FieldSalesNiktimvo tab={tab} subTab={subTab} /> );
                    case "ZYNYZ":
                        return ( <FieldSalesZynyz tab={tab} subTab={subTab} /> );
                }
            }} />
            <Route path="/:pageName/:subPage/" render={(props) => {
                const { subPage } = props.match.params;
                switch ( subPage.toUpperCase() ) {
                    case "REGIONANALYSIS":
                        return (<FieldSalesRegionAnalysis />)
                    case "BARRIERSANALYSIS":
                        if (teamHasFeature(Config.App.features, "Field Sales Barrier Analysis", activeTeam) || !activeTeam) {
                            return (<FieldSalesBarriersAnalysis />)
                        } else {
                            return (<Redirect to={FieldSalesPathGenerator(activeTeam,`/`)} />)
                        }
                    case "ALERTSFOLLOWUP":
                        return (<FieldSalesAlertsFollowUp />)
                    case "OREGON":
                        return (<FieldSalesOregonDisclosure />)
                    case "JAKAFI":
                        return (<Redirect to={FieldSalesPathGenerator(activeTeam,`/Jakafi/0/0`)} />)
                    case "PEMAZYRE":
                        return (<Redirect to={FieldSalesPathGenerator(activeTeam,`/Pemazyre/0/0`)} />)
                    case "MONJUVI":
                        return (<Redirect to={FieldSalesPathGenerator(activeTeam,`/Monjuvi/0/0`)} />)
                    case "ZYNYZ":
                        return (<Redirect to={FieldSalesPathGenerator(activeTeam,`/Zynyz/0/0`)} />)
                }
            }} />
            <Route path="/:pageName/" render={(props) => {
                const { pageName } = props.match.params;
                return (<Redirect to={`/${pageName}/${toTitleCase(getPrimaryBrandForTeam(Config.App.teamToBrand, activeTeam))}/0/0`} />)
            }} />
        </Switch>
    )
}

export default FieldSalesRouter;