import './dismiss-cell.module.scss';
import { Button, ButtonProps, Popconfirm, Select } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import { useBackend } from '@trinity-incyte/hooks';
import { ConfigContext } from '@trinity-incyte/context';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { dismissedAlerts as dismissedAlertsAtom, dismissedAlertsReasons as dismissedAlertsReasonsAtom } from '@trinity-incyte/recoil';

import {
	QlikUser as QlikUserAtom,
} from '@trinity-incyte/recoil';

declare var window: any;
const BACKEND_CLASS_NAME = 'AlertsDismissals';
const Parse = useBackend();

/* eslint-disable-next-line */
export interface DismissCellProps {
  value?: any;
  column?: any;
	alertType?: any;
	alertParseDbId?: any;
	alertKey?: any;
  size?: ButtonProps['size'];
  fontSize?: any;
  style?: any;
}

export function DismissCell(props: DismissCellProps) {
	let profId;
	const Config = useContext(ConfigContext);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [currentReasonSelected, set_currentReasonSelected] = useState("");
	const [selectOptions, set_selectOptions] = useState([]);
	const dismissedAlerts = useRecoilValue(dismissedAlertsAtom);
	const dismissedAlertsReasons = useRecoilValue(dismissedAlertsReasonsAtom);
	const setDismissedAlerts = useSetRecoilState(dismissedAlertsAtom);

	useEffect(() => {
		const selectOptionList = dismissedAlertsReasons.map((ad) =>
			<Select.Option key={`reason_${ad.id}`} value={ad.id}>{ad.description}</Select.Option>
	 	);
		set_selectOptions(selectOptionList);
	}, [dismissedAlertsReasons]);

	function confirm(e) {

		// Write the dismissal to the parse server
			const LBClass = Parse.Object.extend(BACKEND_CLASS_NAME);
			const lb = new LBClass();
			let dimsissedDate = new Date();
			lb.set('QlikUserId', QlikUser.authenticatedUser);
			lb.set('DismissalReason', currentReasonSelected);
			lb.set('DismissedDate', dimsissedDate);
			lb.set('AlertKey', props.alertKey);
			lb.set('AlertType', props.alertType);
			lb.set('AppName', Config.App.name);
	
			lb.save().then(
				(result) => {
					setDismissedAlerts([
						...dismissedAlerts,
						{
							AlertParseDbId : result.id,
							AlertKey : props.alertKey, 
							AlertType : props.alertType						
						}
					]);	
					window.analytics?.track('Button Clicked', { text: 'Dismissed alert', context: `Home page alert dismissal submitted`, alertType: props.alertType });
				},
				(error) => {
					console.error('Error while creating ParseObject: ', error);
				}
			);
		};
		

	const dismissDialog = () => {

		const filterProps = {
			// mode: 'tags' as const,
			style: { width: '100%', minWidth:'320px', margin: '4px', marginLeft:'-26px' },
			placeholder: 'Select a Reason',
			onChange: (newValue) => {
				set_currentReasonSelected(newValue);
			}
		}
		if (dismissedAlertsReasons.length == 1) {
			set_currentReasonSelected(dismissedAlertsReasons[0].id);
			return (<div>Dismiss this alert?</div>)
		} else if (selectOptions.length > 0) {
			return (
				<>
				<div style={{ minWidth: '300px', width: '300px' }}>Dismiss?</div>
				 <Select {...filterProps}> 
					{selectOptions}
				</Select>
				</>				
			)
		} else {
			return (
				<></>
			)
		}
	}

	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let content;
	if ( profId ) {
		content = (
				 <Popconfirm
								title={dismissDialog}
								onConfirm={confirm}
								// onCancel={cancel}
								okText="Yes"
								cancelText="No"
								okButtonProps={(currentReasonSelected!="") || (dismissedAlertsReasons.length == 1)?{ disabled: false }:{ disabled: true }}
							>
							<Button
								className='button-cell alert-inner button-cell-home'
								data-action="self"
								size={props.size || "small"}
							>
								Dismiss
								<CloseCircleOutlined/>
						</Button>
					</Popconfirm>
		);
	} else {
		content = ( <></> );
	}

	return content;
}

export default DismissCell;
