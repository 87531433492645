// Remove filter for items with too few options
export const filterVisibleFilters = (options) => {
    Object.keys(options).forEach(key => {
        const filterOptionsCount = Object.keys(
            options[key]
        ).filter((value) => value !== '-').length;
        if (filterOptionsCount > 1 && filterOptionsCount < 3) {
            options[key] = {};
        }
    });
    return options;
}
