import { QSAppMetadataFamily, activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { NavLinkWrapper, QSMashupObject, TextKPI } from '@trinity-incyte/ui';
import { PathGenerators } from '@trinity-incyte/utils';
import { Row, Col, Empty, Divider, Typography } from 'antd';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

const MonjuviSalesPerformanceGauges = (props) => {
	const { config } = props;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const activeTeam = useRecoilValue(activeTeamAtom);
	const { IDsTable: ids } = metadata;
	const [vialsKPI, set_vialsKPI] = useState({ QTD: null, YTD: null });
	const [regionVialsKPI, set_regionVialsKPI] = useState({ QTD: null, YTD: null });
	const [accountsKPI, set_accountsKPI] = useState({ QTD: null, YTD: null });
	const [newAccountsKPI, set_newAccountsKPI] = useState({ QTD: null, YTD: null });
	const [existingAccountsKPI, set_existingAccountsKPI] = useState({ QTD: null, YTD: null });

	const navLinkStyle = {
		position: 'absolute',
		top: 0,
		height: '100%',
		width: '100%',
    padding: '4px 16px 0px 4px',
		zIndex: 10
	};

	const kpiDividerStyle = {
		margin: '4px 0',
	};

	return (
		<Row style={{
				flex: '1 1 auto',
				height: '100%'
		}} wrap={false} gutter={[16, 4]} justify="space-around">
				<Col span={5}>
					<NavLinkWrapper
						to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Details/10")}
						val="Monjuvi Guage Territory Vials KPI"
						style={navLinkStyle}
					>
						<Typography.Title level={5} style={{color: '#256678'}}>VIALS</Typography.Title>
						<QSMashupObject
							appId={appId}
							mashupId={ids.get('Mosaic_Main - Monjuvi_kpi_Territory Vials QTD/YTD')?.id}
							noShow
							customFormatter={({ num1, num2 }) => {
								set_vialsKPI({
									QTD: Intl.NumberFormat().format(Math.round(num1)),
									YTD: Intl.NumberFormat().format(Math.round(num2)),
								});
							}}
						/>
						{(vialsKPI.QTD) && (
							<TextKPI
								title="QTD"
								value={vialsKPI.QTD}
							/>
						)}
            <Divider plain type="horizontal" style={kpiDividerStyle}/>
						{(vialsKPI.YTD) && (
							<TextKPI
								title="YTD"
								value={vialsKPI.YTD}
							/>
						)}
					</NavLinkWrapper>
				</Col>
      	<Divider type="vertical" className="HO3-kpi-divider"/>
				<Col span={5}>
					<NavLinkWrapper
						to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Details/10")}
						val="Monjuvi Guage Region Vials KPI"
						style={navLinkStyle}
					>
						<Typography.Title level={5} style={{color: '#256678'}}>REGION VIALS</Typography.Title>
						<QSMashupObject
							appId={appId}
							mashupId={ids.get('Mosaic_Main - Monjuvi_kpi_Region Vials QTD/YTD')?.id}
							noShow
							customFormatter={({ num1, num2 }) => {
								const content = {
									QTD: (isNaN(parseFloat(num1))) ? 0 : Intl.NumberFormat().format(Math.round(num1)),
									YTD: (isNaN(parseFloat(num2))) ? 0 : Intl.NumberFormat().format(Math.round(num2)),
								};
								set_regionVialsKPI(content);
							}}
						/>
						{(regionVialsKPI.QTD !== null) && (
							<TextKPI
								title="QTD"
								value={regionVialsKPI.QTD}
							/>
						)}
            <Divider plain type="horizontal" style={kpiDividerStyle}/>
						{(regionVialsKPI.YTD !== null) && (
							<TextKPI
								title="YTD"
								value={regionVialsKPI.YTD}
							/>
						)}
					</NavLinkWrapper>
				</Col>
      	<Divider type="vertical" className="HO3-kpi-divider"/>
				<Col span={5}>
					<NavLinkWrapper
						to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Details/10")}
						val="Monjuvi Guage Accounts KPI"
						style={navLinkStyle}
					>
						<Typography.Title level={5} style={{color: '#256678'}}>ACCOUNTS</Typography.Title>
						<QSMashupObject
							appId={appId}
							mashupId={ids.get('Mosaic_Main - Monjuvi_kpi_Accounts QTD/YTD')?.id}
							noShow
							customFormatter={({ num1, num2 }) => {
								set_accountsKPI({
									QTD: Intl.NumberFormat().format(Math.round(num1)),
									YTD: Intl.NumberFormat().format(Math.round(num2)),
								});
							}}
						/>
						{(accountsKPI.QTD) && (
							<TextKPI
								title="QTD"
								value={accountsKPI.QTD}
							/>
						)}
            <Divider plain type="horizontal" style={kpiDividerStyle}/>
						{(accountsKPI.YTD) && (
							<TextKPI
								title="YTD"
								value={accountsKPI.YTD}
							/>
						)}
					</NavLinkWrapper>
				</Col>
      	<Divider type="vertical" className="HO3-kpi-divider"/>
        <Col span={4}>
					<NavLinkWrapper
						to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Details/11")}
						val="Monjuvi Gauge New Accounts KPI"
					>
						<Typography.Title level={5} style={{color: '#256678'}}>NEW ACCOUNTS</Typography.Title>
						<QSMashupObject
							appId={appId}
							mashupId={ids.get('Mosaic_Main - Monjuvi_kpi_New Accounts QTD')?.id}
							noShow
							customFormatter={({ num1, num2 }) => {
								set_newAccountsKPI({
									QTD: Intl.NumberFormat().format(Math.round(num1)),
									YTD: Intl.NumberFormat().format(Math.round(num2)),
								});
							}}
						/>
						{!!newAccountsKPI.QTD || !!newAccountsKPI.YTD ? (
							<TextKPI
								title="QTD"
								value={newAccountsKPI.QTD}
							/>
						) : (
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description="No Data"
							/>
						)}
					</NavLinkWrapper>
				</Col>
      	<Divider type="vertical" className="HO3-kpi-divider"/>
				<Col span={4}>
					<NavLinkWrapper
						to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Details/10")}
						val="Monjuvi Gauge Existing Accounts KPI"
					>
						<Typography.Title level={5} style={{color: '#256678'}}>EXISTING ACCOUNTS</Typography.Title>
						<QSMashupObject
							appId={appId}
							mashupId={ids.get('Mosaic_Main - Monjuvi_kpi_Existing Accounts QTD')?.id}
							noShow
							customFormatter={({ num1, num2 }) => {
								set_existingAccountsKPI({
									QTD: Intl.NumberFormat().format(Math.round(num1)),
									YTD: Intl.NumberFormat().format(Math.round(num2)),
								});
							}}
						/>
						{!!existingAccountsKPI.QTD ||
						!!existingAccountsKPI.YTD ? (
							<TextKPI
								title="QTD"
								value={existingAccountsKPI.QTD}
							/>
						) : (
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description="No Data"
							/>
						)}
					</NavLinkWrapper>
				</Col>
		</Row>
	);
};

export default MonjuviSalesPerformanceGauges;
