import { Empty } from 'antd';
import ReactResizeObserver from 'rc-resize-observer';
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useBackTop } from '@trinity-incyte/ui';
import Event from './Event';
import EventsPaneStyle from './EventsPane.module.scss';

const EventsPane = ({
    items = [],
    selectedType,
    selectedCompany,
    selectedTeam,
}) => {
    const [rows, setRows] = useState(items);
    const [self, set_self] = useState<any>();
    const [set_scroller, onResizer, backTop] = useBackTop();
    useEffect(() => {
        setRows(
            items.filter(
                (val) =>
                    (!selectedType || val.filterType === selectedType) &&
                    (!selectedCompany || val.companyName === selectedCompany) &&
                    (!selectedTeam || val.teamName === selectedTeam)
            )
        );
    }, [selectedType, selectedCompany, selectedTeam]);
    return rows.length ? (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'block',
            maxWidth: '100%!important',
          }}>
        <div style={{ height: 'calc(100%)', margin: '0px', flex: 'auto' }}>
            <Virtuoso
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: self?.clientHeight,
                }}
                data={rows}
                scrollerRef={set_scroller}
                components={{
                    List: React.forwardRef((props, listRef) => (
                        <ReactResizeObserver onResize={onResizer}>
                            <div {...props} ref={listRef}></div>
                        </ReactResizeObserver>
                    )),
                }}
                itemContent={(index) => {
                    const val = rows[index];
                    return (
                        <div
                            // need a more targetted key for lists
                            key={'item_' + index /* + val.employeeName + val.prof + val.date.format('MM/DD/YYYY') */}
                            style={{
                                width: 'calc(100% - 20px)',
                                paddingBottom:
                                    index === rows.length - 1 ? '2px' : '15px',
                                position: 'relative',
                                left: '8px',
                            }}
                        >
                            <Event {...val} />
                        </div>
                    );
                }}
            />
            {backTop}
        </div>
        </div>
    ) : (
        <Empty className={EventsPaneStyle.noEvents} image={Empty.PRESENTED_IMAGE_SIMPLE} description={'NO EVENTS THIS WEEK'}/>
    );
};

export default EventsPane;
