import React, { MouseEvent, useContext, useState } from 'react';
import QSTableTiles from '../Other/QSTableTiles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { 
	QSAppMetadataFamily,
	toggleSubPageSelector
} from '@trinity-incyte/recoil';

import { CheckCircleOutlined } from '@ant-design/icons';
import { useQSListbox } from '@trinity-incyte/hooks';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { KPIBox } from '@trinity-incyte/ui';

declare const Mosaic: MosaicGlobal;

const TargetFilter = ({ config, fieldName }) => {

	const appId = config.ids.appId;
	const [buttonsChecked, set_buttonsChecked] = useState([]);

	const { rows: buttonList } = useQSListbox({
		config,
		definition: [fieldName],
		title: 'Target Types',
	});

	const handleTargetClick = (e: MouseEvent<HTMLDivElement>, clickedItem:string) => {
		e.stopPropagation()

		let fieldSalesApp = Mosaic.Qlik.app[appId];

		// if it is currently in the list
		let buttonIndexInList = buttonsChecked.findIndex((obj) => obj.qText==clickedItem);

		let arrButtonsChecked = [...buttonsChecked];
		if (buttonIndexInList!=-1) {
			arrButtonsChecked.splice(buttonIndexInList, 1);
		} else {
			arrButtonsChecked.push({ qText: clickedItem });
		}

		// if fieldName is in the current seletctions, add it, otherwise remove it
		fieldSalesApp.field(fieldName).clear().then(() => {
			fieldSalesApp.field(fieldName).selectValues(arrButtonsChecked).then(() => {
				set_buttonsChecked(arrButtonsChecked);
			});
		}
	
		)
	}

	if (buttonList.length>0) {
		buttonList.sort(function(a, b) { 
			return a.qText.localeCompare(b.qText);
		})

		let hasSelected = buttonList.some((obj) => obj.qState=="S" || obj.qState=="L");

		// if none are selected select them all
		if (!hasSelected) {
			let arrSelectAll = [];
		 	buttonList.forEach((button, i) => {
				buttonList[i].qState="S";
				arrSelectAll.push({ qText: button.qText });
		 	});
			if (arrSelectAll.length>0) {
				// remove this to change the behavior so that it checks the first one
				set_buttonsChecked(arrSelectAll);
			}

		} 

		let buttonRender = buttonList.map((button) => {
			let buttonStyle:React.CSSProperties = {};
			let buttonStyleBase:React.CSSProperties = {
				width: `${100/buttonList.length}%`,
				maxWidth: '50px',
				height: '25px',
				maxHeight: '25px',
				textAlign: 'center',
				padding: '2px 4px 0px 4px',
				border: `1px solid rgb(0, 92, 173, .9)`,
				backgroundColor: `rgb(250, 250, 250)`,
				color: 'rgb(0, 92, 173)',
				borderRadius: '6px',
				cursor: 'pointer',
				margin: '0px 2px',
				fontSize: '1em'
			}

			// if this is selected or none are selected, then all are selected
			let selectedCheck = <></>;
			if ((button.qState=="S") || (button.qState=="L") || (!hasSelected)) {	
				buttonStyle={fontWeight:'bold'};
				selectedCheck = (<CheckCircleOutlined style={{ color: 'green', marginRight: '2px', fontSize: '1em' }} />);
			}
			buttonStyleBase = {...buttonStyleBase, ...buttonStyle};

		return (
			<div style={buttonStyleBase} key={`button_${button.qElemNumber}`} onClick={(event) => handleTargetClick(event, button.qText)}>{selectedCheck} {button.qText}</div>
		)
		})
		return (
			<div style={{textAlign:`right`, width:`100%`, marginTop:`3px`}}>
			<div style={{display:`flex`, maxWidth:`${buttonList.length*57}px`, marginLeft:`auto`, paddingLeft:`auto`}}>
			{buttonRender}
			</div>
			</div>
		);
	} else {
		return <>loading...</>;
	}

}

const BarrierGrid = (props) => {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
    const toggleSubPage = useSetRecoilState(toggleSubPageSelector);

	let tileStyle:React.CSSProperties={width: '32%', minWidth:'60px', maxWidth:'32%', borderWidth:'1px', maxHeight:'20%', margin:'2px'};
	const barrierFilterId = fields.get('Mosaic_Field Sales_MPN Target Type Island Filter')?.id;
	const gridBox:React.CSSProperties = {
		padding:"0px",
	};
	const subHeaderCss:React.CSSProperties = {
		margin:"4px 0px 0px 2px",
		padding:"0px",
		lineHeight:"1em",
		fontWeight: 700
	};

	const openBarrierModal = (barrierIndication, tileIndex) => {

		// Possible values are: Barrier Identified, No Objection, Not Identified			
		const barrierValues = ['Barrier Not Identified', 'Barrier Identified', 'No Objection'];

		// Apply the filters
		let fieldSalesApp = Mosaic.Qlik.app[appId];

		let barrierIndicationFilter = fields.get('Mosaic_Field Sales_Barrier Indication Island Filter')?.id;
		let barrierIndicationField = fieldSalesApp.field(barrierIndicationFilter); 
		barrierIndicationField.clear().then(() => {
			// Possible values are MF, PV, AGVHD, CGVHD
			barrierIndicationField.selectValues( [barrierIndication]);
		})

		let barrierCategoryFilter = fields.get('Mosaic_Field Sales_Barrier Category Island Filter')?.id
		let barrierCategoryField = fieldSalesApp.field(barrierCategoryFilter); 
		barrierCategoryField.clear().then(() => {
			barrierCategoryField.selectValues( [barrierValues[tileIndex]] );
		});


		// open the window with the chart
		let mashupId = ids.get('Mosaic_Field Sales_Jakafi Barrier Details')?.id;
		toggleSubPage({
			mashupId,
			appId: appId,
			dynamic: true,
			tableProps: {
				colOverrides: [],
			},
			onUnMount: () => {
				barrierIndicationField.unlock().then(()=>barrierIndicationField.clear());
				barrierCategoryField.unlock().then(()=>barrierCategoryField.clear());
			}
		});

	}

	return (
		<KPIBox customClass="kpibox kpi-threesixty" style={{width:'100%'}}>

			<div style={{display:'flex', width:'100%', height:'2.4rem'}}>
				<div style={{width:'30%', lineHeight: '1em'}} className='homeSectionHead'></div>
				<div style={{width:'70%', textAlign:'right'}}><TargetFilter config={config} fieldName={barrierFilterId}/></div>
			</div>
			<div style={{display:'flex', width:'100%', marginTop:'1em'}}>
				<div className='homeSectionHead' style={{width:'100%', lineHeight: '1em', margin:'0rem 0px 0px 0.4px', padding:'0px 0px 0px 0px'}}>
					MPN Barriers
				</div>
			</div>
			<div className={'headerOfSomeSort'} style={subHeaderCss}>
				PV
			</div>
			<div className={'gridBox'} style={gridBox}>
				<QSTableTiles 
					appId={appId} 
					mashupId={ids.get('Mosaic_Field Sales_Main - Jakafi_PV Barrier Identification')?.id} labelColumnId={0} valueColumnId={1} 
					customStyle={tileStyle}
					customContainerClass='qsTableTileContainer_HCOBarriers'
					onClickCallback={(index) => { openBarrierModal('PV', index) }}
					/>
			</div>
			<div className={'headerOfSomeSort'} style={subHeaderCss}>
				MF
			</div>
			<div className={'gridBox'} style={gridBox}>
				<QSTableTiles appId={appId} mashupId={ids.get('Mosaic_Field Sales_Main - Jakafi_MF Barrier Identification')?.id} labelColumnId={0} valueColumnId={1} 
								customStyle={tileStyle}
								customContainerClass='qsTableTileContainer_HCOBarriers'
								onClickCallback={(index) => { openBarrierModal('MF', index) }}
								/>
			</div>
			<div className='homeSectionHead' style={{lineHeight:'1em', margin:'0.6em 0px 0px 0.6px' , padding:'0px 0px 0px 0px'}}>
				GVHD Barriers
			</div>
			<div className={'headerOfSomeSort'} style={subHeaderCss}>
				aGVHD Barriers
			</div>
			<div className={'gridBox'} style={gridBox}>
				<QSTableTiles appId={appId} mashupId={ids.get('Mosaic_Field Sales_Main - Jakafi_aGVHD Barrier Identification')?.id} labelColumnId={0} valueColumnId={1} 
								customStyle={tileStyle}
								customContainerClass='qsTableTileContainer_HCOBarriers'
								onClickCallback={(index) => { openBarrierModal('AGVHD', index) }}
								/>
			</div>
			<div className={'headerOfSomeSort'} style={subHeaderCss}>
				cGVHD Barriers
			</div>
			<div className={'gridBox'} style={gridBox}>
				<QSTableTiles appId={appId} mashupId={ids.get('Mosaic_Field Sales_Main - Jakafi_cGVHD Barrier Identification')?.id} labelColumnId={0} valueColumnId={1} 
								customStyle={tileStyle}
								customContainerClass='qsTableTileContainer_HCOBarriers'
								onClickCallback={(index) => { openBarrierModal('CGVHD', index) }}
								/>
			</div>
		</KPIBox>
	);
}

export default BarrierGrid;
