import React from "react";
import {HCP360State} from '@trinity-incyte/context';
import HCP360Router from "./HCP360Router";

export const HCP360Home = (props) => {
    const profId = props?.profId;
    return (
        <HCP360State profId={profId}>
            <HCP360Router />
        </HCP360State>
    )
}

export default HCP360Home;