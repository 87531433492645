import React, { useContext } from 'react';
import {
	Button,
	Card,
	Collapse,
} from 'antd';
import { NavLink } from 'react-router-dom';

import { NavLinkWrapper, QSSelectionBar, KPIBox, Loader as UILoader, KPIHomeHeader, AlertsPane, AlertsState } from '@trinity-incyte/ui';
import BrandSelectionButtons from '../Groups/BrandSelectionButtons';
import ComplexListBuilder from '../Other/ComplexListBuilder';
import ComplexInteractionsTimeline from '../Other/ComplexInteractionsTimeline';
import KeySegmentsCount, { OrderedKeySegments } from '../Other/KeySegmentsCount';
import Notifications from '../Other/Notifications';
import Config from '../../Config';
import PemiBox from '../Other/PemiBox';
import JakafiGauges from '../Other/JakafiGauges';
import PaceCalculator from '../Other/PaceCalculator';
import BarrierGrid from '../Other/BarrierGrid';
import EventBox from '../Other/EventBox';
import CurrentEventsBox from '../Other/CurrentEvents';
import EventsCalendar from '../Other/Notifications/EventsCalendar';
import MonjuviSalesPerformanceGauges from '../Other/MonjuviSalesPerformanceGauges';
import { LinkOutlined } from '@ant-design/icons';
import { PathGenerators } from '@trinity-incyte/utils';
import { QSAppMetadataFamily, activeBrand } from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { useRecoilValue } from 'recoil';

declare const Mosaic: MosaicGlobal

const config = Config.Qlik.FieldSales;
const Other = {
	'/Index - Circle Gauges': {
		content: (<JakafiGauges config={config} />),
	},
	'/Index - Circle Gauges OCNE': {
		content: (<JakafiGauges config={config} />),
	},
	'/Index - Barrier Grid': {
		component: () => (
			<BarrierGrid />
		)
	},
	// '/Index - Pace Box': {
	// 	component: () => (
	// 		<PaceCalculator config={config} style={{ overflowY: 'auto' }} />
	// 	)
	// },
	'/Index - Pemi Box': {
		component: () => (
			<PemiBox config={config} />
		)
	},
	'/Index - Event Box': {
		component: () => (
			<EventBox />
		)
	},
	'/Index - Alerts': {
		component: () => {
			const Config = useContext(ConfigContext)
			const fieldSalesAppId = Config.Qlik.FieldSales.ids.appId
			const metadata = useRecoilValue(QSAppMetadataFamily(fieldSalesAppId))
			const { IDsTable: ids, FieldsTable: fields } = metadata
			return (
				<AlertsState dependencyFields={[
					'FSD.TeamID',
					'SA_USERID',
					fields.get('Mosaic_Field Sales_Area HO1')?.id,
					fields.get('Mosaic_Field Sales_Region HO1')?.id,
					fields.get('Mosaic_Field Sales_Territory HO1')?.id,
					fields.get('Mosaic_Field Sales_Region HO2')?.id,
					fields.get('Mosaic_Field Sales_Territory HO2')?.id,
					fields.get('Mosaic_Field Sales_Region HO3')?.id,
					fields.get('Mosaic_Field Sales_Territory HO3')?.id,
					fields.get('Mosaic_Field Sales_Territory OCNE')?.id
				]}>
					<AlertsPane/>
				</AlertsState>
			)
		},
	},
	'/Index - Key Segments': {
		component: ({ activeTeam }) => (
			<div className="key-segments-container-team11" style={{ height: 'calc(100% - 1em)', overflowY: 'auto', padding: '0.5em' }}>
				<KPIHomeHeader>
					<NavLink to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Jakafi/Details/0")}>
						<span style={{textDecoration:'underline'}}>Key Segments</span>
						<LinkOutlined />
					</NavLink>
				</KPIHomeHeader>
				<div>
					<KeySegmentsCount config={config} />
				</div>
			</div>
		),
	},
	'/Index - Reminders': {
		component: () => {
			const panelItems = [{
				header: 'Register for the National Meeting',
			}, {
				header: 'Appt. 10:00 AM - Dr. Safah',
			}, {
				header: 'Appt. 2:30PM - Dr. Oh',
			}, {
				header: 'Speaker Program this week',
			}, {
				header: 'Send Dr. Banner OS whitepaper',
			}, {
				header: 'Dr. Swami published a new article',
			}];

			return (
				<Card style={{ background: 'transparent' }} title={<h2>Reminders</h2>}>
					<Collapse defaultActiveKey={['0']}>
						{panelItems.map((val) => {
							return (
								<Collapse.Panel header={val.header} key={val.header}>
									<p>{val.header}</p>
								</Collapse.Panel>
							)
						})}
					</Collapse>
				</Card>
			);
		},
	},
	'/Events/Index': {
		component: (props) => (
			<Notifications {...props} config={config} />
		),
	},
	'/Events/Calendar': {
		component: (props) => (
			<EventsCalendar {...props} />
		),
	},
	'Landing Page Circle Gauges': {
		content: (
			<MonjuviSalesPerformanceGauges config={config} apex />
		),
	},
	'Landing Page Current Week Events': {
		content: (
			<div style={{ height: 'calc(100% - 2rem)', overflowY: 'auto', padding: '0.5em' }}>
				<KPIHomeHeader>
					<NavLink to="/Events/Calendar">
						<span style={{ textDecoration: 'underline' }}>Current Week Events</span>
						<LinkOutlined />
					</NavLink>
				</KPIHomeHeader>
				<div>
					<CurrentEventsBox config={config} />
				</div>
			</div>
		),
	},
	'Landing Page Alerts': {
		component: () => {
			const Config = useContext(ConfigContext)
			const fieldSalesAppId = Config.Qlik.FieldSales.ids.appId
			const metadata = useRecoilValue(QSAppMetadataFamily(fieldSalesAppId))
			const { IDsTable: ids, FieldsTable: fields } = metadata
			return (
				<AlertsState dependencyFields={[
					'FSD.TeamID',
					'SA_USERID',
					fields.get('Mosaic_Field Sales_Area HO1')?.id,
					fields.get('Mosaic_Field Sales_Region HO1')?.id,
					fields.get('Mosaic_Field Sales_Territory HO1')?.id,
					fields.get('Mosaic_Field Sales_Region HO2')?.id,
					fields.get('Mosaic_Field Sales_Territory HO2')?.id,
					fields.get('Mosaic_Field Sales_Region HO3')?.id,
					fields.get('Mosaic_Field Sales_Territory HO3')?.id,
					fields.get('Mosaic_Field Sales_Territory OCNE')?.id
				]}>
					<AlertsPane/>
				</AlertsState>
			)
		},
	},
	'Landing Page Key Segments': {
		component: ({ activeTeam }) => (
			<div className="key-segments-container-HO3" style={{ height: 'calc(100% - 1em)', overflowY: 'auto', padding: '0.5em' }}>
				<KPIHomeHeader>
					<NavLink to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Details/0")}>
						<span style={{textDecoration:'underline'}}>Key Segments</span>
						<LinkOutlined />
					</NavLink>
				</KPIHomeHeader>
				<div>
					<KeySegmentsCount config={config} />
				</div>
			</div>
		),
	},
	'Ordered Key Segments': {
		component: ({ activeTeam }) => (
			<div className="ordered-key-segments-container" style={{ overflowY: 'auto', padding: '0.5em' }}>
				<KPIHomeHeader>
					<span>Key Segments</span>
				</KPIHomeHeader>
				<div>
					<OrderedKeySegments activeBrand={activeBrand} activeTeam={activeTeam} config={config} key={`key-segments-${activeTeam}`} />
				</div>
			</div>
		),
	},
	'discovery_trends': {
		component: ({ activeTeam }) => (
			<div style={{ padding: '0.5rem' }}>
				<h2>
					<LinkOutlined />
					<NavLink to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Monjuvi/Trends/0")}>
						<span style={{ textDecoration: 'underline', marginLeft: '2rem' }}>Key Segments</span>
					</NavLink>
				</h2>
				<div
					style={{ height: 'inherit' }}
				>
					<KeySegmentsCount config={config} />
				</div>
			</div>
		),
	},
	'discovery_messages': {
		content: (
			<NavLinkWrapper
				path="/ActionCenter/Messages"
			>
				<div style={{ padding: '1rem', fontSize: '1.2em' }}>
					<div>
						<ul style={{ listStyle: 'square inside' }}>
							<li>April 28, 2020 - Please register for the July regional sales meeting by clicking <a>here</a></li>
							<li>April 17, 2020 - FDA approves Pemazyre™ (pemigatinib) as First Targeted Treatment for Adults with Previously Treated, Unresectable Locally Advanced or Metastatic Cholangiocarcinoma</li>
						</ul>
					</div>
				</div>
			</NavLinkWrapper>
		),
	},
	'Brand Selection Buttons': {
		content: (
			<BrandSelectionButtons config={config} />
		),
	},
	'Loader': {
		component: (props) => (
			<div color={props.color} style={{ height: '100%', width:'100%' }}>
				<UILoader></UILoader>
			</div>
		),
	},
	'Message Box': {
		component: (props) => (
			<div color={props.color} style={{width:"100%"}}>
				<span style={{ fontSize: '2rem' }}>
					{props.text}
				</span>
			</div>
		),
	},
	'KPI Box': {
		component: (props) => (
			<KPIBox
				customClass={`kpibox kpi-${props.color || 'blue'}`}
				{...props}
			>
				{props.children}
			</KPIBox>
		),
	},
	'opportunity_segments_buttons': {
		content: (
			<Button.Group style={{display:"flex", flexFlow:"column"}}>
				<Button color="teal">High MF Patient Potential</Button>
				<Button color="teal">High PV Patient Potential</Button>
				<Button color="teal">Targets (A, B, C, E)</Button>
				<Button color="teal">High GVHD Patient Potential</Button>
				<Button color="teal">BMT Team</Button>
				<Button color="teal">Growth Opportunity</Button>
				<Button color="teal">Prospecting</Button>
				<Button color="teal">Exploratory Targets</Button>
				<Button color="teal">VA HCPs</Button>
				<Button color="teal">PEM Targets (Field, NPP)</Button>
			</Button.Group>
		),
	},
	'Complex List Builder': {
		component: (props) => (
			<ComplexListBuilder {...props} config={Config.Qlik.Prof360} />
		),
	},
	'Complex Interactions Timeline': {
		component: (props) => (
			<ComplexInteractionsTimeline {...props} />
		),
	},
	'Selections Bar': {
		component: (props) => (
			<QSSelectionBar {...props} />
		),
	},
};

export default Other;
