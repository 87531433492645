import React, { useState, useEffect, useContext } from 'react';
import Disclaimer from '../../disclaimer/disclaimer';
import { closeModalSelector, hasAcceptedState, modalState, openDisclaimerSelector, openModalSelector } from '@trinity-incyte/recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ConfigContext } from '@trinity-incyte/context';

/* eslint-disable-next-line */
export interface DisclaimerGroupProps {}

export function DisclaimerGroup(props: DisclaimerGroupProps) {
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const Config = useContext(ConfigContext);
	const hasAccepted = useRecoilValue(hasAcceptedState);
	const openDisclaimer = useSetRecoilState(openDisclaimerSelector);
	const closeModal = useSetRecoilState(closeModalSelector);
	const closeDisclaimerGroup = () => setShowDisclaimer(false);

	useEffect(() => {
		const lastAcceptance = new Date(parseInt(localStorage.getItem('disclaimerAccepted'),10));
		const acceptanceGoodUntil = new Date();
		acceptanceGoodUntil.setDate(lastAcceptance.getDate() + 1);

		const supressDisclaimer = Date.now() < acceptanceGoodUntil.valueOf();
		const flag = Config.Qlik.showDisclaimer && !supressDisclaimer;
		setShowDisclaimer(flag);
	}, []);

    return <>
			{!showDisclaimer || 
				openDisclaimer({content:<Disclaimer closeDisclaimerGroup={closeDisclaimerGroup} />})
			}
    </>;
};

export default DisclaimerGroup;
