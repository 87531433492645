import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductJakafiSales = () => {
    const defaultLayout = {
        responsive: [{
            w: 15,
            items: [{
                items: [{
                    w: 24,
                    i: 'HCO360 Jakafi Bottles'
                }]
            }, {
                items: [{
                    w: 8,
                    i: 'HCO360 Jakafi Growth Rate'
                }, {
                    w: 16,
                    i: 'HCO360 Jakafi Top Prescribers'
                }]
            }, {
                items: [{
                    w: 8,
                    i: 'HCO360 Jakafi Total Account SP Patients',
                }, {
                    w: 16,
                    i: 'HCO360 Jakafi Average Quintile of HCPs',
                }]
            },
            {
                items: [{
                    w: 24,
                    i: 'HCO 360 Jakafi Key Individuals'
                }]
            },
            {
                items: [{
                    w: 24,
                    i: 'HCO360 Overview BMT Staff'
                }]
            }
            ]
        },
        {
            w: 9,
            items: [{
                w: 24,
                i: 'HCO360 Jakafi R12M Volume'
            },
            {
                w: 24,
                i: 'HCO360 Affiliations'
            }]
        }
    ]}

	return (
		<>
			<Grid
				title="Jakafi Sales"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductJakafiSales;
