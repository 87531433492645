import React, { useContext } from "react";
import {  LoadingOutlined } from "@ant-design/icons";

import { useRecoilValue } from "recoil";
import PreCallPlanningStyles from './PreCallPlanning.module.scss';
import { activeTeam as activeTeamAtom } from "@trinity-incyte/recoil";

import { use360ProfSelection } from '@trinity-incyte/hooks';
import { HCP360Context } from "@trinity-incyte/context";
import { ConfigContext } from '@trinity-incyte/context';

const HCPDetails = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection( profId );
    const activeTeam = useRecoilValue(activeTeamAtom);
    const hcp360Context = useContext(HCP360Context);
    const profData = hcp360Context.profData;
    const Config = useContext(ConfigContext);

    let content = (<>Loading HCP Details...<br/><LoadingOutlined /></>);

    if ( profId !== false && profId !== '-'  && isProfSelected && profData && activeTeam) {
        let haspProfImage = ( profData.imgUrl && (profData.imgUrl != '-' || profData.imgUrl != '' )) ;

        content = (           
            <div style={{display:'flex', width:'100%', fontSize:'12px'}}>

                {/* IMAGE */}
                {(haspProfImage) && (
                    <div style={{marginRight: '4px'}}>
                        <img src={profData.imgUrl}
                            className={PreCallPlanningStyles.profImage}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=Config.App.profImagesDefault;
                              }}
                        />
                    </div>
                )}

                {/* BIO INFO */}
                <div className={PreCallPlanningStyles.normalBox} style={{flexGrow:1}}>
                    {/* NAME */}
                    <div className={PreCallPlanningStyles.bioName}>
                            {profData.bio.Name}
                            {profData?.bio?.Degree && (
                                <>, {profData.bio.Degree}</>
                            )} ({props.profId})
                    </div>

                    {/* ADDRESS */}
                    <div className="precallProfData_detail">
                        {profData.bio.Address[activeTeam]} {profData.bio.Address2[activeTeam]} {profData.bio.City[activeTeam]}, {profData.bio.State[activeTeam]} {profData.bio.Zip[activeTeam]} 
                    </div>

                    {/* AFFILIATION/HOSPITAL */}
                    <div className="precallProfData_detail profData_affiliations">
                        Affiliations: <span style={{fontWeight:'bold'}}>{profData.bio.Affiliations}</span>
                    </div>

                    {/* EDUCATION */}
                    <div className="precallProfData_detail">
                        Education: {profData.education} 
                    </div>

                    {/* FELLOWSHIP */}
                    <div className="precallProfData_detail">
                        Fellowship: {profData.fellowship}
                    </div>
                </div>


                {/* STATE RESTRICTIONS */}
                {(profData.bio['State Restrictions'] && profData.bio['State Restrictions'] !== '-') && (
                    <div className={PreCallPlanningStyles.normalBox} style={{textAlign:'center', fontWeight:'bold', paddingTop: '2em'}}>
                    <div className="state_restrictions" style={{color:'red'}}>State Restrictions</div>
                    <div className="state_restrictions_data" style={{color:'red'}}>{profData.bio['State Restrictions'] || 'N/A'}</div>
                    </div>
                )}


                {/* SALES TARGET AND OPPORTUNITY */}
                <div className={PreCallPlanningStyles.normalBox_noBorder}>
                    {/* SALES TARGET TYPE */}
                    <div className={PreCallPlanningStyles.targetBox}>
                        <div className={PreCallPlanningStyles.targetBoxHead}>Sales Target Type</div>
                        <table className={PreCallPlanningStyles.targetBoxTable}>
                            <thead>
                            <tr>
                                <th>Jakafi</th>
                                <th>Pemazyre</th>
                                <th>Monjuvi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{profData.targetJak || 'NT'}</td>
                                <td>{profData.targetPem || 'NT'}</td>
                                <td>{profData.targetMon || 'NT'}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        )
    }
	return ( content );
};

export default HCPDetails;


