import { ConfigContext, HCO360Context } from '@trinity-incyte/context';
import React, { useContext, ReactNode, useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Menu, Row } from 'antd';
import styled from 'styled-components';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { InnerPageNavGroup } from '@trinity-incyte/ui';
import { CaretDownFilled } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import {
	QlikUser as qlikUserAtom,
} from '@trinity-incyte/recoil';
declare const Mosaic: MosaicGlobal;

declare var window: any;

const MAX_RECENT_ITEMS = 10

/* eslint-disable-next-line */

const NavButton = styled(Button)`
	background-color: #005CAB;
	border-color: #005CAB;
	color: white;
	height: 100%;
	font-size: 1.2rem;
	font-weight: 700;
`;

type RecentlyViewedItems = {
    name: string
    id: string
}

export interface NavGroupMosaicProps {
	urlSuffix?: string;
	showClear?: boolean;
	incyteActivities?: any[];
	publicActivities?: any[];
	size?: any;
	children?: ReactNode;
	hideConnections?: boolean
}

export function HCO360TopNav(props: NavGroupMosaicProps) {
	const Config = useContext(ConfigContext);
	const hco360Context = useContext(HCO360Context);
    const [recentlyViewedItems, setRecentlyViewedItems] = useState<Array<RecentlyViewedItems>>(JSON.parse(localStorage.getItem('HCO360RecentGroups')) || []);
	const config = Config.Qlik.Prof360;

	const history = useHistory();
    
	const userData = useRecoilValue(qlikUserAtom);
	const dataDateJakPem = userData.dataDate.jakPem;

    useEffect(() => {
		if (hco360Context.state.groupId) {
			
			// form the new entry object
			let newEntry:RecentlyViewedItems = { name: hco360Context.state.groupData.name, id: hco360Context.state.groupId };

			// initialize the new array
			let newRecentlyViewedItems = [...recentlyViewedItems];

			let recentItemsFoundIndex = newRecentlyViewedItems.find((recentItem) => recentItem.id == hco360Context.state.groupId);

			// if it's already in there, remove it
			let itemInListIndex = newRecentlyViewedItems.indexOf(recentItemsFoundIndex);

			if (itemInListIndex!=-1) {
				newRecentlyViewedItems.splice(itemInListIndex, 1);
				setRecentlyViewedItems(newRecentlyViewedItems);
			}

			// now add it at the beginning
			newRecentlyViewedItems = [newEntry, ...newRecentlyViewedItems];

			// get rid of the one at the end
			if (newRecentlyViewedItems.length > MAX_RECENT_ITEMS) {
				newRecentlyViewedItems.pop();
			}

			localStorage.setItem('HCO360RecentGroups', JSON.stringify(newRecentlyViewedItems));
			setRecentlyViewedItems(newRecentlyViewedItems);

		} else {
			return;
		}
    }, [hco360Context.state.groupId]);

    let dropDownItemsRecent = recentlyViewedItems.map((ri) => {
        return { text: `${ri.name} (${ri.id})`, to: `/HCO360/${ri.id}`, onClick: ()=> { 
            hco360Context.applyGroupId(ri.id)
        }, type: 'ITEM' }
    })

	if (dropDownItemsRecent.length==0) {
        dropDownItemsRecent = [{ text: 'No recent items', onClick: () => {}, to: '', type: 'ITEM' }]
	}

	let dropDownItems = [
        { text: 'Search', to: '/HCO360/', onClick: () => {}, type: 'ITEM' },
        { text: '', to: '', onClick: () => {}, type: 'DIVIDER' },
        { text: 'Recent HCO360:', to: '', onClick: null, type: 'HEADER' },
        ...dropDownItemsRecent
	];

	return (
		<InnerPageNavGroup
			{...props}
            dataDate={dataDateJakPem}
            dataDateLabel={"Data Through"}
			dropdownText={(<span className="submenu-header">{'HCO 360°'}</span>)}
			dropdownItems={dropDownItems}
			config={config}
            showLastRX={true}
		>
            <Row gutter={[8, 4]} justify="start" style={{ width: '100%', background: 'transparent' }}>
			{( hco360Context.state.groupId) && (
                <>
                    <Menu.SubMenu
						className="styled-submenu square-corners no-active"
						icon={<CaretDownFilled style={{ color: "white" }}/>}
                        theme="light"
                        title={<span className="submenu-header">{`Product: ${hco360Context.state.brand ? hco360Context.state.brand : ' All'}`}</span>}
                    >
                        <Menu.Item
                            key="HCO360_Menu_All"
                            style={{ width: "200px", fontSize: "1em" }}
                        >
                            <NavLink
                                className="square-corners"
                                onClick={() => {
                                    window.analytics?.track('Link Clicked', { text: 'Product', context: 'HCO360 Overview Button Clicked'})
                                }}
                                style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                                to={`/HCO360/${hco360Context.state.groupId}/`}
                            >
                                All Brands
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            key="HCO360_Menu_Jakafi"
                            style={{ width: "200px", fontSize: "1em" }}
                        >
                            <NavLink
                                className="square-corners"
                                onClick={() => {
                                    window.analytics?.track('Link Clicked', { text: 'Product', context: 'HCO360 Product Button Clicked'})
                                }}
                                style={{ paddingLeft: '2rem', paddingRight: '1.8rem' }}
                                to={`/HCO360/${hco360Context.state.groupId}/Jakafi/`}
                            >
                                Jakafi
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            key="HCO360_Menu_Pemazyre"
                            style={{ width: "200px", fontSize: "1em" }}
                        >
                            <NavLink
                                className="square-corners"
                                onClick={() => { 
                                    window.analytics?.track('Link Clicked', { text: 'Product', context: 'HCO360 Product Button Clicked - Pemazyre'})
                                }}
                                style={{ paddingLeft: '2rem', paddingRight: '1.8rem' }}
                                to={`/HCO360/${hco360Context.state.groupId}/Pemazyre/`}
                            >
                                Pemazyre
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            key="HCO360_Menu_Monjuvi"
                            style={{ width: "200px", fontSize: "1em" }}
                        >
                            <NavLink
                                className="square-corners"
                                onClick={() => { 
                                    window.analytics?.track('Link Clicked', { text: 'Product', context: 'HCO360 Product Button Clicked'})
                                }}
                                style={{ paddingLeft: '2rem', paddingRight: '1.8rem' }}
                                to={`/HCO360/${hco360Context.state.groupId}/Monjuvi/`}
                            >
                                Monjuvi
                            </NavLink>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item 
                        className="no-padding static-topNav-item"
                    >
                        <NavButton
                            size={props.size || 'large'} color="blue"
                            className={`square-corners${hco360Context.state.activeView == 'Overview' ? ' active' : ''}`}
                            style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                            onClick={() => { 
                                if (hco360Context.state.brand == null) {
                                    history.push(`/HCO360/${hco360Context.state.groupId}/`)
                                } else {
                                    history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/`)
                                }

                                window.analytics?.track('Link Clicked', { text: 'HCO Product Summary', context: 'HCO360 Product Summary Button Clicked'})
                            }}
                        >
                            Overview
                        </NavButton>
                    </Menu.Item>
                    {(hco360Context.state.brand) && (
                        <>
                            <Menu.Item 
                                key="HCO360_Menu_Targets"
                                className="no-padding static-topNav-item"
                            >
                                <NavButton
                                    size={props.size || 'large'} color="blue"
                                    className={`square-corners${hco360Context.state.activeView == 'HCPs' ? ' active' : ''}`}
                                    style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                                    onClick={() => { 
                                        history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/HCPs`);
                                        window.analytics?.track('Link Clicked', { text: 'HCPs', context: 'HCO360 HCPs Button Clicked'})
                                    }}
                                >
                                    HCPs
                                </NavButton>
                            </Menu.Item>
                            <Menu.Item 
                                key="HCO360_Menu_Connections"
                                className="no-padding static-topNav-item"
                            >
                                <NavButton
                                    size={props.size || 'large'} color="blue"
                                    className={`square-corners${hco360Context.state.activeView == 'Connections' ? ' active' : ''}`}
                                    style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                                    onClick={() => { 
                                        history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/Connections`);
                                        window.analytics?.track('Link Clicked', { text: 'Connections', context: 'HCO360 Connections Button Clicked'})
                                    }}
                                >
                                    Connections
                                </NavButton>
                            </Menu.Item>
                            <Menu.Item 
                                key="HCO360_Menu_Reports"
                                className="no-padding static-topNav-item"
                            >
                                <NavButton
                                    size={props.size || 'large'} color="blue"
                                    className={`square-corners${hco360Context.state.activeView == 'Reports' ? ' active' : ''}`}
                                    style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                                    onClick={() => { 
                                        history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/Reports`);
                                        window.analytics?.track('Link Clicked', { text: 'Reports', context: 'HCO360 Reports Button Clicked'})
                                    }}
                                >
                                    Account Details
                                </NavButton>
                            </Menu.Item>
                        </>
                    )}
                </>
			)}
            </Row>
			{props.children}
		</InnerPageNavGroup>
	);
};

export default HCO360TopNav;
