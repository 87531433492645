/**
 * This page is blank, a design that was not fully pursued
 **/

import React, { useContext } from 'react';

import {RGLGrid as Grid, InnerPageNavGroup} from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';

const Main = ( props ) => {
    const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;

	const defaultLayout = {
		"lg":[
			{"w":24,"h":16,"x":0,"y":0,"i":"action_feed","moved":false,"static":true},
	]};

	return (
		<>
			<InnerPageNavGroup
				dropdownText="Action Center"
				dropdownHeader="Action Center"
				config={config}
				showFilters
				showClear
				dropdownItems={[
					{ text: 'Action Center', to: '/ActionCenter' },
					{ text: 'Calendar', to: '/ActionCenter/Calendar' },
				]}
			/>
			<Grid
				title="Action Center"
				layout={defaultLayout}
			/>
		</>
	);
};
// TO DO: decide whether or not to delete this file. I believe it is unused
export default Main;
