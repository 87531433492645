import { environment } from '../environments/environment';
import { v1 as uuidv1 } from 'uuid';
import { ProfKPIGroup } from '@trinity-incyte/ui'

const Config = {
    App: {
        title: 'Incyte Mosaic',
        name: 'Mosaic',
        segmentWriteKey: 'pO5MmVvpGzTIL1cMv3qvooBmc5LHi3DE',
        authFailRedirectUrl: environment.authFailRedirectUrl,
        listBuilder_class: environment.listBuilder_class,
        profImagesServer: environment.profImagesServer, // The dynamic image name gets directly appended to this url
        profImagesDefault: environment.profImagesServer + 'default_profimage.png',
        authFailRedirectWait: 500, // In milliseconds
        crmPrefix: 'https://na43.salesforce.com/apex/QlickviewGVHDPage?BMTOrgId=', // The Prof ID gets directly appende to this
        unknownWebsiteUrl: 'https://inside.incyte.com/',
        unknownCRMUrl: 'https://inside.incyte.com/',
        unknownGoogleMapUrl: 'https://inside.incyte.com/',
        defaultToGridTest: false,
        skipQlikAuth: false,
        grid: {
            cols: { lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 },
            vUnits: 16, // How many vertical units?
            rHeight: 100, // How many pixels per vertical grid? THIS IS A FALLBACK
        },
        defaultProfId: 10810095,
        mdmUrl: 'https://incyte-us.okta.com/app/incytecorporationhipaaprod_mdmportalmv_1/exkjqam61lMKOVPVf296/sso/saml?RelayState=%2FBccPortal%2F%2FMdmpProfDetails.aspx%2F<PROFID>%2F<RANDOM>',
        mdmRedirect: environment.mdmUrlRedirect,
        routerBasename: environment.routerBasename,
        siteStatusUrl: 'https://incyte-mosaic.azurewebsites.net/api/siteStatus?code=_jSFcysBNTBCBG4skNGWk6bFkEkFDEEY9-LQkq3TudnSAzFuDV3QvA==',
        oceSamlUrl: environment.oceSamlUrl,
        oceSiteUrl: environment.oceSiteUrl,
        walkmeUrl: environment.walkmeUrl,
        buildDate: environment.buildDate,
        enableAnalytics: true,
        enableSentry: false,
        defaultStyles: {
            fontFamily:"'Roboto', sans-serif"
        },
        profHeaders: ['PROF ID', 'PROF FULL NAME (ID)', 'PROF NAME (ID)', 'HCP', 'BLINDED PROF FULL NAME (ID)', 'FROM HCP'],
        profComponent: ProfKPIGroup,
        teamToBrand: [
            { 'teamName':'HO1', 'teamNumber': '11', 'primaryBrand': 'JAKAFI', 'brands': ['JAKAFI'], 'userTerritoryField': 'vSalesForceHeaderTM11' }, 
            { 'teamName':'HO2', 'teamNumber': '12', 'primaryBrand': 'JAKAFI', 'brands': ['JAKAFI', 'NIKTIMVO'], 'userTerritoryField': 'vSalesForceHeaderTM12' }, 
            { 'teamName':'HO3', 'teamNumber': '21', 'primaryBrand': 'MONJUVI', 'brands': ['MONJUVI', 'PEMAZYRE', 'ZYNYZ'], 'userTerritoryField': 'vSalesForceHeaderTM21' }, 
            { 'teamName':'OCNE', 'teamNumber': '61', 'primaryBrand': 'JAKAFI', 'brands': ['JAKAFI', 'PEMAZYRE', 'MONJUVI'], 'userTerritoryField': 'vSalesForceHeaderOCNE' } 
        ],
        features: [
            { featureName: environment?.features?.['HCO360'] },
            { featureName: environment?.features?.['chatbot'] },
            { teams: ['HO1', 'HO2'], brands: ['JAKAFI'], featureName: 'Field Sales Barrier Analysis' }
        ],
        embedLinks: {
            chatBot: [
                { teams: ['HO1', 'HO2'], embedLink: 'https://posit.incyte.com/content/b5914500-5007-4ee6-a280-6bde37868e3a' },
                { teams: ['HO3'], embedLink: 'https://posit.incyte.com/content/f88cc956-d25b-44a0-bbdc-ee2c6c95c87c' },
            ],
        },
        reports: {
            trends: [
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_Targets Reached - Calls' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_New and Existing Accounts' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_Total Direct Sales' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_barchart_New and Existing Accounts' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_linechart_Launch to Date Accounts' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_barchart_Rx Trend by City' },
                { teams: ['OCNE', 'HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_barchart_Total Direct Sales' },
                { teams: ['OCNE', 'HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_linechart_Targets Reached - Monthly Data Only' },
                { teams: ['OCNE', 'HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_barchart_New SP Patients' },
                { teams: ['OCNE', 'HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_barchart_New SP Prescribers' },
                { teams: ['OCNE', 'HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_barchart_Sales by Channel' },
                { teams: ['OCNE', 'HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_barchart_Sales by Target Type' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_linechart_Normalized Rx Trend - Jakafi' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_Trend_Avg Rx Per Day By Qtr' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_barchart_City Rx Trend by SP and Direct' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_barchart_Sales by Channel' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_linechart_Direct Sales % of Total' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_barchart_% Change from Prior' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_barchart_SP Sales by Target Type' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_linechart_New SP Prescribers' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_linechart_Sales by Strength' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_barchart_SP Prescribers' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_linechart_New SP Patients' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_linechart_Calls' },
                { teams: ['OCNE', 'HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_barchart_SP Patients' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Trends_Launch to Date Accounts' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Trends_New and Existing Accounts' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Trends_Rx Trend by City' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Trends_Targets Reached' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Trends_Total Direct Sales' },
            ],
            details: [
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_HCP Targets' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_HCP Targets Not Called (R13W)' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_Rep Interactions' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_Account Order History' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_Accounts with Orders' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales_Zynyz_Existing Accounts Not Ordering (QTD)' },
                { teams: ['HO3'], brand: 'ZYNYZ', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCPs in Restricted States' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCP Competitor Product Usage'},
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_Existing Accounts Not Ordering (QTD)' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCP Targets' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_Monjuvi Experience' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCP Targets Not Called (R13W)' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCP MOR Calls (R13W)' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCPs sent MOR RTE' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCP Targets Not Emailed (R13W)' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_Alert HCPs' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_Alerts' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_Accounts with Orders' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_Account Order History' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_New Orders this Week' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCPs in Restricted States' },
                { teams: ['HO3'], brand: 'MONJUVI', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCPs with Barriers' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Alert HCPs' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Alerts' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_New Patients' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Prescribers' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Pemazyre Targets' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Rep Interactions' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_SP Sales History' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Direct Sales Accounts' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Pemazyre_table_Direct Sales History' },
                { teams: ['HO3'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_Field Sales - Monjuvi_table_HCPs in Restricted States' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Prescribers' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Direct Sales Accts' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_HCPs with Barriers' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales_Barrier Analysis_HCP Barrier History' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Alert HCPs' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Alerts' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_New Patients' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_New Prescribers' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Targets w/No Rx' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Targets not Called' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Decliners' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_BV Enrollments' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_HCPs in Restricted States' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Refill Tracker' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_SP Sales CW vs. PW' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Direct Sales CW vs. PW' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_SP Sales History' },
                { teams: ['HO1', 'HO2'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Direct Sales History' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Primary Targets' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_New Patients' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_New Prescribers' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Prescribers' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_BV Enrollments' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_Field Sales - Jakafi_table_Direct Sales Accts' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_New APP Prescribers' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_APP Barriers' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_APP Initiating HCP' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Alert Listing' },
                { teams: ['OCNE'], brand: 'JAKAFI', friendlyName: 'Mosaic_OCNE Reports - Jakafi_table_Alert Listing OCNE Dismissal' },
                { teams: ['OCNE'], brand: 'MONJUVI', friendlyName: 'Mosaic_OCNE Reports - Monjuvi_table_Monjuvi Targets' },
                { teams: ['OCNE'], brand: 'MONJUVI', friendlyName: 'Mosaic_OCNE Reports - Monjuvi_table_Alert Listing' },
                { teams: ['OCNE'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre Targets' },
                { teams: ['OCNE'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre Interactions' },
                { teams: ['OCNE'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre New Patients' },
                { teams: ['OCNE'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Pemazyre New Prescribers' },
                { teams: ['OCNE'], brand: 'PEMAZYRE', friendlyName: 'Mosaic_OCNE Reports - Pemazyre_table_Alert Listing' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_Account Order History' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_Accounts with Orders' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_Existing Accounts Not Ordering' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_HCP Targets' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_HCP Targets Export' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_HCP Targets Not Called' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_HCP Targets Not Emailed' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_HCPs in Restricted States' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_New Orders this Week' },
                { teams: ['HO2'], brand: 'NIKTIMVO', friendlyName: 'Mosaic_Field Sales_Niktimvo Details_Niktimvo Experience' },

            ],
            landingalert: [
                { teams: ['HO1'], friendlyName: 'Mosaic_Main - Jakafi_table_HO1 Alerts'},
                { teams: ['HO2'], friendlyName: 'Mosaic_Main - Pemazyre_table_HO2 Alerts'},
                { teams: ['HO3'], friendlyName: 'Mosaic_Main - Monjuvi_table_HO3 Alerts'},
                { teams: ['OCNE'], friendlyName: 'Mosaic_Main - OCNE_table_OCNE Alerts'},
            ],
            dismissalReasons: [
                { teams: ['HO1', 'HO2', 'HO3', 'OCNE'], friendlyName: 'Mosaic_Main_filterpane_Alert Dismissal Reason'},
            ],
            hcp360alertpage: [
                { brand: 'JAKAFI', friendlyName: 'Mosaic_HCP 360_Jakafi Alerts Listing'},
                { brand: 'MONJUVI', friendlyName: 'Mosaic_HCP 360_Monjuvi Alerts Listing'},
                { brand: 'PEMAZYRE', friendlyName: 'Mosaic_HCP 360_Pemazyre Alerts Listing'},
            ],
            hcp360barrierspage: [
                { brand: 'JAKAFI', friendlyName: 'Mosaic_HCP 360_Jakafi Barriers Listing'},
                { brand: 'MONJUVI', friendlyName: 'Mosaic_HCP 360_Monjuvi Barriers Listing'},
                { brand: 'PEMAZYRE', friendlyName: 'Mosaic_HCP 360_Pemazyre Barriers Listing'},
            ],
            hcp360affiliationspage: [
                { friendlyName: 'Mosaic_HCP 360 - Affiliations_Account Listing' },
                { friendlyName: 'Mosaic_HCP 360 - Affiliations_HCP Listing' },
            ],
            fieldSalesBarriersAnalysis: [
                { friendlyName: 'Mosaic_Field Sales_Barrier Analysis_HCP Barrier History', dataFormat: 'table' },
                { friendlyName: 'Mosaic_Field Sales_Barrier Analysis_Barrier Identification by Indication', dataFormat: 'chart' },
                { friendlyName: 'Mosaic_Field Sales_Barrier Analysis_Barrier Identification by Region', dataFormat: 'chart' },
                { friendlyName: 'Mosaic_Field Sales_Barrier Analysis_% of Indication Barrier Identification by Region', dataFormat: 'chart' },
                { friendlyName: 'Mosaic_Field Sales_Barrier Analysis_% Total Barrier Identification by Region', dataFormat: 'chart' },    
            ],
            hco360Connections: [
                { brand: 'JAKAFI', chart: 'AffiliatedHCPs', friendlyName: 'Mosaic_HCO 360_Jakafi Connection Detail_HCPs' },
                { brand: 'JAKAFI', chart: 'ConnectionTypes', friendlyName: 'Mosaic_HCO 360_Jakafi Connection Detail_Connection Types' },
                { brand: 'JAKAFI', chart: 'ConnectedHCPDetails', friendlyName: 'Mosaic_HCO 360_Jakafi Connection Detail_Connections Details' },
                { brand: 'PEMAZYRE', chart: 'AffiliatedHCPs', friendlyName: 'Mosaic_HCO 360_Pemazyre Connection Detail_HCPs' },
                { brand: 'PEMAZYRE', chart: 'ConnectionTypes', friendlyName: 'Mosaic_HCO 360_Pemazyre Connection Detail_Connection Types' },
                { brand: 'PEMAZYRE', chart: 'ConnectedHCPDetails', friendlyName: 'Mosaic_HCO 360_Pemazyre Connection Detail_Connections Details' },
                { brand: 'MONJUVI', chart: 'AffiliatedHCPs', friendlyName: 'Mosaic_HCO 360_Monjuvi Connection Detail_HCPs' },
                { brand: 'MONJUVI', chart: 'ConnectionTypes', friendlyName: 'Mosaic_HCO 360_Monjuvi Connection Detail_Connection Types' },
                { brand: 'MONJUVI', chart: 'ConnectedHCPDetails', friendlyName: 'Mosaic_HCO 360_Monjuvi Connection Detail_Connections Details' },
            ],
        },
        fieldFriendlyNames: {
            trainerSelector: {
                masqueradeFieldId: 'Mosaic_Field Sales_Masquerade User Id',
                repNameFieldId: 'Mosaic_Field Sales_Trainer Rep Name'
            },
            masqueradeSelector: {
                masqueradeFieldId: 'Mosaic_Field Sales_Masquerade User Id',
                repNameFieldId: 'Mosaic_Field Sales_Masquerade Rep Name'
            }
        },
    },
    Qlik: {
        showDisclaimer: environment.showDisclaimer,
        useJwtAuth: false,
        loadMashupObjects: true,
        doLoadQlikStyles: true, // Should load Qlik styles?
        config: { // This is the Qlik config object
            host: environment.Qlik_config_host,
            prefix: '/',
            port: 443,
            isSecure: true,
            identity: environment.production ? uuidv1() : undefined, //Provides the ability to show multiple HCP for one user of the app
        },
        // Adapted from https://help.qlik.com/en-US/sense-developer/February2021/Subsystems/EngineAPI/Content/Sense_EngineAPI/GenericObject/LayoutLevel/HyperCube.htm#anchor-1
        qStateValues: {
            LOCKED: 'L',
            SELECTED: 'S',
            OPTIONAL: 'O',
            DESELECTED: 'D',
            ALTERNATIVE: 'A',
            EXCLUDED: 'X',
            EXCLUDED_SELECTED: 'XS',
            EXCLUDED_LOCKED: 'XL',
        },
        TeamFilteredApps: [
            'FieldSales',
            'Prof360'
        ],
        FieldSales: {
            defaultSelections: [
                { fieldName: 'TimeSelectionDesc', qSelected: 'Quarter' },
            ],
            locks: [
                'Mosaic_Field Sales_Masquerade User Id',
                'Mosaic_Field Sales_Region HO1',
                'Mosaic_Field Sales_Region HO2',
                'Mosaic_Field Sales_Region HO3',
                'Mosaic_Field Sales_Territory HO1',
                'Mosaic_Field Sales_Territory HO2',
                'Mosaic_Field Sales_Territory HO3',
                'Mosaic_Field Sales_Territory OCNE',
                'Mosaic_Field Sales_Area HO1'
            ],
            userDataFields: {
                dataDate: {
                    jakPem: { qStringExpression: `=Only(DATA_DATE_PROMETRICS)` },
                    mon: { qStringExpression: `=Only(DATA_DATE_INTEGRICHAIN)` }
                }
            },
            variables: {
                'Sales Performance Data Date': 'vINTEGRICHAIN_DATA_DATE',
                vCenterWebsite: '=BMTC.WEBSITE',
                vCRMLink: "='https://na43.salesforce.com/apex/QlickviewGVHDPage?BMTOrgId=' & [%BMT_ORG_ID]",
                vGoogleMapLoc: "='http://maps.google.com/?q=loc:' & [BMTC.DO.ORG_ADDRESS1] & ', ' & [BMTC.DO.ORG_CITY] & ', ' & [BMTC.DO.ORG_STATE] & ', ' & [BTMC.DO.ORG_ZIP5]",
                vProfImageFile: "=if(count(distinct DP.PROF_IMG_URL)=1,subfield(DP.PROF_IMG_URL,'ProfImages\\',2),'UnknownDoc.jpg')",
                vSalesForceHeaderHO1: '',
                vSalesForceHeaderHO2: '',
                vSalesForceHeaderHO3: '',
                vSalesForceHeaderTM31: '',
                vSalesForceHearderMorCounterpart1: '',
                vSalesForceHearderMorCounterpart2: '',
            },
            ids: {
                appId: environment.Qlik_FieldSales_appId,
                fields: {
                    'Team ID': 'FSD.TeamID',
                    'PROF ID': '%PROF_ID',
                    'PROF IMAGE URL': 'DPA.PROF_IMG_URL',
                    'Google Maps URL': 'BMTC.gLoc',
                    'Website URL': 'BMTC.WEBSITE',
                    'Salesforce ID': 'CDS.SFA_CUSTOMER_ID',
                    'Brand': 'DPROD.BRAND_NAME',
                    'INCYTE_LAST_INTERACTION_DATE': 'DPA.INCYTE_LAST_INTERACTION_DATE',
                    'INCYTE_NEXT_INTERACTION_DATE': 'DPA.INCYTE_NEXT_INTERACTION_DATE',
                    'MORPHOSYS_LAST_INTERACTION_DATE': 'DPA.MORPHOSYS_LAST_INTERACTION_DATE',
                    'MORPHOSYS_NEXT_INTERACTION_DATE': 'DPA.MORPHOSYS_NEXT_INTERACTION_DATE',
                    'User Team': 'NT_TEAM_ID',
                    'User Primary Team': 'NT_CM_PRIMARY_TEAM_ID',
                    'User Emp Type': 'NT_USER_EMP_TYPE',
                    'User Access Type': 'NT_USER_ACCESS',
                    'User First Name': 'NT_EMP_FIRST_NAME',
                    'User Last Name': 'NT_EMP_LAST_NAME',
                    'Masquerade User Id': `SA_USERID`,
                    'Primary City State': 'MDM_PRIMARY_CITY_STATE_BLINDED_TM11',
                    'Primary Zip': 'MDM_PRIMARY_ZIP_BLINDED_TM11',
                    'REGIONANALYSIS - Show Nation': 'NT_CM_FS_COMPARE_NATION',
                    'REGIONANALYSIS - Show Region': 'NT_CM_FS_COMPARE_REGION',
                    'HOME - Show Filters': 'NT_CM_FS_HOME_FILTERS_REGION',
                    'APP - Is 360 Only': 'NT_HCP360_ONLY_FLAG',
                },
                items: {
                    'Mashup Verify Object IDs': 'qpChhU',
                    'Mashup Verify Fields': 'HnSYGW',
                    'Alerts Dismissals': 'jUsmPvL',
                    'Monjuvi HCP Targets - Export': '0d4e084f-e6ba-45b7-b967-35292ed3d7de',
                },
                other: {},
            },
        },
        FieldSalesNation: {
            ids: {
                appId: environment.Qlik_FieldSalesNation_appId,
                items: {
                    'Mashup Verify Object IDs': 'dNKdndr',
                    'Mashup Verify Fields': 'mXJXVUw',
                }
            }
        },
        Prof360: {
            locks: [
                'Mosaic_HCP 360_Masquerade User Id',
                'Mosaic_HCP 360_Team ID'
            ],
            variables: {
                vCenterWebsite: '=BMTC.WEBSITE',
                vCRMLink: "='https://na43.salesforce.com/apex/QlickviewGVHDPage?BMTOrgId=' & [%BMT_ORG_ID]",
                vGoogleMapLoc: "='http://maps.google.com/?q=loc:' & [BMTC.DO.ORG_ADDRESS1] & ', ' & [BMTC.DO.ORG_CITY] & ', ' & [BMTC.DO.ORG_STATE] & ', ' & [BTMC.DO.ORG_ZIP5]",
                vProfImageFile: "=if(count(distinct DPA.PROF_IMG_URL)=1,subfield(DPA.PROF_IMG_URL,'ProfImages\\',2),'UnknownDoc.jpg')",
            },
            ids: {
                appId: environment.Qlik_Prof360_appId,
                fields: {
                    'Team ID': 'HCP360.TeamID',
                    'Brand': 'BF1.BRAND',
                    'Time Selection': 'TimeSelectionDesc',
                    'Prof Full Name (ID)': 'DP.PROF_FULL_NAME_WITH_ID',
                    'User Team': 'NT_TEAM_ID',
                    'User Emp Type': 'NT_USER_EMP_TYPE',
                    'User Access Type': 'NT_USER_ACCESS',
                    'User First Name': 'NT_EMP_FIRST_NAME',
                    'User Last Name': 'NT_EMP_LAST_NAME',
                    'Masquerade User Id': `SA_USERID`,
                    '360 Search NPI': 'DP.PROF_FULL_NAME_WITH_ID_NPI',
                    'PROF ID': '%PROF_ID',
                    'PROF ID SELECTION': 'Mosaic_HCP 360_PROF ID',
                    'INTERACTION_TERR_WITH_REP': 'INTERACTION_TERR_WITH_REP',
                    'INTERACTION_DESC': 'INTERACTION_DESC',
                    'INTERACTION_CNT': 'INTERACTION_CNT',
                    'EVENT_SHOW_FLAG': 'EVENT_SHOW_FLAG',
                },
                items: {
                    'Mashup Verify Object IDs': 'gLHN',
                    'Mashup Verify Fields': 'fdYDEpF',
                    // these key/value pairs are used in the hcp360.tsx view file
                    'Diagnosis Claims Quintile MF KPI': 'mSjCAH',
                    'Diagnosis Claims Quintile PV KPI': 'DBrBDVh',
                    'Diagnosis Claims Quintile GVHD KPI': 'AEJfvmA',
                    'Bottles R12Mo KPI': 'BCDvXtL',
                    'Bottles C13W KPI': 'PpPmta',
                    'Bottles YTD KPI': 'bGCFbP',
                    'SP Patient Active KPI': 'tpvfJ',
                    'SP Patient R12Mo New KPI': 'Gkgwz',
                    'SP Patient YTD New KPI': 'QvJAJ',
                    'SP MF/PV Split Chart': 'JBpxws',
                    'Trial Card LTD KPI': 'FqnJyF',
                    'Trial Card R12Mo KPI': 'KupXeV',
                    'IncyteCARES LTD KPI': 'JjPfmJ',
                    'IncyteCARES R12Mo KPI': 'LPjGHmJ',
                    'Direct Sales At Location KPI': 'LueXPUN',
                    'Direct Sales GPO Bottles KPI': 'nYubtm',
                    'Digital Pull Engagements KPI': '5393ff49-dabf-4b25-824b-64423ad7844a',
                    // Textbooks
                    'Overview_Textbook YTD Count': 'kgbFGKm',
                    'Overview_Textbook LTD Count': 'yKBgQ',
                    // MCE
                    'Face to Face KPI': '0dc0ead0-94d1-4384-a023-e03d820a0ab6',
                    'Peer Interaction KPI': '85c56027-9a33-48bc-a11b-711ce275b96d',
                    'Digital Push KPI': 'bab85425-f571-498e-9acd-5b9799fa0ac6',
                    'Digital Pull KPI': 'f4913b57-b4cb-4371-841f-1cfd4f120815',
                    'Last Interaction Rep': '92e1c958-e69a-47f7-b12d-c2b453d673a1',
                    'Last Interaction Date': '7992994f-83c5-4540-a440-c149266088cb',
                    'Speaker Program Speaker KPI': '993a4217-441c-4345-aa25-dcb352d67aeb',
                    'Speaker Program SubPage': 'CEpPb',
                    'Speaker Program Attendee KPI': '125f375e-c5ac-41b1-a760-7cb236edf225',
                    'Prod Strategy Direct Mail KPI': 'b31608f5-9700-4374-813e-194d0ab9d56b',
                    'Prod Strategy Email KPI': 'b555993c-39fc-4c6a-9e2c-555cc49ff0aa',
                    'Prod Strategy Textbooks KPI': '3b8d9cab-e296-4cb3-ae35-cf32ccf17f9a',
                    'Connections KPI': 'e5915565-3364-405f-9841-b0c881e1d2fe',
                    'Education Table - Bio': '915a54c9-5cb0-405b-b2e8-2b1e67d84df8',
                    'Current Roles Table - Bio': '33de6018-3e50-46e1-ac81-cfb06033b871',
                    'Associations Table - Bio': '912e63c1-7fe3-4074-b0de-cd02323be48a',
                    'Editorial Table - Bio': 'f3fa690d-5c34-4783-bbe5-26afe4c1c1d0',
                    'Current Research - Research and Interest': '08321370-3d6f-454a-ad4b-8b6263b5a9f4',
                    'Incyte Focus Areas - Research and Interest': 'bf2896b8-bf42-4c6d-a4b7-17f8aa3ec502',
                    'Clinicial Trials Incyte Activity': '3968d47c-3de6-460d-9395-5b69069a3c60',
                    'Investigator Trials Incyte Activity': 'ebb2e05c-095c-4dd5-bd20-5308dba9407a',
                    'Adboards Incyte Activity': 'd87fe141-a6f9-44c0-8763-b824593df919',
                    'Publications Public Activity': '0cdf2f4c-089e-4e14-a94a-cbaf7555e8b2',
                    'Clinical Trials Public Activity': '9472cffb-f0d8-4620-8685-946c4afb1e12',
                    'Presentations Public Activity': '13b6f77a-041b-4327-8f0c-515c7eb2ec92',
                    'Digital Push SubPages': {
                        'HQ - Jakafi/Pemazyre': 'a8243092-9153-4d44-a17d-cf5b2c1fcddc',
                        'HQ - Monjuvi': 'e0a0fd53-f6fc-4469-95b8-1d39b22bbe4c',
                        'RTM(Emp) - Jakafi/Pemazyre': 'f889e29a-260d-400e-b638-a38a659a6129',
                        'RTM(Emp) - Monjuvi': '3e3d065e-e822-4961-ad97-2dafd5001d4a',
                        'Resource Send - Jakafi/Pemazyre': '7ee6113a-751d-4bb7-b1c5-152dce912d25',
                        'Resource Send - Monjuvi': '57444cd0-8ba6-4779-95d0-6ae375a3a978',
                        '3rd Party - Jakafi/Pemazyre': '30f0b59d-e369-4eb9-b3bc-0e964818187e',
                        '3rd Party - Monjuvi': '4ed4d21b-0a06-479a-8432-3bd3ac6eb9a9'
                    },
                    'Digital Pull 6M - Jakafi': '01ff6968-f458-4d18-b7e4-203279f35d61',
                    'Digital Pull 6M - Pemazyre': 'f5dbcdae-791e-4891-846f-7c52a511c7bb',
                    'Digital Pull 6M - Monjuvi': 'fccecd05-f376-49bf-bf71-0d0751ab68bc',
                    'Digital Pull 12M - Jakafi': '9f4e0105-dcc4-449c-9ff7-011a047d0fab',
                    'Digital Pull 12M - Pemazyre': '7ad20b59-9df8-447e-9a37-f214061fa5a6',
                    'Digital Pull 12M - Monjuvi': 'b07ec024-b01c-4b9b-8a85-e93338802c06',
                    'Digital Pull SubPages': {
                        'Jakafi': '695e9bfa-773a-42e9-9699-b07ac4b23f24',
                        'Pemazyre': 'c524080b-bb3f-4aa0-b30e-17c15a047d8d',
                        'Monjuvi': '7541a0e3-02ae-4862-b45d-df8bf1ce521b',
                    },
                    'Connections': {
                        'Top Table': '043bd1a7-311e-427e-aef3-c15751b6117a',
                        'Bottom Table': {
                            'HO1': '05b5d019-9963-4be1-9f30-20bad77e0b7e',
                            'HO2': 'TUBgca',
                            'HO3': 'd458a2b9-bd23-4cd8-b956-5436e3b88bc0',
                            'OCNE': '05b5d019-9963-4be1-9f30-20bad77e0b7e',
                        },
                        'Summary of Types': 'bfd9b4a4-a684-4fb0-8c18-c285f7db999a',
                        'Exports TableId': {
                            'HO1': '928c190c-dca8-47d7-b809-2845980ef21c',
                            'HO2': 'jEXWA',
                            'HO3': '6bb797e5-03fd-4d27-8449-7ba0b4f8d796',
                            'OCNE': '928c190c-dca8-47d7-b809-2845980ef21c',
                        }
                    },
                    'Disclosures Table': 'db357a93-fe56-4e59-b833-1deb8cbdb50c',
                    'Grants Table': '0b4ea112-e224-41b2-a459-2db2a875c613',
                    'CMS Spend Charts': {
                        'CMS Spend by Year': 'f2935d40-f9e9-4465-a33b-72eabac05d98',
                        'Industry Spend': '11668449-0e6d-4b1a-87ce-bfe43fdb28f9',
                    },
                    "Incyte Benchmarks": {
                        "Face to Face Virtual KPI": "467f5b66-167e-4deb-aed1-2ccc35bf32b7",
                        "Face to Face Live KPI": "381b41a4-9f79-4a15-81b2-ca8b2902d15f",
                        "Speaker Programs Virtual KPI": "ad5b9989-94fa-4fa0-b73e-cf92cb6d1e6b",
                        "Speaker Programs Live KPI": "b951ff7b-2fc1-4f7f-9db8-758d2bfb0c81",
                        "Speaker Programs National KPI": "0fd7069f-f5c9-4c9f-8e20-49334028edad",
                        "Digital Push RTE KPI": "8e1100b7-25d3-400b-9fd6-3e9b59f3ec35",
                        "Digital Push HQ KPI": "75c64356-591a-4feb-8313-0a3c5b5dd292",
                        "Digital Push 3rd Party KPI": "aff707e5-80e4-4597-80fb-8593a6d0e544",
                        "Digital Push Resource KPI": "67417152-b925-4e03-aab8-11affd4ba4f0",
                    },
                    "Rolling 12 Months": {
                        "JAKAFI": {
                            "Face to Face HO1 OTS KPI": "2703a60a-5221-46e2-8a35-5fbbf47b3230",
                            "Face to Face HO2 OTS KPI": "QhPuUL",
                            "Face to Face HO3 OTS KPI": "bxqp",
                            "Face to Face OCNE KPI": "a0b395fa-510f-43b4-930c-5210a5dc1dff",
                            "Face to Face MSL KPI": "116d8cdc-eba7-4aa3-9a49-84c5a7048c02",
                            "Face to Face HQ KPI": "44decce2-bd42-4b26-afe4-d71d1fe4c691",
                            "Speaker Programs KPI": "2601072b-b6c2-46cf-8025-4a5bf236a132",
                            "Ad Boards KPI": "f154910a-3e0d-4b4d-8c77-dc346b342e6b",
                            "Digital Push RTE KPI": "ae9019cf-f61b-4d7f-9b4e-de4d4adf2950",
                            "Digital Push HQ KPI": "be9ebcff-20c2-45da-ab83-83be81940ce8",
                            "Digital Push 3rd Party KPI": "c855362e-edf2-44d6-9baf-dc4227a5578d",
                            "Digital Push Resource KPI": "faae1475-78e5-411b-838d-436e5a78dc82",
                        },
                        "MONJUVI": {
                            "Face to Face HO1 OTS KPI": "c9f2de2d-f71b-4a94-be5f-4e321fbae41b",
                            "Face to Face HO3 OTS KPI": "CJHJFg",
                            "Face to Face OCNE KPI": "e3a53abc-35a0-4f0f-8141-5f2c7fd46c75",
                            "Face to Face MSL KPI": "98588bf7-6830-4aea-a134-250d088b0e52",
                            "Face to Face HQ KPI": "e4724066-ada1-447f-a5a0-7cb2cbc28fa8",
                            "Speaker Programs KPI": "0610b116-034f-41e5-afaf-26cef05b9039",
                            "Ad Boards KPI": "acsWAj",
                            "Digital Push RTE KPI": "0c819f3b-7874-41f3-85e7-ab8155ee7a82",
                            "Digital Push HQ KPI": "f39b9969-fa3a-4a75-b13d-03a2cf38f5f6",
                            "Digital Push 3rd Party KPI": "cae0159a-1a84-4423-b451-17ec1bec619a",
                            "Digital Push Resource KPI": "a1e8404c-3c2d-4efc-ba60-10ed0525a46c",
                        },
                    },
                    'Mosaic Overview': {
                        'Patients Popup Table': 'ZeAcHnn',
                        'Speaker Programs as Attendee': '125f375e-c5ac-41b1-a760-7cb236edf225',
                        'Speaker Programs as Speaker': '993a4217-441c-4345-aa25-dcb352d67aeb',
                        'MONJUVI': {
                            // Box 1 - Dx Claims
                            'Dx Claims DLBCL': '5150d818-84ee-40e9-8b3d-f78a2e56b1f1',
                            'MorphoSys Target Type': '216cc8eb-e4bf-49d5-9f0b-7b7ac7e15617',
                            // Box 2 - HCP Landscape
                            'Speaker': 'd83f2dc6-f93a-4c16-be93-5a3793dfdb35',
                            'External Expert': '24d1f5da-303c-433d-bb00-c029c6608417',
                            'Investigator': '4699990e-11ee-4dee-85b8-ab189a70eb53',
                            'Reg. CAR-T Expert': '946fc765-4a47-4bd3-943c-ca29078fca5e',
                            'Policy Experience': '852ac390-8ec6-4ad6-a192-58f749d1889e',
                            // Box 3 - NPP Alerts
                            'RTE Opens': '1aad2584-ea86-42bd-8c53-bacb8fb1d7f1',
                            'HQ Emails CTA Clicked': 'b4a9ceb5-d1f4-438c-9b4f-822afe809434',
                            '3rd Party Email CTA Clicked': '987df8d0-04f2-45f0-9dc5-78afaeddcac2',
                            'Web Pull': 'c96ae03e-fb42-44f7-89f5-74a81c30ddf5',
                            // Box 4 - Copay Card
                            'Monjuvi LTD': 'dfc9cd13-9c6f-4ed9-954f-dff53784391b',
                            'Jakafi (Y/N)': 'c74d1eaa-0786-4535-980d-f4928185c5f0',
                            'Pemazyre (Y/N)': 'WDNngVf',
                            // Box 6 - Hub Enrollment
                            'Monjuvi - R12M': '3b2db52a-523d-4bd0-b8d1-4f3f20839562',
                            'Monjuvi - LTD': 'ef017732-cadb-4f94-8c62-ca08cc4d0323',
                            'Monjuvi - Last Ship Date': 'afff238e-3e57-4ae1-9c7b-cb34ab284dc9',
                            // Box 7 - HCO Info
                            'Account Tier': 'b32a014c-f0b0-45e2-8ead-dc5bd90cc97c',
                            'First Shipment Date': '218b01ca-fb03-4019-9c42-0556e38cf0ca',
                            'Last Shipment Date': '98299aa7-d619-408b-8014-63804137e609',
                            // Box 8 - HCO Vials
                            'Vials - R12M': '8f829516-adc7-4b05-ab5b-9cacdf2f7dff',
                            'Vials - C13W': '29b296d5-a0da-4a26-9c56-e5f6f4c615e4',
                            'Vials - YTD': '3a1ee821-9f4e-44cf-aacd-bb766754a9e4',
                            // Box 9 - Monjuvi Experience
                            'Adoption': '0cfb4715-a41a-445b-8210-de44a149fb5f',
                            'First Detected Date': '2c3dcb8a-b7c0-4073-a7dc-77bccb976987',
                            // Box 10 - Jakafi Landscape
                            'Target Type': '3cddffa8-43d6-41ca-82f7-ce9fc20c0df8',
                            'Speaker (Y/N)': 'ddc76431-5fdf-4681-afd0-ad07cb9ff1f7',
                            'Last Rx Date': '847a9533-1e5b-42ab-8242-41b561b5a291',
                            'R12M Utilization (Y/N)': '1694dc53-543f-47a1-8195-b9b8887534fb',
                            'SP Patients Active KPI': 'e2a2a77b-a7ae-4b9c-a077-61117d5ec573',
                            'SP Patients R12Mo New KPI': 'ed0289b0-f505-4624-bc5f-6c7d065891d5',
                            'SP Patients YTD New KPI': 'a0461d0e-dbda-4898-b216-1b786747cbea',
                            'Bottles R12MO KPI': 'a35e3f40-77e9-4f20-be2c-f84171bc805a',
                            'Bottles C13W KPI': '7457dc2d-f186-463c-9c80-952c2f81ba44',
                            'Bottles YTD KPI': '6c2a61bb-3484-4428-a651-b3266bab9e26',
                        },
                        'JAKAFI': {
                            // Box 1 - Bottles
                            'Bottles R12MO KPI': 'a35e3f40-77e9-4f20-be2c-f84171bc805a',
                            'Bottles C13W KPI': '7457dc2d-f186-463c-9c80-952c2f81ba44',
                            'Bottles YTD KPI': '6c2a61bb-3484-4428-a651-b3266bab9e26',
                            // Box 2 - SP Patients
                            'SP Patients Active KPI': 'e2a2a77b-a7ae-4b9c-a077-61117d5ec573',
                            'SP Patients R12Mo New KPI': 'ed0289b0-f505-4624-bc5f-6c7d065891d5',
                            'SP Patients YTD New KPI': 'a0461d0e-dbda-4898-b216-1b786747cbea',
                            // Box 3 - Direct Sales
                            'Direct Sales At Location KPI': '9d3c9520-aead-4bad-bd76-9828c0ad55dd',
                            'Direct Sales GPO Bottles KPI': '40d03409-2ec1-4dce-9cba-44a5e34508c4',
                            // Box 4 - Treatment
                            'Treatment First Rx': '32c48e73-486a-4c99-9da4-809fd6bf4f2e',
                            'Treatment Last Rx': '4761ab81-5e4d-4551-8d10-1c0ad1187bcf',
                            // Box 5 - Connections
                            'Connections KPI': 'e5915565-3364-405f-9841-b0c881e1d2fe',
                            // Box 6 - Diagnosis Claims Quintile
                            'Diagnosis Claims Quintile MF': '6fb0e17a-0caf-4473-bb41-ffa9abe9b546',
                            'Diagnosis Claims Quintile PV': '7d623342-6bff-4ddc-92f0-0fc35cb82e78',
                            'Diagnosis Claims Quintile GVHD': 'e436fd44-fa26-451b-ae79-c91c0aedf902',
                            // Box 7 - Trial Card / IncyteCARES
                            'Trial Card LTD': '5e35429b-1d77-4185-930c-a89f054a6a5f',
                            'Trial Card R12Mo': '49d35e4c-a0f8-488e-b3a3-3e7fe6ce00d5',
                            'IncyteCARES LTD KPI': '05b396f6-fc9f-4cd5-9981-c6a8ffee96b1',
                            'IncyteCARES R12Mo KPI': '67bda375-e94f-46f4-b7eb-1c3eb5a12436',
                            // Box 9 - Monjuvi Landscape
                            'Target Type': '732ff34d-1406-4e94-94df-d0c6171ceb70',
                            'Speaker (Y/N)': 'd83f2dc6-f93a-4c16-be93-5a3793dfdb35',
                            'DLBCL EE': 'b5dfb0cb-09b4-409b-88fc-333612880dc7',
                            'Last Mon Engagement Date': 'fb0d38ce-08e2-40fd-8706-ddf87a0748e4',

                        },
                        'PEMAZYRE': {
                            // Box 10 - Pemazyre Landscape
                            'Target Type': 'b767692a-81e9-4af3-99e8-0d4138736e12',
                            'Speaker (Y/N)': '3f7a2c00-8a4e-4f65-87e8-2984d334f4b6',
                            'CCA EE': 'c591454c-fb41-4b02-b247-05d66e7f2374',
                            'Last Rx Date': '9dba1f33-b297-4617-a59d-2936b9e8c5b2',
                            'Active Patient Count': 'edc75781-3172-436a-8ad1-866885181693',
                            'Pemi use in last 12 months': 'f2413867-224d-400c-b50b-98d617eb7e5a',
                            'Last PEM Engagement Date': 'a0da55f2-b5c4-47c5-b89b-fe8c7ce4d3ed',
                            'CCA Patient Potential': '45de90b4-8edb-4992-990d-7db33e0477b0',
                            'CCA Foundation Member Y/N': 'a187ee6e-c02f-4689-b320-17ef8607658f',
                            'CCA Testing Behavior': 'xDqxM',
                            'CCA Investigator': '6efc0edf-cc9f-46bf-b853-40fce7b275f6',
                            'CCA Investigator INCY and EXT': '9c382502-b766-4e54-9045-5ac3b569f39a',
                            'GEM CIS Treater': '724a64d9-ee25-4b16-9519-3b86231c74ca',
                        }
                    },
                },
                other: {},
            },
        },
    },
};

export default Config;
