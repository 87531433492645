import { ConfigContext } from "@trinity-incyte/context"
import { QSAppMetadataFamily } from "@trinity-incyte/recoil"
import { QSMashupObject } from "@trinity-incyte/ui"
import React, { useContext } from "react"
import { useRecoilValue } from "recoil"

const colOverrides = {
    colOverrides : [
        {
            columns: [0],
            hStyles: { width: "18rem"},
        },
        {
            columns: [1],
            hStyles: { width: "10rem" },
        },
        {
            columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            hStyles: { width: "7rem" },
        },
        ]
}

export const HCO360ReportsMonjuviTables = () => {
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata
    
    const monjuviAffiliatedAccounts = `Mosaic_HCO 360_Monjuvi_Affiliated Accounts`
    
    return (
        <div style={{ padding: '4px' }}>
            <div style={{ display: 'flex', width: '100%', paddingLeft: '6px', marginTop: '12px' }}>
                <div style={{ width: '100%', height: '86vh' }}>
                    <QSMashupObject
                        showExports
                        exportAsXLS
                        appId={appId}
                        objectKey={ids.get(monjuviAffiliatedAccounts)?.id}
                        mashupId={ids.get(monjuviAffiliatedAccounts)?.id}
                        isTable
                        compact
                        compactMargin
                        tableProps={colOverrides}
                        hideTitle
                    />
                </div>
            </div>
        </div>
    )
}