import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductJakafiInteractions = () => {
    const defaultLayout = {
        responsive: [{
            w: 24,
            items: [{
                w: 24,
                i: 'HCO360 Jakafi Interactions Barriers'
            },
            {
                w: 24,
                i: 'HCO360 Jakafi Interactions Alerts'
            },
            {
                w: 24,
                i: 'HCO360 Jakafi Speaker Programs'
            },
            {
                w: 24,
                i: 'HCO360 Jakafi Interactions'
            }]
        }
    ]}

	return (
		<>
			<Grid
				title="Jakafi Interactions"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductJakafiInteractions;
