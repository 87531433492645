import React, { useContext } from 'react';
import Config from '../../Config';
import { QSAppMetadataFamily, toggleSubPageSelector } from '@trinity-incyte/recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { KPIBox, QSMashupObject } from '@trinity-incyte/ui';
import { useMashup } from "@trinity-incyte/hooks";
import { HCO360Context } from '@trinity-incyte/context';
import QSTableTiles from '../Other/QSTableTiles';
import QSKPITIle from '../Other/QSKPITile';
import { BasicTableColumnRenderer } from 'libs/ui/src/lib/Qlik/qscustom-table/basic-table/basic-table';
import { useHistory } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
declare const Mosaic: MosaicGlobal;

const config = Config.Qlik.Prof360;
const { appId } = config.ids;

    const speakerProgramColOverrides = {
        colOverrides : [
        {
            columns: [0],
            hStyles: { width: "6rem" },
        },
        {
            columns: [1],
            hStyles: { width: "28rem" },
        },
        {
            columns: [2, 3],
            hStyles: { width: "6rem" },
        },
        {
            columns: [4],
            hStyles: { width: "11rem" },
        },
    ]};

    const interactionColOverrides = {
        colOverrides : [
            {
                columns: [0],
                hStyles: { width: "7rem"},
            },
            {
                columns: [1],
                hStyles: { width: "10rem" },
            },
            {
                columns: [2, 3, 4, 5],
                hStyles: { width: "7rem" },
            },
            {
                columns: [6],
                hStyles: { width: "15rem" },
            },
            ]
    }
    const alertsColOverrides = { 
        colOverrides : [
        {
            columns: [0],
            hStyles: { width: '27rem' },
        },
        {
            columns: [1],
            hStyles: { width: '10rem' },
        },
        {
            columns: [2],
            hStyles: { width: '7rem' },
        },
        {
            columns: [3, 4],
            hStyles: { width: '7rem' },
        },
        {
            columns: [5],
            hStyles: { width: '16rem' },
        },
        {
            columns: [6, 7, 8, 9, 10, 11, 12],
            hStyles: { width: '7rem' },
        },
    ]}


const HCO360 = {
    'HCO360 Overview Products': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Units by Brand`
            const history = useHistory()
            const onClick = (groupId, product) => {
                history.push(`/HCO360/${groupId}/${product}/`)
            }
            const productHeaderRenderer = (cellInfo, columns, index, product) => {
                const hco360Context = useContext(HCO360Context)
                return (
                    <th key={`header_cell_${cellInfo.qFallbackTitle}_${index}`} style={{color: '#005cab', textDecoration: 'underline', cursor:'pointer'}} onClick={() => { onClick(hco360Context.state.groupId, product) }}>
                        {cellInfo.Header}
                        <LinkOutlined />
                    </th>
                )
            }
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Units By Brand"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            columnRenderers={new Map<string, BasicTableColumnRenderer>([
                                ["Jakafi", { headerRenderer: (cellInfo, columns, index) => { return productHeaderRenderer(cellInfo, columns, index, 'Jakafi')}}],
                                ["Monjuvi", { headerRenderer: (cellInfo, columns, index) => { return productHeaderRenderer(cellInfo, columns, index, 'Monjuvi')}}],
                                ["Pemazyre", { headerRenderer: (cellInfo, columns, index) => { return productHeaderRenderer(cellInfo, columns, index, 'Pemazyre')}}]
                            ])}
                            {...props}
                        />
                </KPIBox>
        )}        
    },
    'HCO360 Overview Growth Rate': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Growth Rate`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Growth Rate"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}                
    },
    'HCO360 Overview Top Prescribers': {
        component: (props) => {
            const hco360Context = useContext(HCO360Context)
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId_jakafi = `Mosaic_HCO 360_Overview_Top Prescribers - Jakafi`
            const objectId_pemazyre = `Mosaic_HCO 360_Overview_Top Prescribers - Pemazyre`
            const history = useHistory()
            const onClick = (groupId, product) => {
                history.push(`/HCO360/${groupId}/${product}/HCPs/`)
            }
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Top 5 Prescribers at Account"}</div>
                    <div style={{marginTop:'0.4em', fontWeight:'bold', color: '#005cab', textDecoration: 'underline', cursor:'pointer'}} onClick={() => { onClick(hco360Context.state.groupId, "Jakafi") }}>
                        {"Jakafi"}
                        <LinkOutlined />
                    </div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId_jakafi}
                            customClass="basicTable"
                            mashupId={ids.get(objectId_jakafi)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                    <div style={{marginTop:'0.4em', fontWeight:'bold', color: '#005cab', textDecoration: 'underline', cursor:'pointer'}} onClick={() => { onClick(hco360Context.state.groupId, "Pemazyre") }}>
                        {"Pemazyre"}
                        <LinkOutlined />
                    </div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId_pemazyre}
                            customClass="basicTable"
                            mashupId={ids.get(objectId_pemazyre)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
            );
        }          
    },
    'HCO360 Overview R12M Volume': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_R12M Volume`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height: '400px', display:'flex', width: '100%'}}>
                    <QSMashupObject
                        showExports
                        exportAsPPT
                        exportAsXLS={false}
                        customClass="qscustom-table-threesixty"
                        key={objectId}
                        appId={appId}
                        mashupId={ids.get(objectId)?.id}
                        contentClass="no-margin"
                        contentStyle={{ height: null, width: '100%', padding: '1px' }}
                        style={{flex:1}}
                    />
                </KPIBox>
            );
        }       
    },
    'HCO360 Overview Connections': {
        component: () => {
            const hco360Context = useContext(HCO360Context);
            const objectId = `Mosaic_HCO 360_Overview_Connections`
            const mashupData = useMashup(appId, objectId);
            const numConnections = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
        return (
            <KPIBox customClass="kpibox kpi-threesixty">
                <div className="header-threesixty text-center">{"Connections"}</div>
                <div className="text-center qsKPIValue">{numConnections}</div>
            </KPIBox>
            )
        }   
    },
    'HCO360 Overview Speaker Programs': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Speaker Programs`
            const modalObjectId = ids.get(`Mosaic_HCO 360_Interactions_Speaker Program Details`)?.id;
            return (
                <KPIBox id="kpibox kpi-threesixty" style={{height:"41vh"}} tableProps={speakerProgramColOverrides} canClick mashupId={modalObjectId} minimalHeader customClass="kpibox kpi-blue" header="">
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty-linked"
                            isTable
                            compact
                            compactMargin
                            tableProps={speakerProgramColOverrides}
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Overview Interactions': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Interactions`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height:"41vh"}}>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty"
                            isTable
                            compact
                            compactMargin
                            tableProps={interactionColOverrides}
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Overview Account Enagement Affinity': {
        component: (props) => {
            return (
                <div style={{display:'none'}}>HCO360 Overview Account Enagement Affinity</div>
            )
        }        
    },
    'HCO360 Overview BMT Staff': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_BMT Staff`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"BMT Staff"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable basicTableHCP"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Jakafi Bottles': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Jakafi Product Sales_Brand Units`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Units by Channel"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTableFour"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },

    'HCO360 Jakafi Growth Rate': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Jakafi Product Sales_Growth Rate`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Growth Rate"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Jakafi Top Prescribers': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Top Prescribers - Jakafi`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Top 5 Prescribers at Account"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Jakafi Total Account SP Patients': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Jakafi Product Sales_Total Account SP Patients`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Total Account SP Patients"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO 360 Jakafi Key Individuals': {
        component: (props) => {
            const hco360Context = useContext(HCO360Context);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Jakafi Key Individuals Summary`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCO360/${hco360Context.state.groupId}/Jakafi/HCPs?tab=Key+Individuals`}>
                    <div className="header-threesixty-linked header-threesixty-linked-table">{hco360Context.state.brand==`Jakafi`?``:`Jakafi`} Key Individuals Summary</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable basicTableHCP"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Jakafi Average Quintile of HCPs': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Jakafi Product Sales_Average Quintile of HCPs`;
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">
                        <div style={{display:'flex'}}>
                            <div style={{flex:'1', textAlign:'left'}}>
                                Quintile Distribution of HCPs                    
                            </div>
                            <div style={{flex:'1', color:'#666', fontWeight:'normal', textAlign:'right'}}>
                                1-Lowest, 5-Highest
                            </div>
                        </div>
                    </div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable_quintile"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Jakafi R12M Volume': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Jakafi Product Sales_R12M Volume`;
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height: '400px', display:'flex', width:'100%'}}>
                    <QSMashupObject
                        showExports
                        exportAsPPT
                        exportAsXLS={false}
                        key={objectId}
                        appId={appId}
                        mashupId={ids.get(objectId)?.id}
                        contentClass="no-margin"
                        contentStyle={{ height: null, width: '100%', padding: '1px' }}
                        style={{flex:1}}
                    />
                </KPIBox>
            )
        }        
    },
    'HCO360 Affiliations': {
        component: () => {
            const hco360Context = useContext(HCO360Context);
            const customStyle = {backgroundColor: '#E1F1D7', color:'#005CAB', border:'2px solid #6CB33D', width:'33%', maxWidth:'200px'};
        return (
            <KPIBox customClass="kpibox kpi-threesixty" style={{width:'100%'}}>
                <div className="header-threesixty">{"Affiliations"}</div>

                <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                        <QSKPITIle appId={appId} mashupId="Mosaic_HCO 360_All Brands_Affiliated HCPs KPI" customStyle={customStyle} to={`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/HCPs?tab=Affiliated+HCPs`} />
                        <QSKPITIle appId={appId} mashupId="Mosaic_HCO 360_All Brands_Affiliated Accounts KPI" customStyle={customStyle} to={`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/Reports?tab=Child+Accounts`} />
                </div>
            </KPIBox>
            )
        }   
    },
    'HCO360 Jakafi Interactions Barriers': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids, FieldsTable: fields } = metadata;
            const toggleSubPage = useSetRecoilState(toggleSubPageSelector);
            const objectId = ids.get(`Mosaic_HCO 360_Jakafi Interactions_Barriers identified by rolling 24 months`)?.id;
            const barriersColOverrides_Jakafi = {
                colOverrides : [
                {
                    columns: [0],
                    hStyles: { width: "28rem" },
                },
                {
                    columns: [1, 2, 4],
                    hStyles: { width: "7rem" },
                },
                {
                    columns: [3, 5],
                    hStyles: { width: "10em" },
                },
                {
                    columns: [6],
                    hStyles: { width: "7rem" },
                },
                {
                    columns: [7, 9],
                    hStyles: { width: "10rem"},
                },
                {
                    columns: [8, 10],
                    hStyles: { width: "7rem" },
                },
                ]
            };

            const openBarrierModal = (barrierIndication, tileIndex) => {

                // Possible values are: Barrier Identified, No Objection, Not Identified			
                const barrierValues = ['Barrier Not Identified', 'Barrier Identified', 'No Objection'];
        
                // Apply the filters
                let hcpApp = Mosaic.Qlik.app[appId];
        
                let barrierIndicationFilter = fields.get('Mosaic_HCO 360_Barrier Indication Island Filter')?.id;
                let barrierIndicationField = hcpApp.field(barrierIndicationFilter); 

                // Possible values are MF, PV, AGVHD, CGVHD
                barrierIndicationField.selectValues( [barrierIndication]);
        
                let barrierCategoryFilter = fields.get('Mosaic_HCO 360_Barrier Category Island Filter')?.id
                let barrierCategoryField = hcpApp.field(barrierCategoryFilter); 
                barrierCategoryField.selectValues( [barrierValues[tileIndex]]);

                // open the window with the chart
                let mashupId = ids.get('Mosaic_HCO 360_Jakafi Barrier Details')?.id;
                toggleSubPage({
                    mashupId,
                    appId: appId,
                    dynamic: true,
                    tableProps: {
                        colOverrides: [],
                    },
                    onUnMount: () => {
                        barrierIndicationField.unlock().then(()=>barrierIndicationField.clear());
                        barrierCategoryField.unlock().then(()=>barrierCategoryField.clear());
                    }
                });
        
            }


            return (
                <KPIBox id="kpibox kpi-threesixty" tableProps={barriersColOverrides_Jakafi} minimalHeader customClass="kpibox kpi-blue" header="">
                    <div className="header-threesixty-linked" style={{margin:'0.75em 0em 0.75em -0.35em'}}>
                        {"Targets Associated to Account by Barrier"} 
                    </div>
                    <div style={{display:'flex', width:'100%'}}>
                        <div style={{width:'50%'}}>
                           <div className="header-threesixty-linked">{"PV"}</div>
                            <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                                <QSTableTiles appId={appId} 
                                    mashupId={ids.get('Mosaic_HCO 360_Jakafi Interactions_PV Barrier Identification')?.id} 
                                    labelColumnId={0}
                                    valueColumnId={1} 
                                    customStyle={{width: '120px', minWidth:'60px', maxWidth:'30%'}}
                                    customContainerClass='qsTableTileContainer_HCOBarriers'
                                    onClickCallback={(index) => { openBarrierModal('PV', index) }}
                                    />
                            </div>
                        </div>
                        <div style={{width:'50%'}}>
                            <div className="header-threesixty-linked">{"aGVHD"}</div>
                            <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                            <QSTableTiles 
                                appId={appId}
                                mashupId={ids.get('Mosaic_HCO 360_Jakafi Interactions_AGVHD Barrier Identification')?.id} 
                                labelColumnId={0}
                                valueColumnId={1} 
                                customStyle={{width: '120px', minWidth:'60px', maxWidth:'30%'}} 
                                customContainerClass='qsTableTileContainer_HCOBarriers'
                                onClickCallback={(index) => { openBarrierModal('AGVHD', index) }}
                            />
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', width:'100%'}}>
                        <div style={{width:'50%'}}>
                           <div className="header-threesixty-linked">{"MF"}</div>
                            <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                                <QSTableTiles 
                                    appId={appId} 
                                    mashupId={ids.get('Mosaic_HCO 360_Jakafi Interactions_MF Barrier Identification')?.id} labelColumnId={0} valueColumnId={1} 
                                    customStyle={{width: '120px', minWidth:'60px', maxWidth:'30%'}} 
                                    customContainerClass='qsTableTileContainer_HCOBarriers'
                                    onClickCallback={(index) => { openBarrierModal('MF', index) }}
                                />
                            </div>
                        </div>
                        <div style={{width:'50%'}}>
                            <div className="header-threesixty-linked">{"cGVHD"}</div>
                            <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                            <QSTableTiles 
                                appId={appId} 
                                mashupId={ids.get('Mosaic_HCO 360_Jakafi Interactions_CGVHD Barrier Identification')?.id} labelColumnId={0} valueColumnId={1} 
                                customStyle={{width: '120px', minWidth:'60px', maxWidth:'30%'}} 
                                customContainerClass='qsTableTileContainer_HCOBarriers'
                                onClickCallback={(index) => { openBarrierModal('CGVHD', index) }}
                            />
                            </div>
                        </div>
                    </div>
                </KPIBox>
        )
        }         
    },
    'HCO360 Jakafi Interactions Alerts': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = ids.get(`Mosaic_HCO 360_Jakafi Details_Alert Listing`)?.id;
            const customStyle = {backgroundColor: '#E1F1D7', color:'#005CAB', border:'2px solid #6CB33D', width:'33%', maxWidth:'200px'};
            return (
                <KPIBox id="kpibox kpi-threesixty" 
                    canClick
                    tableProps={alertsColOverrides}
                    mashupId={objectId} minimalHeader customClass="kpibox kpi-blue" style={{margin:'0px'}} header="">
                    <div className="header-threesixty-linked">
                        {"Alerts "} 
                        <span style={{fontSize:'0.8em', color:'#666666', fontWeight:'normal'}}>{"Shows HCP alerts associated to account rolling 6 months​"}</span>
                    </div>

                    <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                        <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Jakafi Interactions_Precicted MCE" customStyle={customStyle} />
                        <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Jakafi Interactions_Clinical" customStyle={customStyle} />
                        <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Jakafi Interactions_Barriers" customStyle={customStyle} />
                    </div>
                </KPIBox>
            )
        }        
    },
    'HCO360 Jakafi Speaker Programs': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Speaker Programs`
            const modalObjectId = ids.get(`Mosaic_HCO 360_Interactions_Speaker Program Details`)?.id;
            return (
                <KPIBox id="kpibox kpi-threesixty" style={{height:"25vh"}} tableProps={speakerProgramColOverrides} canClick mashupId={modalObjectId} minimalHeader customClass="kpibox kpi-blue" header="">
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty-linked"
                            isTable
                            compact
                            compactMargin
                            tableProps={speakerProgramColOverrides}
                            {...props}
                        />
                </KPIBox>
            )   
        },
    },
    'HCO360 Jakafi Interactions': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Interactions`
        
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height:"25vh"}}>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty"
                            isTable
                            compact
                            compactMargin
                            tableProps={interactionColOverrides}
                            {...props}
                        />
                </KPIBox>
            )   
        },
    },
    'HCO360 Pemazyre Bottles': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Pemazyre Product Sales_Brand Units`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Units by Channel"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTableFour"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Pemazyre Growth Rate': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Pemazyre Product Sales_Growth Rate`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Growth Rate"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}       
    },
    'HCO360 Pemazyre Top Prescribers': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId_pemazyre = `Mosaic_HCO 360_Overview_Top Prescribers - Pemazyre`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Top 5 Prescribers at Account"}</div>
                        <QSMashupObject
                                appId={appId}
                                objectKey={objectId_pemazyre}
                                customClass="basicTable"
                                mashupId={ids.get(objectId_pemazyre)?.id}
                                isBasicTable
                                compact
                                compactMargin
                                {...props}
                            />
                </KPIBox>
            );
        }       
    },
    'HCO360 Pemazyre Total Account SP Patients': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Pemazyre Product Sales_Total Account SP Patients`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Total Account SP Patients"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}
    },
    'HCO360 Pemazyre Average Quintile of HCPs': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Pemazyre Product Sales_Average Quintile of HCPs`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">
                        <div style={{display:'flex'}}>
                            <div style={{flex:'1', textAlign:'left'}}>
                                Quintile Distribution of HCPs                        
                            </div>
                            <div style={{flex:'1', color:'#666', fontWeight:'normal', textAlign:'right'}}>
                                1-Lowest, 5-Highest
                            </div>
                        </div>
                    </div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable_quintile"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}     
    },
    'HCO360 Pemazyre R12M Volume': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Pemazyre Product Sales_R12M Volume (SP + Direct)`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height: '400px', display:'flex', width: '100%'}}>
                    <QSMashupObject
                        showExports
                        exportAsPPT
                        exportAsXLS={false}
                        key={objectId}
                        appId={appId}
                        mashupId={ids.get(objectId)?.id}
                        contentClass="no-margin"
                        contentStyle={{ height: null, width: '100%', padding: '1px' }}
                        style={{flex:1}}
                    />
                </KPIBox>
            );
        }         
    },
    'HCO360 Pemazyre CCA Foundation Members': {
        component: () => {
            const hco360Context = useContext(HCO360Context);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Pemazyre Product Sales_CCA Foundation Members`
            const modalObjectId = ids.get(`Mosaic_HCO 360_Pemazyre Product Sales_CCA Foundation Member Details`)?.id;
            const mashupData = useMashup(appId, objectId);
            const numMembers = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
            const ccaFoundationColOverrides = {
                colOverrides : [
                {
                    columns: [0],
                    hStyles: { width: "28rem" },
                },
                {
                    columns: [1],
                    hStyles: { width: "10rem" },
                },
                {
                    columns: [2, 3],
                    hStyles: { width: "7em" },
                },
                {
                    columns: [4],
                    hStyles: { width: "12rem" },
                },
                ]
            };
        return (
            <KPIBox id="kpibox kpi-threesixty" canClick mashupId={modalObjectId} tableProps={ccaFoundationColOverrides} minimalHeader customClass="kpibox kpi-blue" header="">
                <div className="header-threesixty text-center">{"CCA Foundation Members"}</div>
                <div className="text-center qsKPIValue">{numMembers}</div>
            </KPIBox>
            )
        }   
    },
    'HCO360 Pemazyre Key Individuals': {
        component: (props) => {
            const hco360Context = useContext(HCO360Context);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Pemazyre Key Individuals Summary`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCO360/${hco360Context.state.groupId}/Pemazyre/HCPs?tab=Key+Individuals`}>
                    <div className="header-threesixty-linked header-threesixty-linked-table">{hco360Context.state.brand==`Pemazyre`?``:`Pemazyre`} Key Individuals Summary</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable basicTableHCP"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )} 
    },
    'HCO360 Pemazyre CCA Testing Behavior': {
        component: () => {
            let boxWidth = '140px';
            return (
                <KPIBox  customClass="kpibox kpi-threesixty">
                        <div style={{width:'100%'}}>
                           <div className="header-threesixty-linked">{"CCA Testing Behavior"}</div>
                            <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%', display:'flex'}}>
                                <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Pemazyre Interactions_Not Testing" 
                                    customStyle={{width: boxWidth, minWidth: boxWidth, maxWidth: boxWidth, backgroundColor: '#4D2F91', height:'80px', color:'#ffffff', border:'2px solid #666666'}} customLabelStyle={{color:'#ffffff'}} customValueStyle={{color:'#ffffff'}} />
                                <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Pemazyre Interactions_Testing but not at diagnosis" 
                                    customStyle={{width: boxWidth, minWidth:boxWidth, maxWidth: boxWidth, backgroundColor: '#90278E', height:'80px', color:'#ffffff', border:'2px solid #666666'}} customLabelStyle={{color:'#ffffff'}} customValueStyle={{color:'#ffffff'}} />
                                <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Pemazyre Interactions_Testing but ot using appropriate NGS test" 
                                    customStyle={{width: boxWidth, minWidth:boxWidth, maxWidth: boxWidth, backgroundColor: '#90278E', height:'80px', color:'#ffffff', border:'2px solid #666666'}} customLabelStyle={{color:'#ffffff'}} customValueStyle={{color:'#ffffff'}} />
                                <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Pemazyre Interactions_Testing correctly" 
                                    customStyle={{width: boxWidth, minWidth: boxWidth, maxWidth: boxWidth, backgroundColor: '#E1F1D7', height:'80px', border:'2px solid #666666'}} />
                            </div>
                        </div>
                </KPIBox>
            )
        }         
    },
    'HCO360 Pemazyre Interactions Alerts': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId))
            const { IDsTable: ids } = metadata
            const objectId = ids.get(`Mosaic_HCO 360_Pemazyre Details_Alert Listing`)?.id;
            const customStyle = {backgroundColor: '#E1F1D7', color:'#005CAB', border:'2px solid #6CB33D', width:'33%', maxWidth:'250px'};
            return (
                <KPIBox id="kpibox kpi-threesixty" canClick tableProps={alertsColOverrides} mashupId={objectId} minimalHeader customClass="kpibox kpi-blue" style={{margin:'0px'}} header="">
                    <div className="header-threesixty-linked">
                        {"Alerts "} 
                        <span style={{fontSize:'0.8em', color:'#666666', fontWeight:'normal'}}>{"Shows HCP alerts associated to account rolling 6 months​"}</span>
                    </div>
                    <div style={{display:'flex', width:'100%'}}>
                        <div style={{width:'100%'}}>
                            <div style={{borderRadius:'4px', paddingLeft:'20px', width:'50%', display:'flex'}}>
                                <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Pemazyre Interactions_CCA Diagnostic" customStyle={customStyle} />
                            </div>
                        </div>
                    </div>
                </KPIBox>
            )
        }         
    },
    'HCO360 Pemazyre Speaker Programs': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Speaker Programs`
            const modalObjectId = ids.get(`Mosaic_HCO 360_Interactions_Speaker Program Details`)?.id;
            return (
                <KPIBox id="kpibox kpi-threesixty" style={{height:"25vh"}} tableProps={speakerProgramColOverrides} canClick mashupId={modalObjectId} minimalHeader customClass="kpibox kpi-blue" header="">
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty-linked"
                            isTable
                            compact
                            compactMargin
                            tableProps={speakerProgramColOverrides}
                            {...props}
                        />
                </KPIBox>
        )}      
    },
    'HCO360 Pemazyre Interactions': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Interactions`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height:"33vh"}}>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty"
                            isTable
                            compact
                            compactMargin
                            tableProps={interactionColOverrides}
                            {...props}
                        />
                </KPIBox>
        )}
    },
    'HCO360 Monjuvi Bottles': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Monjuvi Product Sales_Brand Units`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Units"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTableFour"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Monjuvi Growth Rate': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Monjuvi Product Sales_Growth Rate`
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">{"Growth Rate"}</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Monjuvi Quintile Distribution of HCPs': {
         component: (props) => {
             const metadata = useRecoilValue(QSAppMetadataFamily(appId));
             const { IDsTable: ids } = metadata;
             const objectId = `Mosaic_HCO 360_Monjuvi Product Sales_Quintile Distribution of HCPs`
             return (
                 <KPIBox customClass="kpibox kpi-threesixty">
                    <div className="header-threesixty">
                        <div style={{display:'flex'}}>
                            <div style={{flex:'1', textAlign:'left'}}>
                                Quintile Distribution of HCPs                        
                            </div>
                            <div style={{flex:'1', color:'#666', fontWeight:'normal', textAlign:'right'}}>
                                1-Lowest, 5-Highest
                            </div>
                        </div>
                    </div>
                     <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable_quintile"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                 </KPIBox>
             )}   
    },
    'HCO360 Monjuvi Key Individuals': {
        component: (props) => {
            const hco360Context = useContext(HCO360Context);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Monjuvi Key Individuals Summary`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCO360/${hco360Context.state.groupId}/Monjuvi/HCPs?tab=Key+Individuals`}>
                    <div className="header-threesixty-linked header-threesixty-linked-table">{hco360Context.state.brand==`Monjuvi`?``:`Monjuvi`} Key Individuals Summary</div>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            customClass="basicTable basicTableHCP"
                            mashupId={ids.get(objectId)?.id}
                            isBasicTable
                            compact
                            compactMargin
                            {...props}
                        />
                </KPIBox>
        )}   
    },
    'HCO360 Monjuvi R12M Volume': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Monjuvi Product Sales_R12M Volume`
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height: '400px', display:'flex', width: '100%'}}>
                    <QSMashupObject
                        showExports
                        exportAsPPT
                        exportAsXLS={false}
                        key={objectId}
                        appId={appId}
                        mashupId={ids.get(objectId)?.id}
                        contentClass="no-margin"
                        contentStyle={{ height: null, width: '100%', padding: '1px' }}
                        style={{flex:1}}
                    />
                </KPIBox>
            );
        }         
    },
    'HCO360 Monjuvi Interactions Barriers': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = ids.get(`Mosaic_HCO 360_Monjuvi Interactions Barriers_Barriers identified by rolling 24 months`)?.id;
            const customStyle = {backgroundColor:'#bedefb', color:'#005cab', border:'2px solid #005cab', flex:1};
            const customLabelStyle = {fontColor:'#595959'};
            const customValueStyle = {fontColor:'#595959'};
            const barriersColOverrides_Monjuvi = {
                colOverrides : [
                {
                    columns: [0],
                    hStyles: { width: "28rem" },
                },
                {
                    columns: [1],
                    hStyles: { width: "16rem" },
                },
                {
                    columns: [2],
                    hStyles: { width: "18em" },
                },
                {
                    columns: [3],
                    hStyles: { width: "12rem" },
                },
                ]
            };
            return (
                <KPIBox id="kpibox kpi-threesixty" canClick mashupId={objectId} tableProps={barriersColOverrides_Monjuvi} minimalHeader customClass="kpibox kpi-blue" header="">
                    <div style={{width:'100%'}}>
                        <div className="header-threesixty-linked">
                            {"Targets Associated to Account By Barrier"} 
                        </div>
                        <div style={{paddingLeft:'20px', width:'100%', display:'flex'}}>
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_CD19 SEQUENCING" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_25% DISCONTINUATION RATE" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_EASY TO TREAT PATIENTS IN LMIND TRIAL" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_TREATMENT TO PROGRESSION - EVERY TWO WEEKS" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                        </div>
                        <div style={{paddingLeft:'20px', width:'100%', display:'flex'}}>
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_25 MG LEN DOSING TOO HIGH" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_COST OF MONJUVI + LEN IS TOO HIGH" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_PHASE 2 NON RANDOMIZED TRIAL" customStyle={customStyle} customLabelStyle={customLabelStyle} customValueStyle={customValueStyle} />
                            <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Barriers_PATIENT TYPE – MONJUVI RESTRICTED TO OLD & FRAIL PATIENTS" customStyle={customStyle} customValueStyle={customValueStyle} />
                        </div>
                    </div>
                </KPIBox>
            )
        }        
    },
    'HCO360 Monjuvi Interactions Alerts': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = ids.get(`Mosaic_HCO 360_Monjuvi Details_Alert Listing`)?.id;
            const customStyle = {backgroundColor: '#E1F1D7', color:'#005CAB', border:'2px solid #6CB33D'};
            return (
                <KPIBox id="kpibox kpi-threesixty" canClick tableProps={alertsColOverrides} mashupId={objectId} minimalHeader customClass="kpibox kpi-blue" style={{margin:'0px'}} header="">
                    <div className="header-threesixty" style={{margin:'8px 4px 4px 4px', textAlign:'center'}}>
                        {"Alerts "} 
                    </div>
                    <div style={{fontSize:'0.8em', color:'#666666', lineHeight:'1em', marginLeft:'8px'}}>{"Shows HCP alerts associated to account rolling 6 months​​"}</div>
                    <div style={{width:'100%'}}>
                        <QSKPITIle appId={appId}  mashupId="Mosaic_HCO 360_Monjuvi Interactions Alerts_Clinical" customStyle={customStyle} />
                    </div>
                </KPIBox>
            )
        }        
    },
    'HCO360 Monjuvi Speaker Programs': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Speaker Programs`
            const modalObjectId = ids.get(`Mosaic_HCO 360_Interactions_Speaker Program Details`)?.id;
            return (
                <KPIBox id="kpibox kpi-threesixty" style={{height:"25vh"}} tableProps={speakerProgramColOverrides} canClick mashupId={modalObjectId} customClass="kpibox kpi-blue" header="">
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty-linked"
                            isTable
                            compact
                            compactMargin
                            tableProps={speakerProgramColOverrides}
                            {...props}
                        />
                </KPIBox>
            )   
        },
    },
    'HCO360 Monjuvi Interactions': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const objectId = `Mosaic_HCO 360_Overview_Interactions`
            
            return (
                <KPIBox customClass="kpibox kpi-threesixty" style={{height:"33vh"}}>
                    <QSMashupObject
                            appId={appId}
                            objectKey={objectId}
                            mashupId={ids.get(objectId)?.id}
                            customClass="qscustom-table-threesixty"
                            isTable
                            compact
                            compactMargin
                            tableProps={interactionColOverrides}
                            {...props}
                        />
                </KPIBox>
        )}   
    },

}

export default HCO360;