import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductMonjuviSales = () => {
    const defaultLayout = {
        responsive: [{
            w: 15,
            items: [{
                items: [{
                    w: 8,
                    i: 'HCO360 Monjuvi Bottles'
                },
                {
                    w: 16,
                    i: 'HCO360 Monjuvi Quintile Distribution of HCPs'
                }
            ]
            }, {
                items: [{
                    w: 8,
                    i: 'HCO360 Monjuvi Growth Rate'
                }, {
                    w: 16,
                    i: 'HCO360 Affiliations'
                }]
            }, {
                items: [{
                    w: 8,
                    i: 'HCO360 Monjuvi Total Account SP Patients',
                }, {
                    w: 16,
                    i: 'HCO360 Monjuvi CCA Foundation Members',
                }]
            }, {
                items: [{
                    w: 16,
                    i: 'HCO360 Monjuvi Average Quintile of HCPs',
                }]
            },
            {
                items: [{
                    w: 24,
                    i: 'HCO360 Monjuvi Key Individuals',
                }]
            }
        ]
        },
        {
            w: 9,
            items: [{
                w: 24,
                i: 'HCO360 Monjuvi R12M Volume'
            },
            ]
        }
    ]}

	return (
		<>
			<Grid
				title="Monjuvi Sales"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductMonjuviSales;
