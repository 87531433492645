import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import MosaicConnectionsIndex from './Connections/index';
import MosaicAffiliations from './Affiliations/Affiliations';
import MosaicPreCallPlanning from './PreCallPlanning/PreCallPlanning';
import MosaicIncyteActivityAdBoards from './IncyteActivity/AdBoards';
import MosaicIncyteActivityClinicalTrials from './IncyteActivity/ClinicalTrials';
import MosaicIncyteActivityInteractions from './IncyteActivity/Interactions';
import MosaicIncyteActivityKeyConnections from './IncyteActivity/KeyConnections';
import MosaicIncyteActivityMCE from './IncyteActivity/MCE';
import MosaicIncyteActivitySpeakerPrograms from './IncyteActivity/SpeakerPrograms';
import MosaicIncyteActivityAlerts from './IncyteActivity/Alerts';
import MosaicIncyteActivityBarriers from './IncyteActivity/Barriers';
import MosaicIncyteActivitySuggestions from './IncyteActivity/Suggestions';
import Mosaic from './index';
import MosaicOverviewBio from './Overview/Bio';
import MosaicOverviewIndex from './Overview/index';
import MosaicOverviewInteractionOverview from './Overview/InteractionOverview';
import MosaicOverviewOnePager from './Overview/OnePager';
import MosaicPublicActivityClinicalTrials from './PublicActivity/ClinicalTrials';
import MosaicPublicActivityCMS_Disclosure from './PublicActivity/CMS_Disclosure';
import MosaicPublicActivityCMS_Spend from './PublicActivity/CMS_Spend';
import MosaicPublicActivityDisclosures from './PublicActivity/Disclosures';
import MosaicPublicActivityFuture from './PublicActivity/Future';
import MosaicPublicActivityGrants from './PublicActivity/Grants';
import MosaicPublicActivityPresentations from './PublicActivity/Presentations';
import MosaicPublicActivityPublications from './PublicActivity/Publications';

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const HCP360Router = () => {
    let query = useQuery();
    return (
        <Switch>
            {/* Start of 360-Only routes */}
            <Route path="/HCP360/Overview/Bio/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicOverviewBio profId={profId} /> );
            }}
            />
            <Route path="/HCP360/Overview/InteractionOverview/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicOverviewInteractionOverview profId={profId} /> );
            }}
            />
            <Route path="/HCP360/Overview/OnePager/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicOverviewOnePager profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/MCE/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityMCE profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/Interactions/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityInteractions profId={profId} interactionType={query.get("type")} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/KeyConnections/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityKeyConnections profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/Suggestions/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivitySuggestions profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/ClinicalTrials/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityClinicalTrials profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/AdBoards/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityAdBoards profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/SpeakerPrograms/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivitySpeakerPrograms profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/Alerts/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityAlerts profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/Barriers/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicIncyteActivityBarriers profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/Publications/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityPublications profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/ClinicalTrials/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityClinicalTrials profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/Presentations/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityPresentations profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/CMS_Disclosure/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityCMS_Disclosure profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/Grants/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityGrants profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/CMS_Spend/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityCMS_Spend profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/Disclosures/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityDisclosures profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/CMS_Disclosure/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityCMS_Disclosure profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PublicActivity/Future/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPublicActivityFuture profId={profId} /> );
            }}
            />
            <Route path="/HCP360/Connections/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicConnectionsIndex profId={profId} /> );
            }}
            />
            <Route path="/HCP360/PreCallPlanning/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicPreCallPlanning profId={profId} />);
            }}
            />
            <Route path="/HCP360/Affiliations/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicAffiliations profId={profId} /> );
            }}
            />
            <Route path="/HCP360/IncyteActivity/MCE" component={MosaicIncyteActivityMCE} />
            <Route path="/HCP360/IncyteActivity/Interactions" component={MosaicIncyteActivityInteractions} />
            <Route path="/HCP360/IncyteActivity/KeyConnections" component={MosaicIncyteActivityKeyConnections} />
            <Route path="/HCP360/IncyteActivity/Suggestions" component={MosaicIncyteActivitySuggestions} />
            <Route path="/HCP360/IncyteActivity/ClinicalTrials" component={MosaicIncyteActivityClinicalTrials} />
            <Route path="/HCP360/IncyteActivity/AdBoards" component={MosaicIncyteActivityAdBoards} />
            <Route path="/HCP360/IncyteActivity/SpeakerPrograms" component={MosaicIncyteActivitySpeakerPrograms} />
            <Route path="/HCP360/PublicActivity/Publications" component={MosaicPublicActivityPublications} />
            <Route path="/HCP360/PublicActivity/ClinicalTrials" component={MosaicPublicActivityClinicalTrials} />
            <Route path="/HCP360/PublicActivity/Presentations" component={MosaicPublicActivityPresentations} />
            <Route path="/HCP360/PublicActivity/Grants" component={MosaicPublicActivityGrants} />
            <Route path="/HCP360/PublicActivity/CMS_Spend" component={MosaicPublicActivityCMS_Spend} />
            <Route path="/HCP360/PublicActivity/Disclosures" component={MosaicPublicActivityDisclosures} />
            <Route path="/HCP360/PublicActivity/CMS_Disclosure" component={MosaicPublicActivityCMS_Disclosure} />
            <Route path="/HCP360/PublicActivity/Future" component={MosaicPublicActivityFuture} />
            <Route path="/HCP360/Connections" component={MosaicConnectionsIndex} />
            <Route path="/HCP360/Overview/Bio" component={MosaicOverviewIndex} />
            <Route path="/HCP360/Overview/InteractionOverview" component={MosaicOverviewIndex} />
            <Route path="/HCP360/Overview/OnePager" component={MosaicOverviewIndex} />
            <Route path="/HCP360/Overview/:profId" render={(props) => {
                const { profId } = props.match.params;
                return ( <MosaicOverviewIndex profId={profId} /> );
            }}
            />
            <Route path="/HCP360/Overview" component={MosaicOverviewIndex} />
            <Route path="/HCP360" component={Mosaic} />
        </Switch>
    )
}

export default HCP360Router;