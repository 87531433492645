import { ConfigContext } from "@trinity-incyte/context"
import { QSAppMetadataFamily, closeModalSelector, openModalSelector } from '@trinity-incyte/recoil';
import { QSMashupObject } from "@trinity-incyte/ui"
import React, { useContext } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button } from 'antd';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ExportHelper, ExportType } from 'libs/ui/src/lib/Qlik/qsexport-helper';

declare var window: any;
declare const Mosaic: MosaicGlobal;

export const HCO360ReportsPemazyreGraphs = () => {
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata
	const openModal = useSetRecoilState(openModalSelector);
	const closeModal = useSetRecoilState(closeModalSelector);
    
    const pemazyreTrxDirectSales = `Mosaic_HCO 360_Pemazyre Trx Details_QoQ Direct Sales`
    const pemazyreTrxSPPSales = `Mosaic_HCO 360_Pemazyre Trx Details_QoQ SPP Sales`
    const pemazyreTrxTotalSales = `Mosaic_HCO 360_Pemazyre Trx Details_QoQ Total Sales`
    const pemazyreTrxTotalSalesPct = `Mosaic_HCO 360_Pemazyre Trx Details_QoQ Total Sales Pct`

    const allChartNames = [ pemazyreTrxDirectSales, pemazyreTrxSPPSales, pemazyreTrxTotalSales, pemazyreTrxTotalSalesPct ];
	
    const allCharts = allChartNames.map((option, index) => {
		const {id, description, tableProps} = ids.get(option);
		return {
			id,
			tableProps,
			key: index.toString(),
			chartName: description
		};
	});

	const exportAllToPPT = () => {
        const exportHelper = ExportHelper(Config, Mosaic.Qlik.app[appId], allCharts.map(tc => tc.id), openModal, closeModal, `Pemazyre Account Sales Performance`)
        exportHelper.exportVisualization(ExportType.exportAsPPT)
	}



    return (
        <div>
            <div style={{width:'100%', textAlign:'right'}}>
                <Button 
                    className="download-button" 
                    onClick={exportAllToPPT}
                    style={{width:'100%', maxWidth:'200px', marginTop: '4px'}}
                >
                    Download All
                </Button>
            </div>
            <div>

                <div style={{ padding: '4px' }}>
                    <div style={{ display: 'flex', width: '100%', paddingLeft: '6px', marginTop: '12px' }}>
                        <div style={{ width: '46%', height: '42vh' }}>
                            <QSMashupObject
                                appId={appId}
                                objectKey={ids.get(pemazyreTrxDirectSales)?.id}
                                mashupId={ids.get(pemazyreTrxDirectSales)?.id}
                                compact
                                compactMargin
                            />
                        </div>
                        <div style={{ width: '46%', height: '42vh' }}>
                            <QSMashupObject
                                appId={appId}
                                objectKey={ids.get(pemazyreTrxSPPSales)?.id}
                                mashupId={ids.get(pemazyreTrxSPPSales)?.id}
                                compact
                                compactMargin
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', paddingLeft: '6px', borderTop: '4px solid #BFD9F1', marginTop: '12px' }}>
                        <div style={{ width: '46%', height: '42vh' }}>
                            <QSMashupObject
                                appId={appId}
                                objectKey={ids.get(pemazyreTrxTotalSales)?.id}
                                mashupId={ids.get(pemazyreTrxTotalSales)?.id}
                                compact
                                compactMargin
                            />
                        </div>
                        <div style={{ width: '46%', height: '42vh' }}>
                            <QSMashupObject
                                appId={appId}
                                objectKey={ids.get(pemazyreTrxTotalSalesPct)?.id}
                                mashupId={ids.get(pemazyreTrxTotalSalesPct)?.id}
                                compact
                                compactMargin
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}