import React, { useState, useEffect, useContext } from 'react';
import {Button} from 'antd';
import { useRecoilValue } from 'recoil';
import { ConfigContext } from '@trinity-incyte/context';

import { 
	activeBrand as activeBrandAtom,
	QSAppMetadataFamily,
 } from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const SelectionButton = ( props ) => {
	if ( props.active ) {
		return (
			<Button
				className={`square-corners ${( props.active ) ? 'square-button-kpi-active' : ''}`}
				data-appid={props.appId}
				data-selection={props.selection}
				onClick={props.onClick}
				onKeyPress={props.onClick}
				//active
				icon="check circle outline">
					{props.content}
				</Button>
		);
	} else {
		return (
			<Button
				className="square-corners"
				data-appid={props.appId}
				data-selection={props.selection}
				onClick={props.onClick}
				onKeyPress={props.onClick}
			>
				{props.content}
			</Button>
		);
	}
};

const buttonSelection = ( event, appId, clearSelection? ) => {
	const app = Mosaic.Qlik.app[appId];
	if ( !app ) return;

	const selectionData = JSON.parse( event.target.dataset.selection );
	const selectionDataKeys = Object.keys( selectionData );

	for ( let ii = 0; ii < selectionDataKeys.length; ii += 1 ) {
		const field = selectionDataKeys[ii];
		const selection = selectionData[field];
		if ( selection === '*' ) {
			app.field( field ).selectAll();
		} else {
			// Selection is case-sensitive
			const selectionArray = Array.isArray( selectionData[field]) ? selectionData[field] : new Array( selectionData[field]);
			app.field( field ).clear();
			app.field( field ).selectValues( selectionArray );
		}
	}
};

const BrandSelectionButtons = ( props ) => {
	const activeBrand = useRecoilValue(activeBrandAtom);
	const [activeButton, setActiveButton] = useState( 3 );
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;

	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	let brandField = fields.get('Mosaic_Field Sales_Brand');

	useEffect(() => {
		if ( activeBrand ) {
			if ( activeBrand === 'JAKAFI' ) {
				setActiveButton(1);
			} else if ( activeBrand === 'PEMAZYRE' ) {
				setActiveButton(2);
			} else if ( activeBrand === 'MONJUVI' ) {
				setActiveButton(3);
			} else {}
		}
	}, [activeBrand]);

	const buttonsArray = [];

	// Hardcoded ifs for launch
	if ( props.showJak ) {
		buttonsArray.push({
			ind: 1,
			text: 'Jakafi',
			selection: `{"${brandField}": "Jakafi"}`,
		});
	}

	if ( props.showPem ) {
		buttonsArray.push({
			ind: 2,
			text: 'Pemazyre',
			selection: `{"${brandField}": "Pemazyre"}`,
		});
	}

	if ( props.showMon ) {
		buttonsArray.push({
			ind: 3,
			text: 'Monjuvi',
			selection: `{"${brandField}": "Monjuvi"}`,
		});
	}

	const content = (
		<Button.Group style={{color:"blue"}}>
			{buttonsArray.map(( val ) => (
				<SelectionButton
					appId={appId}
					key={`date-button-${val.ind}`}
					active={activeButton === val.ind}
					isDisabled={val.isDisabled}
					content={val.text}
					selection={val.selection}
					onClick={( event ) => {
					if ( val.isDisabled ) return;
					if ( activeButton !== val.ind ) {
						setActiveButton( val.ind );
						if ( props.onSelect ) {
							props.onSelect( event, val.text, val.ind );
						} else {
							buttonSelection( event, appId );
						}
					} else {
						return;
					}
					}}
				/>
			))}
		</Button.Group>
	);

	return ( content );
};

export default BrandSelectionButtons;
