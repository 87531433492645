import { LeftSlider, RGLGrid } from "@trinity-incyte/ui";
import React, { useContext } from "react";
import FieldSalesNav from "../../Components/NavBars/FieldSales";
import { ConfigContext } from '@trinity-incyte/context';

const OregonDisclosure = (props) => {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSalesNation;

    
	let defaultLayout = {
		"lg": [
			{
				"w": 24,
				"h": 2,
				"x": 0,
				"y": 0,
				"i": "Selections Bar",
				"moved": false,
				"static": true,
				"props": {
					config: config,
				}
			},
			{
				"w": 24,
				"h": 14,
				"x": 0,
				"y": 2,
				"i": "/FieldSales - OregonDisclosure",
				"moved": false,
				"static": true,
			},
		]
	}

	return (
		<>
			<FieldSalesNav />
			<RGLGrid
				title="Oregon Disclosure"
				layout={defaultLayout}
				className="hide-y"
			>
				<LeftSlider />
			</RGLGrid>
		</>
	);
}

export default OregonDisclosure;