import React from 'react';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, LeftSlider } from '@trinity-incyte/ui';

const JakafiOverview = (props) => {
	const { profId } = props;
	const isProfSelected = use360ProfSelection(profId);

	let defaultLayout;
	if (profId === false) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'green',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId === '-') {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== false && !isProfSelected) {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Loader',
					props: {
						color: 'blue',
						text: 'Loading...',
					},
					moved: false,
					static: true,
				},
			],
		};
	} else if (profId !== '-' && profId !== false && isProfSelected) {
        defaultLayout = {
            responsive: [
                {
                    w: 15,
                    items: [
                        {
                            items: [
                                {
                                    w: 15,
                                    i: 'JAKAFI Bottles Box',
                                    props: { profId: profId },
                                },
                                {
                                    w: 9,
                                    i: 'JAKAFI Treatment LTD',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 12,
                                    i: 'JAKAFI Diagnosis Claims Quintile',
                                    props: { profId: profId },
                                },
                                {
                                    w: 12,
                                    i: 'JAKAFI Connections',
                                    props: { profId: profId },
                                },
                            ]
                        },


                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 12,
                                    i: 'JAKAFI SP Patients',
                                    props: { profId: profId },
                                },
                                {
                                    w: 12,
                                    i: 'JAKAFI Multi Channel Engagement',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'JAKAFI Marketing Campaign Journeys',
                                    props: { profId: profId },
                                }
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'JAKAFI Alerts',
                                    props: { profId: profId },
                                }
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'JAKAFI HCP Activity',
                                    props: { profId: profId },
                                }
                            ]
                        },
                    ]
                },
                {
                    w: 9,
                    items: [
                        {
                            items: [
                                {
                                    w: 24,
                                    i: 'JAKAFI Barriers Concept',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'JAKAFI MPN Landscape',
                                    props: { profId: profId },
                                },
                            ]
                        },
                        {
                            style: {padding: '8px 0 0'},
                            items: [
                                {
                                    w: 24,
                                    i: 'JAKAFI GVHD Landscape',
                                    props: { profId: profId },
                                },
                            ]
                        }
                    ]
                }
            ]
        }
	} else {
		defaultLayout = {
			xs: [
				{
					w: 24,
					h: 16,
					x: 0,
					y: 0,
					i: 'Message Box',
					props: {
						color: 'orange',
						text: 'Please select one HCP to view the overview.',
					},
					moved: false,
					static: true,
				},
			],
		};
	}

	return (
		<>
			<Grid
				title="Jakafi Overview"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default JakafiOverview;
