
/* eslint-disable max-len */
/**
 * QS Mashup Object, meant for direct use on the page
 */

import { Textfit } from '@kyo_ago/react-textfit';
import React, { useEffect, useState, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Segment as SegmentUI, Statistic as StatisticUI } from '@trinity-incyte/ui';
import {
	Col, 
	List as AntList, 
	Row, 
	Image,
	Button as AntButton,
} from 'antd';
import {  QlikLastModified as QlikLastModifiedAtom, closeModalSelector, openModalSelector } from '@trinity-incyte/recoil';
import Utils from '@trinity-incyte/utils';
import { Loader as LoaderUI } from 'libs/ui/src/lib/Other/loader/loader';
import GroupByYearBarChart from '../Other/group-by-year-bar-chart/group-by-year-bar-chart';
import {ProgressBarColor, ProgressBar} from '../Other/progress-bar/progress-bar';
import QSCustomTable from './qscustom-table/qscustom-table';
import SingleStackedBarChart from '../Other/single-stacked-bar-chart/single-stacked-bar-chart';
import './qsmashup-object.scss';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { ConfigContext } from '@trinity-incyte/context';
import {  ExpandOutlined, FileExcelFilled, FileImageFilled, FilePptFilled, FilePdfFilled, StarFilled } from "@ant-design/icons";
import TargetTypeBarChart from "../Other/horizontal-bar-charts/target-type-bar-chart";
import AggregatedPdeBarChart from "../Other/horizontal-bar-charts/aggregated-pde-bar-chart";
import CallTypeSplitBarChart from "../Other/horizontal-bar-charts/call-type-split-bar-chart";
import styled from "styled-components";
import { MosaicGlobal, QlikAppInstance } from "@trinity-incyte/api-interfaces";
import { ExportHelper, ExportType } from "./qsexport-helper";

const AntListItemLabel = styled(Col)`
	font-size:1rem; 
	text-align: left;
	font-weight: 700;
	color: #595959;
	text-transform: uppercase;
`;

const AntListItemValue = styled(Col)`
	font-weight: 400;
	font-size: 1.3rem;
	color: #005cab;
	text-transform: uppercase;
	line-height: 1.4rem;
	text-align: center;
`;

const AntListItemWrapValue = styled(Col)`
	font-weight: 400;
	font-size: 1rem;
	color: #005cab;
	line-height: 1rem;
	text-align: left;
`;

const ExportButton = styled(AntButton)`
	color: #818181; 
	background-color: 'red'; // #e0e1e2;
	border-radius: 0;
	font-size: 1em; 
	padding: 2px 3px;
`;


const ReactMarkdown = require('react-markdown/react-markdown.min.js');
declare const window: any;

declare var Mosaic: MosaicGlobal;

const toggleMaximized = (el: HTMLElement | null, callback: Function) => {
	let isMaximized = false;
	if (el) isMaximized = el.classList.toggle('maximized');
	if (callback) callback(el, isMaximized);
};

const renderManualText = (props, qViz) => {
	const { layout } = qViz.model;
	const cube = layout.qHyperCube;
	let textElem = '';
	let content;
	let contentChildren;
	if (layout.markdown || layout.visualization.toUpperCase() === 'TEXT-IMAGE') { // This is a text object
		textElem = layout.markdown
			.split('%[').join('')
			.replace('****', '** **')
			.replace(/\(center\)/gi, '')
			.replace(/\(inherit\)/gi, '')
			.replace(/\[/g, '')
			.replace(/\]/g, '')
			.replace('(4)', '')
			.replace('(2)', '');
		for (let ii = 0; ii < cube.qMeasureInfo.length; ii += 1) {
			textElem = textElem.replace(`=(${cube.qMeasureInfo[ii].cId})`, cube.qGrandTotalRow[ii].qText);
		}

		textElem = textElem.split( '^' ).slice( 1 ).join( '<br />' ); // Replace caret with newline except for first
		contentChildren = (
			<Textfit mode="multi">
				<div style={{ padding: '2px 0px 4px' }}>
					<ReactMarkdown children={textElem} rehypePlugins={[rehypeRaw, rehypeSanitize]}/>
				</div>
			</Textfit>
		);
	} else if (layout.visualization.toUpperCase() === 'KPI') { // This is a KPI object
		textElem = `**${props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}** <br /> ${cube.qGrandTotalRow[0].qText}`;
		if (cube.qMeasureInfo.length === 1) {
			if (props.customFormatter) {
				contentChildren = props.customFormatter({ val1: cube.qGrandTotalRow[0].qText, num1: cube.qGrandTotalRow[0].qNum, title1: props.customLabel || cube.qMeasureInfo[0].qFallbackTitle });
			} else if (props.heroValue) {
				let maxSize;
				if (Number.isInteger(props.heroValue)) {
					maxSize = props.heroValue;
				} else if (props.heroValue === 'small') {
					maxSize = 40;
				} else if (props.heroValue === 'medium') {
					maxSize = 60;
				} else if (props.heroValue === 'huge') {
					maxSize = 105;
				} else {
					maxSize = 75;
				}
				contentChildren = (
					<Textfit mode="single" max={maxSize}>
						{cube.qGrandTotalRow[0].qText}
					</Textfit>
				);
			} else if (props.asAntListItem) {
				contentChildren = (
					<AntList.Item style={{ padding:'0px', lineHeight: "1.14285714em"}}>
						<Row wrap={false} align={"bottom"} justify={"space-between"} style={{width:"100%"}}>
							<AntListItemLabel>
								{props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}
								</AntListItemLabel>
							<AntListItemValue>
								{cube.qGrandTotalRow[0].qText}
							</AntListItemValue>
						</Row>
					</AntList.Item>
				);
			} else if (props.asAntListItemWrap) {
				contentChildren = (
					<AntList.Item style={{padding:"0px", lineHeight: "1.14285714em"}}>
						<Row wrap={true} align={"bottom"} justify={"space-between"} style={{width:"100%"}}>
							<AntListItemLabel>
								{props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}
							</AntListItemLabel>
							<AntListItemWrapValue>
								{cube.qGrandTotalRow[0].qText}
							</AntListItemWrapValue>
						</Row>
					</AntList.Item>
				);
			} else if (props.noLabel) {
				contentChildren = (
					<StatisticUI
						size={props.size || 'mini'}
						color={props.color || 'blue'}
						style={props.style}
						valueStyle={props.valueStyle}
						inverted={props.inverted}
						horizontal={props.horizontal}
						value={cube.qGrandTotalRow[0].qText}
					/>
				);
			} else if (props.progressIconLeft) {
				let starColor = ProgressBarColor(cube.qGrandTotalRow[0].qText);
				contentChildren = (
					<div style={{display:'flex', textAlign:'left'}}>
						<div style={{padding:'4px'}}>
							<Image 
									width={props.progressIconWidth || "40px"} 
									height={props.progressIconHeight || "auto"} 
									src={props.progressIconLeft} 
									preview={false} 
								/>
							</div>
							<div>
								<div>
									<span className="kpi-label">{(props.noLabel) ? false : props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}
									</span>
								</div>
								<div>
									<span className="kpi-value">{cube.qGrandTotalRow[0].qText} </span> 
									<StarFilled className="kpi-icon" style={{ color:`${starColor}`, display:`${cube.qGrandTotalRow[0].qText=='-'?'none':'inline-block'}` }} />
								</div>
						</div>
					</div>
				);
	
			} else {
				let progressAddon;
				if (props.progressIcon) {
					let starColor = ProgressBarColor(cube.qGrandTotalRow[0].qText);
					progressAddon = (
						<>
							<StarFilled className="kpi-icon-large" style={{ color:`${starColor}`, margin:`3px`, display:`${cube.qGrandTotalRow[0].qText=='-'?'none':'inline-block'}` }} />
							<Image 
								width={props.progressIconWidth || "35px"} 
								height={props.progressIconHeight || "auto"} 
								src={props.progressIcon} 
								preview={false} 
								rootClassName="center-image"
							/>
						</>
					);
				}
				contentChildren = (
					<>
						<StatisticUI
							style={props.textStyle}
							labelStyle={props.labelStyle}
							valueStyle={props.valueStyle}
							size={props.size || 'mini'}
							color={props.color || '#005cab'}
							inverted={props.inverted}
							horizontal={props.horizontal}
							label={(props.noLabel) ? false : props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}
							value={cube.qGrandTotalRow[0].qText}
						/>
						{progressAddon}
					</>
				);
			}
		} else if (cube.qMeasureInfo.length === 2) {
			if (props.customFormatter) {
				contentChildren = props.customFormatter({
					val1: cube.qGrandTotalRow[0].qText,
					num1: cube.qGrandTotalRow[0].qNum,
					title1: cube.qMeasureInfo[0].qFallbackTitle,
					val2: cube.qGrandTotalRow[1].qText,
					num2: cube.qGrandTotalRow[1].qNum,
					title2: cube.qMeasureInfo[1].qFallbackTitle,
				});
			} else if (props.asAntListItem) {
				contentChildren = (
					<>
						<AntList.Item style={{padding:"0px", lineHeight: "1.14285714em"}}>
							<Row wrap={false} align={"bottom"} justify={"space-between"} style={{width:"100%"}}>
								<AntListItemLabel>
									{props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}
									</AntListItemLabel>
								<AntListItemValue>
									{cube.qGrandTotalRow[0].qText}
								</AntListItemValue>
							</Row>
						</AntList.Item>
						<AntList.Item style={{padding:"5px 0 0 0", lineHeight: "1.14285714em"}}>
							<Row wrap={false} align={"bottom"} justify={"space-between"} style={{width:"100%"}}>
								<AntListItemLabel>
									{props.customLabel || cube.qMeasureInfo[1].qFallbackTitle}
								</AntListItemLabel>
							<AntListItemValue>
								{cube.qGrandTotalRow[1].qText}
							</AntListItemValue>
						</Row>
					</AntList.Item>
				</>
				);
			} else if (props.noLabel) {
				contentChildren = [
					<StatisticUI
						style={props.textStyle}
						labelStyle={props.labelStyle}
						valueStyle={props.valueStyle}
						size={props.size || 'mini'}
						color={props.color || '#005cab'}
						inverted={props.inverted}
						horizontal={props.horizontal}
						label={(props.noLabel) ? null : cube.qMeasureInfo[0].qFallbackTitle}
						value={cube.qGrandTotalRow[0].qText}
					/>,
					<StatisticUI
						style={props.textStyle}
						labelStyle={props.labelStyle}
						valueStyle={props.valueStyle}
						size={props.size || 'mini'}
						color="#00b5ad"
						inverted={props.inverted}
						horizontal={props.horizontal}
						label={(props.noLabel) ? null : cube.qMeasureInfo[1].qFallbackTitle}
						value={cube.qGrandTotalRow[1].qText}
					/>,
				];
			} else {
				contentChildren = [
					<StatisticUI
						style={props.textStyle}
						labelStyle={props.labelStyle}
						valueStyle={props.valueStyle}
						size={props.size || 'mini'}
						color={props.color || '#005cab'}
						inverted={props.inverted}
						horizontal={props.horizontal}
						label={(props.noLabel) ? null : props.customLabel || cube.qMeasureInfo[0].qFallbackTitle}
						value={cube.qGrandTotalRow[0].qText}
					/>,
					<StatisticUI
						style={props.textStyle}
						labelStyle={props.labelStyle}
						valueStyle={props.valueStyle}
						size={props.size || 'mini'}
						color="#00b5ad"
						inverted={props.inverted}
						horizontal={props.horizontal}
						label={(props.noLabel) ? null : props.customLabel || cube.qMeasureInfo[1].qFallbackTitle}
						value={cube.qGrandTotalRow[1].qText}
					/>,
				];
			}
		}
	}

	if (props.textOnClick) {
		content = (
			// eslint-disable-next-line jsx-a11y/interactive-supports-focus
			<div
				className="manualText"
				style={{ overflow: 'hidden', cursor: 'pointer' }}
				onClick={props.textOnClick}
				onKeyPress={props.textOnClick}
				role="link"
			>
				{contentChildren}
			</div>
		);
	} else if (props.asListItem || props.heroValue || props.customFormatter) {
		content = contentChildren;
	} else {
		content = (
			<div className="manualText">
				{contentChildren}
			</div>
		);
	}

	return content;
	// ReactDOM.render( content, document.getElementById( elementId ));
};

/* eslint-disable-next-line */
export interface QSMashupObjectProps {
	nonInteractive?: boolean;
	customClass?: string;
	objectKey?: string;
}

export function QSMashupObject(props: QSMashupObjectProps | any) {
	const { appId } = props;
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const [isMounting, setIsMounting] = useState<any>(0);
	const [qsObject, setQsObject] = useState<any>(0);
	const [textContent, setTextContent] = useState<any>({ component: false });
	const [tableContent, setTableContent] = useState<any>({ component: false });
	const [elementId] = useState(() => { return props.elementId || Utils.idGen('mashup')});
	const Config = useContext(ConfigContext);
	const openModal = useSetRecoilState(openModalSelector);
	const closeModal = useSetRecoilState(closeModalSelector);

	const exportId = props.exportId || props.mashupId;

	useEffect(() => {
			if (qsObject && (props.isText || props.customFormatter) && props.mashupId) {
					const app = Mosaic.Qlik.app[appId];
					app.getObject(props.mashupId);
			}
	}, [props.mashupId]);

	useEffect(() => {
		let isActive = true;
		if (Mosaic.QlikAPI && props.mashupId) { // If Qlik has been loaded and connection established
			if (Mosaic.Qlik.app[appId]) {
				// If the specific app has been loaded
				const app = Mosaic.Qlik.app[appId];
				if (!qsObject && !isMounting) {
					if (!isActive) return;
					setIsMounting(true);
					if (Config.Qlik.loadMashupObjects === false || props.noLoad) return;

					app.visualization.get(props.mashupId).then((qViz) => {
						if (!isActive) return;
						setQsObject(qViz);
						
						if (props.onQViz) props.onQViz(qViz, elementId);

						if (props.isText || props.customFormatter) {
							setTextContent({ component: renderManualText(props, qViz) });

							if (qViz.table) {
								qViz.table.addOnDataListener(() => {
									setTextContent({ component: renderManualText(props, qViz) });
								});
							}
						} else if (props.isTable || props.isBasicTable) {
							setTableContent({
								component: <QSCustomTable qViz={qViz} {...props} />,
							});
						} else if (props.barChart) {
							setTableContent({
								component: (
									<GroupByYearBarChart qViz={qViz} {...props} />
								),
							});
						} else if (props.stackedChart100) {
							setTableContent({
								component: (
									<SingleStackedBarChart qViz={qViz} {...props} />
								)
							});
						} else if (props.targetTypeBarChart) {
							setTableContent({
								component: (
									<TargetTypeBarChart qViz={qViz} {...props}/>
								)
							});
						} else if (props.aggregatedPdeBarChart) {
							setTableContent({
								component: (
									<AggregatedPdeBarChart qViz={qViz} {...props}/>
								)
							});
						} else if (props.callTypeSplitBarChart) {
							setTableContent({
								component: (
									<CallTypeSplitBarChart qViz={qViz} {...props}/>
								)
							});
						} else if (props.noShow) {
						} else {
							qViz.show(elementId);
						}
					});
				}
			}
		}

		return () => {
			isActive = false;
		};
	}, [Mosaic.QlikAPI, props.mashupId]); // eslint-disable-line

	useEffect(() => {
        if(qsObject && qsObject.resize && props.resizer) {
            props.resizer(() => () => {
                qsObject.resize();
            });
        }
		return () => {
			if (qsObject && qsObject.close) {
				qsObject.close();
			}
		};
	}, [qsObject]);

	useEffect(() => {
		if (Mosaic && Mosaic.Qlik.app[appId] && Mosaic.Qlik.app[appId].visualization && props.mashupId) {
			if (props.isText || props.customFormatter) {
				Mosaic.Qlik.app[appId].visualization.get(props.mashupId).then((qViz) => {
					setTextContent({ component: renderManualText(props, qViz) });
				});
			} 
		}
	}, [QlikLastModified, props.mashupId]); // eslint-disable-line

	let contentStyle = { } as any;

	if (props.isBasicTable) {
		contentStyle = { ...props.contentStyle }
	} else {
		contentStyle = { height: '100%', width: '100%', background:'transparent', ...props.contentStyle }
	}

	if (props.compactMargin) {
		contentStyle.margin = '0px';
	}

	if (props.compact) {
		contentStyle.padding = '0px';
		contentStyle.border = 'none';
	}
	if (props.bottomMargin) {
		contentStyle.margin = '0 0 1rem 0';
	}

	const startExport = ({
		exportAsImg = false,
		exportAsPDF = false,
		exportAsPPT = false,
		exportAsXLS = false
	}) => {
		// Track the click
		let exportType:ExportType;
		if (exportAsImg) exportType = ExportType.exportAsImg;
		if (exportAsPDF) exportType = ExportType.exportAsPDF;
		if (exportAsPPT) exportType = ExportType.exportAsPPT;
		if (exportAsXLS) exportType = ExportType.exportAsXLS;
		window.analytics?.track('Button Clicked', {
			text: 'Download Data',
			context: 'QS Mashup Object Export Icon Clicked',
			exportType
		});

		const exportHelper = ExportHelper(Config, Mosaic.Qlik.app[appId], exportId, openModal, closeModal)
		exportHelper.exportVisualization(exportType)
	};

	let content;
	if (props.noShow) {
		content = null;
	} else if (props.asListItem || props.customFormatter) {
		content = (
			<>
				{textContent.component && (
					textContent.component
				)}
				{tableContent.component && (
					tableContent.component
				)}
				{textContent.component === false && tableContent.component === false && (
					<>
						<div id={`${elementId}_loader`}>
							<LoaderUI size={props.size || 'medium'} text={props.loaderText || ''}/>
						</div>
						<div style={{ height: '100%' }} />
					</>
				)}
			</>
		);
	} else {
		const { exportAsPDF, exportAsImg, exportAsPPT, exportAsXLS=true } = props;
		content = (
			<SegmentUI basic={props.basic} color={(props.segmentColor) ? props.color : null} style={contentStyle} className={`qs-object-segment no-border ${props.contentClass || ''}`}>
				{(qsObject)
					? (
						<div style={{ position: 'absolute', right: '1rem', zIndex: 2 }}>
							{props.showExports && (
								<div>
									{exportAsPDF && (
										<ExportButton
											style={{ marginLeft: '2px', marginRight:'0px' }}
											icon={<FilePdfFilled style={{ fontSize: '28px' }}/>}
											title="Download as PDF"
											onClick={() => {
												startExport({exportAsPDF});
											}}
										/>
									)}
									{exportAsPPT && (
										<ExportButton
											style={{ marginLeft: '2px', marginRight:'0px' }}
											title="Download PPT"
											icon={<FilePptFilled style={{ fontSize: '28px' }} />}
											onClick={() => {
												startExport({exportAsPPT});
											}}
										/>
									)}
									{exportAsImg && (
										<ExportButton	
											style={{ marginLeft: '2px', marginRight:'0px' }}
											title="Download Image"
											icon={<FileImageFilled style={{ fontSize: '28px' }} />}
											onClick={() => {
												startExport({exportAsImg});
											}}
										/>
									)}
									{exportAsXLS && (
										<ExportButton
										style={{ marginLeft: '2px', marginRight:'0px' }}
										icon={<FileExcelFilled style={{ fontSize: '28px' }} />}
										title="Download Excel"
										onClick={() => {
											startExport({exportAsXLS: true});
										}}
									/>
									)}
								</div>
							)}
							{(props.showMaximize)
								&& (
									<AntButton
										style={{ borderRadius: '0', marginLeft: '4px' }}
										icon={<ExpandOutlined />}
										onClick={() => {
											toggleMaximized((document.querySelector(`#${elementId}`) as HTMLElement).parentElement, (el, isMaximized) => {
												if (props.onToggleMaximize) props.onToggleMaximize(el, isMaximized);
												if (qsObject && isMaximized) qsObject.resize();
											});
										}}
									/>
								)}
						</div>
					) : ''}
				<div id={elementId} className="qs-object" style={props.loaderStyle || { height: '100%' }}>
					{textContent.component && (
						textContent.component
					)}
					{tableContent.component && (
						tableContent.component
					)}
					{textContent.component === false && tableContent.component === false && (
						<>
							<div id={`${elementId}_loader`}>
								<LoaderUI text={props.loaderText || ''} size={props.loaderSize || 'medium'}/>
							</div>
							<div style={{ height: '100%' }} />
						</>
					)}
				</div>
				{(props.addon && qsObject) ? props.addon : ''}
				{/* Adds a non-operable div around the chart/element to make it non-interactive */}
				{(props.nonInteractive && qsObject && !props.isTable) ? 
					<div>
						<div onClick={props.onClick} onKeyUp={props.onClick} data-chart-id={elementId} style={{ position: 'absolute', zIndex: 1, height: '100%', width: '100%', opacity: 0, bottom: '0px', marginLeft: '-1rem' }}/>
					</div> 
				: ''}
				{(props.styleKpi)
					&& <style>{`#${elementId} .kpi-value { ${props.styleKpi} }`}</style>}
				{(props.styleTitle)
					&& <style>{`#${elementId} .measure-title { ${props.styleTitle} }`}</style>}
			</SegmentUI>
		);
	}

	return (
		content
	);
};

export default QSMashupObject;
