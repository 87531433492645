import { NavLinkWrapper, DualBarComparison } from '@trinity-incyte/ui';
import React, { useRef } from 'react';
import { Col, Row, Divider } from 'antd';
import { useRecoilValue } from 'recoil';
import { QSAppMetadataFamily, activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { PathGenerators } from '@trinity-incyte/utils';

const SalesPerformanceGauges = (props) => {
  const { config } = props;
  const { appId } = config.ids;
  const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const { IDsTable: ids } = metadata;
  const activeTeam = useRecoilValue(activeTeamAtom);
  const containerRef1 = useRef<any>();
  const containerRef2 = useRef<any>();
  const containerRef3 = useRef<any>();
  const containerRef4 = useRef<any>();

  const navLinkStyle = {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 10
  };

  let JakafiSPPChannelLink = activeTeam==="OCNE" ? "/" : PathGenerators.FieldSalesPathGenerator(activeTeam, "/Jakafi/Details/SP%20Sales%20CW%20vs.%20PW");
  let JakafiDirectChannelLink = activeTeam==="OCNE" ? "/" : PathGenerators.FieldSalesPathGenerator(activeTeam, "/Jakafi/Details/Direct%20Sales%20CW%20vs.%20PW");
  
  return (
    <Row style={{ flex: '1 1 auto', height: '100%' }} wrap={false} gutter={[24, 4]}>
      <Col span={6} ref={containerRef1}>
        <NavLinkWrapper
          to={JakafiSPPChannelLink} 
          val="Jakafi Gauge SPP Channel KPI"
          style={navLinkStyle}
        >
            <DualBarComparison appId={appId}
              colors={['#66009c', '#db8efe']}
              mashupId={ ids.get('Mosaic_Main - Jakafi_kpi_SPP Channel')?.id }
              title="SPP Channel"
              />
        </NavLinkWrapper>
      </Col>
      <Divider type="vertical" style={{height: '100%', margin: '0'}}/>
      <Col span={6} ref={containerRef4}>
        <NavLinkWrapper
          to={JakafiDirectChannelLink}
          val="Jakafi Gauge Direct Channel KPI"
          style={navLinkStyle}
        >
            <DualBarComparison appId={appId}
              colors={['#65Bb00', '#A8Ed6c']}
              title="Direct Channel"
              mashupId={ ids.get('Mosaic_Main - Jakafi_kpi_Direct Channel')?.id }
            />
        </NavLinkWrapper>
      </Col>
      <Divider type="vertical" style={{height: '100%', margin: '0'}}/>
      <Col span={6} ref={containerRef2}>
        <NavLinkWrapper
          to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Jakafi/Details/New%20Patients")}
          val="Jakafi Gauge Patient KPI"
          style={navLinkStyle}
        >
            <DualBarComparison appId={appId}
              colors={['#006697', '#35bbfa']}
              title="New Patients"
              mashupId={ ids.get('Mosaic_Main - Jakafi_kpi_New Patients')?.id }
            />
        </NavLinkWrapper>
      </Col>
      <Divider type="vertical" style={{height: '100%', margin: '0'}}/>
      <Col span={6} ref={containerRef3}>
        <NavLinkWrapper
          to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Jakafi/Details/New%20Prescribers")}
          val="Jakafi Gauge Prof KPI"
          style={navLinkStyle}
        >
            <DualBarComparison appId={appId}
              colors={['#ff6600', '#ffa264']}
              title="New Writers"
              // formatter={(val) => Math.round(val).toLocaleString()}
              mashupId={ ids.get('Mosaic_Main - Jakafi_kpi_New Writers')?.id }
              />
        </NavLinkWrapper>
      </Col>
    </Row>
  );
};

export default SalesPerformanceGauges;
