import { AudioFilled, MailOutlined, TeamOutlined } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import {
    QlikLastModified as QlikLastModifiedAtom, QSAppMetadataFamily
} from '@trinity-incyte/recoil';
import { MosaicCalendar, useBackTop } from '@trinity-incyte/ui';
import Utils, { generateGetMoreData } from '@trinity-incyte/utils';
import ReactResizeObserver from 'rc-resize-observer';
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useRecoilValue } from 'recoil';
import Event from './Event';


declare const Mosaic: MosaicGlobal;

const EventsList = ({events}) => {
    const [set_scroller, onResizer, backTop] = useBackTop();
  return (
    <>
    <Virtuoso
      style={{
        width: '100%',
        height: Math.floor(window.innerHeight * 0.7),
      }}
      data={events}
      scrollerRef={set_scroller}
      components={{
        List: React.forwardRef((props, listRef) => (
            <ReactResizeObserver onResize={onResizer}>
                <div {...props} ref={listRef}></div>
            </ReactResizeObserver>
        )),
    }}
      itemContent={(index) => {
        const val = events[index];
        return (
          <div
            // need a more targetted key for lists
            key={'item_' + index /* + val.employeeName + val.prof + val.date.format('MM/DD/YYYY') */}
            style={{
              width: 'calc(100% - 20px)',
              paddingBottom:
                index === events.length - 1 ? '2px' : '15px',
              position: 'relative',
              left: '8px',
            }}
          >
            <Event {...val} />
          </div>
        );
      }}
    />
    {backTop}
    </>
  );
};

const EventsCalendar = ({
  config,
  sample,
  rowLimit,
}) => {
  const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
  const [events, set_events] = useState({array: []});
	const [tableObject, set_tableObject] = useState<any>();

	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const { IDsTable: ids, FieldsTable: fields } = metadata;
  const app = Mosaic.Qlik.app[appId];
	useEffect(() => { // OnMount & OnUnmount
		// Set up the accordion titles
		const now = Utils.moment();

		if ( app ) {
			const onQVizData = ( qViz ) => {
				const tempEvents = {array: []};

				for ( let ii = 0; ii < qViz.table.rows.length; ii += 1 ) {
					if ( sample && ( ii % ( parseInt( sample, 10 ) || 50 ) !== 0 )) continue;

					const row = qViz.table.rows[ii];
					const eventDate = Utils.moment( row.cells[2].qText );
          const activity = row.cells[1].qText;

					let icon;
					if ( activity.indexOf( 'Call' ) >= 0 || activity.indexOf( 'MSL Engagement' ) >= 0 ) {
						icon = <TeamOutlined/>;
					} else if ( activity.indexOf( 'Speaker Program' ) >= 0 ) {
						icon = <AudioFilled/>;
					} else {
						icon = <MailOutlined/>;
					}

					const filterType = row.cells[4].qText;
					const companyName = row.cells[5].qText;
					const teamName = row.cells[6].qText;
					const event = {
						prof: row.cells[0].qText,
						profId: row.cells[0].qText.split('(')[1].split(')')[0],
						activity,
						labels: [{ content: teamName, color: 'orange' }],
						date: eventDate,
						employeeName: row.cells[2].qText,
						detail: row.cells[3].qText,
						filterType,
						companyName,
						teamName,
						count: row.cells[7].qNum,
						icon,
						iconStyle: ( row.cells[1].qText === 'Call' )
							? { background: 'var(--MagentaHEX)', color: '#fff' }
							: { background: 'var(--DarkGreyHEX)', color: '#fff' },
						contentStyle: ( row.cells[1].qText === 'Call' )
							? { background: 'var(--WhiteFogHEX)', color: '#0D0D0D' }
							: { background: 'var(--WhiteFogHEX)', color: '#0D0D0D' },
					};

          tempEvents.array.push(event);
        }

        set_events( tempEvents );
			};

			app.visualization
				.get(ids.get('Mosaic_Events_table_Events - Calendar View')?.id)
				.then(( qViz ) => {
					qViz.table.addOnDataListener(() => {
						onQVizData( qViz );
						if ( qViz.table.rows.length !== qViz.table.rowCount && ( !rowLimit || ( rowLimit && qViz.table.rows.length < rowLimit ))) {
							qViz.table.getMoreData();
						}
					});
					qViz.table._instance.getMoreData = generateGetMoreData();
          			qViz.table.getMoreData();
					set_tableObject( qViz );
        	});
		}
  }, []);

  useEffect(() => () => {
    if (tableObject && tableObject.close) tableObject.close();
  }, [tableObject]);

	// This updates the table when the selections change
	useEffect(() => {
		app.visualization
			.get(ids.get('Mosaic_Events_table_Events - Calendar View')?.id)
			.then(( qViz ) => {
				qViz.table.getMoreData();
			});
  }, [QlikLastModified])

  return (
    <MosaicCalendar  events={events} EventsList={EventsList}/>
  );
};

export default EventsCalendar;
