import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360OverviewProduct = () => {
    const defaultLayout = {
        responsive: [{
            w: 15,
            items: [{
                w: 24,
                i: 'HCO360 Overview Products'
            }, {
                items: [{
                    w: 8,
                    items: [{
                        w: 24,
                        i: 'HCO360 Overview Growth Rate'
                    }]
                }, {
                    w: 16,
                    items: [{
                        w: 24,
                        i: 'HCO360 Overview Top Prescribers'
                    }]
                }]
            }, {
                w: 24,
                i: 'HCO 360 Jakafi Key Individuals'
            }, {
                w: 24,
                i: 'HCO360 Pemazyre Key Individuals'
            }, {
                w: 24,
                i: 'HCO360 Monjuvi Key Individuals'
            }, {
                w: 24,
                i: 'HCO360 Overview BMT Staff'
            }]
        },
        {
            w: 9,
            items: [{
                w: 24,
                i: 'HCO360 Overview R12M Volume'
            },
            {
                w: 24,
                i: 'HCO360 Overview Connections'
            }]
        }
    ]}

	return (
		<>
			<Grid
				title="Product Summary"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360OverviewProduct;
