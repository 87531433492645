import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { 
	Typography, 
	Statistic as AntStatistic, 
	Divider,
	List as ListAntd,
	Row, Col
} from 'antd';
import {
    activeTeam as activeTeamAtom,
    QlikLastModified as QlikLastModifiedAtom,
    IncyteTeam,
		QSAppMetadataFamily,
} from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const CurrentEventsBox = ( {config} ) => {
	const { appId} = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
  	const activeTeam = useRecoilValue<IncyteTeam>(activeTeamAtom);
	const [counts, set_counts] = useState({});
	const [tableObject, set_tableObject] = useState<any>();
  	const app = Mosaic.Qlik.app[appId];
  	const {Title} = Typography;

	let objectId, activeLink;
  if (activeTeam === 'HO3') {
      objectId = ids.get('Mosaic_Main - Monjuvi_table_Current Week Events')?.id;
      activeLink = 'MONJUVI';
  }

	const onQVizData = ( qViz ) => {
		const tempCounts = {};
		for ( let ii = 0; ii < qViz.table.rows[0].cells.length; ii += 1 ) {
			tempCounts[qViz.table.headers[ii].qFallbackTitle] = qViz.table.rows[0].cells[ii].qText;
		}
		set_counts( tempCounts );
	};

	useEffect(() => {
		app?.visualization
			.get(objectId)
			.then(( qViz ) => {
				const listener = () => {
					onQVizData( qViz );
					qViz.table.removeOnDataListener( listener );
				};
				qViz.table.addOnDataListener( listener );
				qViz.table.getMoreData();
				set_tableObject( qViz );
			});
    }, [activeTeam]);

    useEffect(() => {
      return () => {
          if (tableObject && tableObject.close) tableObject.close();
      };
    }, [tableObject]);

	// This updates the table when the selections change
	useEffect(() => {
		app?.visualization
			.get(objectId)
			.then(( qViz ) => {
				const listener = () => {
					onQVizData( qViz );
					qViz.table.removeOnDataListener( listener );
				};
				qViz.table.addOnDataListener( listener );
				qViz.table.getMoreData();
			});
	}, [QlikLastModified])

	const columns = Object.keys( counts );
	const content = columns.map((val, ind) => {
		return (
			<ListAntd.Item
				style={{paddingTop:"0em", paddingBottom:"0em"}}
				key={val}
			>
				<Row style={{width:"100%", marginLeft:"0.7em"}}>
					<Col span={16}
						style={{
							fontSize: "1em",
							fontWeight: 700,
							color: "#595959",
							textTransform: "uppercase",
							textAlign: "left"
						}}
					>
						{val}
					</Col>
					<Col span={8}
						style={{
							fontSize: "1.4em",
							fontWeight: 400,
							color: "#1b1c1d",
							textTransform: "uppercase",
							textAlign: "right",
							paddingBottom:"3px"
						}}
					>
						{counts[val]}
					</Col>
					<Divider style={{marginBottom:"0.1em", marginTop:"0em"}} type="horizontal"/>
				</Row>
			</ListAntd.Item>
		);
  });

	return (
    <>
      <ListAntd style={{ textDecoration: 'none' }}>
        {content}
      </ListAntd>
    </>
	);
};

export default CurrentEventsBox;
